import { createApp } from "vue";
import Quasar from 'quasar/src/vue-plugin.js';import Notify from 'quasar/src/plugins/notify/Notify.js';import Dialog from 'quasar/src/plugins/dialog/Dialog.js';;
import dayjs from "dayjs";
import "dayjs/locale/ru";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";

// icons
import { outlinedHome } from "@quasar/extras/material-icons-outlined";
import {
    matAdd,
    matAttachFile,
    matAutorenew,
    matCancel,
    matCheck,
    matCheckBox,
    matCheckBoxOutlineBlank,
    matCheckCircle,
    matCheckCircleOutline,
    matClose,
    matCreate,
    matContentCopy,
    matDelete,
    matDownload,
    matEdit,
    matEngineering,
    matEvent,
    matHelpOutline,
    matHistoryEdu,
    matMoveUp,
    matNotifications,
    matPeople,
    matPermIdentity,
    matPlayArrow,
    matPlaylistAdd,
    matPlaylistAddCheck,
    matReportProblem,
    matRotateLeft,
    matSave,
    matSearch,
    matSearchOff,
    matSend,
    matVisibility,
    matVisibilityOff,
} from "@quasar/extras/material-icons";

import {
    mdiDownload,
    mdiDownloadMultiple,
    mdiFileSign,
    mdiFullscreen,
    mdiFullscreenExit,
    mdiUsbFlashDrive,
} from "@quasar/extras/mdi-v6";

import App from "./App.vue";
import router from "./router";
import store from "./store";

import "./styles/quasar.scss";
import "@quasar/extras/roboto-font/roboto-font.css";
import "@quasar/extras/material-icons/material-icons.css";
import { siteHost } from "./store/constants";

import "@/styles/app.scss";

// import "@/cadesplugin/cadesplugin_api";

Notify.registerType(
    "notify-success",
    {
        icon: matCheckCircle,
        textColor: "white",
        color: "green",
        position: "top-right",
        timeout: 1000,
        html: true,
    },
);

Notify.registerType(
    "notify-failure",
    {
        icon: matReportProblem,
        textColor: "white",
        color: "red",
        position: "top-right",
        html: true,
    },
);

const app = createApp(App)
    .use(
        Quasar,
        {
            config: {},
            plugins: {
                Notify,
                Dialog,
            },
        },
    )
    .use(store)
    .use(router);

dayjs.locale("ru");
dayjs.extend(customParseFormat);
dayjs.extend(utc);
app.config.globalProperties.$dayjs = dayjs;

app.config.globalProperties.outlinedHome = outlinedHome;

app.config.globalProperties.matAdd = matAdd;
app.config.globalProperties.matAttachFile = matAttachFile;
app.config.globalProperties.matAutorenew = matAutorenew;
app.config.globalProperties.matCancel = matCancel;
app.config.globalProperties.matCheck = matCheck;
app.config.globalProperties.matCheckBox = matCheckBox;
app.config.globalProperties.matCheckBoxOutlineBlank = matCheckBoxOutlineBlank;
app.config.globalProperties.matCheckCircle = matCheckCircle;
app.config.globalProperties.matCheckCircleOutline = matCheckCircleOutline;
app.config.globalProperties.matClose = matClose;
app.config.globalProperties.matCreate = matCreate;
app.config.globalProperties.matContentCopy = matContentCopy;
app.config.globalProperties.matDelete = matDelete;
app.config.globalProperties.matDownload = matDownload;
app.config.globalProperties.matEdit = matEdit;
app.config.globalProperties.matEngineering = matEngineering;
app.config.globalProperties.matEvent = matEvent;
app.config.globalProperties.matHelpOutline = matHelpOutline;
app.config.globalProperties.matHistoryEdu = matHistoryEdu;
app.config.globalProperties.matMoveUp = matMoveUp;
app.config.globalProperties.matNotifications = matNotifications;
app.config.globalProperties.matPeople = matPeople;
app.config.globalProperties.matPermIdentity = matPermIdentity;
app.config.globalProperties.matPlayArrow = matPlayArrow;
app.config.globalProperties.matPlaylistAdd = matPlaylistAdd;
app.config.globalProperties.matPlaylistAddCheck = matPlaylistAddCheck;
app.config.globalProperties.matRotateLeft = matRotateLeft;
app.config.globalProperties.matSave = matSave;
app.config.globalProperties.matSearch = matSearch;
app.config.globalProperties.matSearchOff = matSearchOff;
app.config.globalProperties.matSend = matSend;
app.config.globalProperties.matVisibility = matVisibility;
app.config.globalProperties.matVisibilityOff = matVisibilityOff;

app.config.globalProperties.mdiDownload = mdiDownload;
app.config.globalProperties.mdiDownloadMultiple = mdiDownloadMultiple;
app.config.globalProperties.mdiFileSign = mdiFileSign;
app.config.globalProperties.mdiFullscreen = mdiFullscreen;
app.config.globalProperties.mdiFullscreenExit = mdiFullscreenExit;
app.config.globalProperties.mdiUsbFlashDrive = mdiUsbFlashDrive;

// console.log(process.env.VUE_APP_SERVER_BASE_URL);
app.config.globalProperties.siteHost = siteHost;

app.mount("#app");
