import axios from "axios";
import { siteHost } from "./constants";

export default {
    state: {
        sparesSets: [],
        sparesSetsItemsFilter: {
            regionId: null,
            title: "",
        },
    },
    getters: {
        sparesSets: (state) => state.sparesSets,
        sparesSetsItems: (state) => (
            state.sparesSets
                .filter((e) => (state.sparesSetsItemsFilter.regionId
                    ? state.sparesSetsItemsFilter.regionId === e.region_id
                    : true))
                .map((e) => e.spares_sets_items)
                .flat()
                .filter((e) => (state.sparesSetsItemsFilter.title
                    ? e.title.toLowerCase().includes(state.sparesSetsItemsFilter.title.toLowerCase())
                    : true))
        ),
        sparesSetName: (state) => (sparesSetId) => {
            const sparesSet = state.sparesSets.find((ss) => ss.id === sparesSetId);
            return sparesSet
                ? sparesSet.title
                : "";
        },
        sparesSetRegionId: (state) => (sparesSetId) => {
            const sparesSet = state.sparesSets.find((ss) => ss.id === sparesSetId);
            return sparesSet
                ? sparesSet.region_id
                : "";
        },
        sparesSet: (state) => (sparesSetId) => {
            const sparesSet = state.sparesSets.find((ss) => ss.id === sparesSetId);
            return sparesSet || {};
        },
        sparesSetsItemsFilter: (state) => state.sparesSetsItemsFilter,
    },
    actions: {
        getSparesSetsFromDB({ commit }) {
            return new Promise((resolve, reject) => {
                axios.get(`${siteHost}/api/spares_sets`)
                    .then((res) => {
                        // console.log(res.data);
                        commit("setSparesSets", res.data.spares_sets);
                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        saveSparesSet({ dispatch }, sparesSet) {
            const method = sparesSet.id
                ? "put"
                : "post";

            const url = sparesSet.id
                ? `${siteHost}/api/spares_sets/${sparesSet.id}`
                : `${siteHost}/api/spares_sets`;

            return new Promise((resolve, reject) => {
                axios[method](
                    url,
                    sparesSet,
                )
                    .then(() => {
                        dispatch("getSparesSetsFromDB");
                        resolve("ЗИП сохранен");
                    })
                    .catch((err) => {
                        reject(err.response.data);
                    });
            });
        },
        deleteSparesSetItem({ dispatch }, sparesSetItemId) {
            return new Promise((resolve, reject) => {
                axios.delete(`${siteHost}/api/spares_sets/${sparesSetItemId}`)
                    .then(() => {
                        dispatch("getSparesSetsFromDB");
                        resolve("Элемент ЗИПа удален");
                    })
                    .catch((err) => {
                        reject(err.response.data);
                    });
            });
        },
        setSparesSetsItemsFilter({ commit }, filter) {
            commit("setSparesSetsItemsFilter", filter);
        },
    },
    mutations: {
        setSparesSets(state, sparesSets) {
            state.sparesSets = sparesSets;
        },
        setSparesSetsItems(state, sparesSetsItems) {
            state.sparesSetsItems = sparesSetsItems;
        },
        setSparesSetsItemsFilter(state, filter) {
            state.sparesSetsItemsFilter = filter;
        },
    },
};
