<template>
    <div class="content content__flex-with-table">
        <div class="content-title">
            {{ notifications.length === 0 ? 'Уведомлений нет' : 'Уведомления' }}
            <q-space />
        </div>
        <!-- {{ notifications }} -->
        <div class="notifications-grid">
            <NotificationsRow
                v-for="(n, indexN) in notifications"
                :key="indexN"
                :notification="n"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import NotificationsRow from "./NotificationsRow.vue";

export default {
    name: "Notifications",
    components: {
        NotificationsRow,
    },
    computed: {
        ...mapGetters({
            notifications: "notifications",
        }),
    },
    mounted() {
        this.$store.dispatch("getNotificationsFromDB");
    },
};
</script>
