<template>
    <div class="cell">
        {{ $dayjs(notification.date_time).format("DD.MM.YYYY") }}
    </div>
    <!-- <div class="cell">
        {{ notification.notification_type }}
    </div> -->
    <!-- <div class="cell">
        {{ notification.object_id }}
    </div> -->
    <div class="cell cell-center">
        {{ notification.info }}
    </div>
    <!-- <div class="cell">
        {{ notification.state === NOTIFICATION_STATES.NEW ? "Новое" : "Прочитано" }}
    </div> -->
    <div class="cell cell-center">
        <q-btn
            label="Открыть заявку"
            style="width: 100%;"
            class="poeso-table--control-button"
            @click="showRequest"
        />
    </div>
</template>

<script>
import { getSingleRequest } from "@/helpers/serverDemands";
import DialogEditRequest from "@/components/Dialogs/DialogEditRequest/DialogEditRequest.vue";
import { NOTIFICATION_STATES } from "../../store/constants";

export default {
    name: "NotificationsRow",
    props: {
        notification: {
            type: Object,
            required: true,
        },
    },
    setup() {
        return {
            NOTIFICATION_STATES,
        };
    },
    methods: {
        async showRequest() {
            this.$store.dispatch("markNotificationReaded", this.notification.id);

            const { request } = await getSingleRequest(this.notification.object_id);

            this.$q.dialog({
                component: DialogEditRequest,
                componentProps: {
                    requestForEdit: request,
                    tabStart: "comments",
                },
            });
        },
    },
};
</script>
