<template>
    <q-dialog
        v-model="showFilter"
        position="top"
    >
        <q-card style="width: 70%; max-width: 70%;">
            <div class="filter-grid--tool">
                <SelectFilter
                    v-model="regionId"
                    :options="regions"
                    label="Регион"
                    clearable
                />
                <SelectFilter
                    v-model="unitId"
                    :options="unitsFiltered"
                    optionLabel="name"
                    label="Объект"
                    clearable
                    extendedScope
                    scopeAttr1="name"
                    scopeAttr2="not_needed"
                    scopeAttr3="address"
                />
                <SelectFilter
                    v-model="toolSetNameId"
                    :options="toolsSetsNamesCodes"
                    label="Вид комплекта оборудования"
                    optionLabel="name"
                    clearable
                    outlined
                    dense
                />
                <q-input
                    v-model="toolInventarNumber"
                    outlined
                    dense
                    clearable
                    label="Инвентарный номер"
                >
                    <template #append>
                        <q-checkbox v-model="tollInventarExact">
                            <q-tooltip
                                :delay="250"
                                class="bg-amber text-body2 text-black shadow-4"
                            >
                                Точное совпадение
                            </q-tooltip>
                        </q-checkbox>
                    </template>
                </q-input>

                <PoeDatePicker
                    v-model="toolWorkInDtFrom"
                    labelTitle="Дата ввода в эксплуатацию от"
                    emptyDateAllowed
                    classOuter=""
                />
                <PoeDatePicker
                    v-model="toolWorkInDtTill"
                    labelTitle="Дата ввода в эксплуатацию по"
                    emptyDateAllowed
                    classOuter=""
                />
                <q-select
                    v-model="toolAge"
                    outlined
                    dense
                    clearable
                    :options="TOOLS_AGES"
                    optionValue="value"
                    optionLabel="title"
                    mapOptions
                    emitValue
                    label="Срок использования"
                />
            </div>

            <div class="filter-controls">
                <q-btn
                    label="Очистить"
                    @click="$store.dispatch('setToolsSetsFilter', null)"
                />
                <q-btn
                    label="Закрыть"
                    @click="showFilter = false"
                />
            </div>
        </q-card>
    </q-dialog>

    <q-btn
        v-if="filterIsNotEmpty"
        round
        :icon="matSearchOff"
        @click="$store.dispatch('setToolsSetsFilter', null)"
    >
        <q-tooltip
            :delay="300"
            anchor="top right"
            self="bottom right"
            class="bg-amber text-body2 text-black shadow-4"
        >
            Очистить фильтр
        </q-tooltip>
    </q-btn>
    <q-btn
        round
        :icon="matSearch"
        :color="filterIsNotEmpty ? 'primary' : ''"
        :textColor="filterIsNotEmpty ? 'white' : 'black'"
        @click="showFilter = !showFilter"
    />
</template>

<script>
import { mapGetters } from "vuex";
import {
    RIGHTS_CONSTANTS,
    TOOLS_AGES,
} from "@/store/constants";
import PoeDatePicker from "@/components/Common/PoeDatePicker.vue";
import SelectFilter from "@/components/Common/SelectFilter.vue";

function generateProperty(property) {
    return {
        get() {
            return this.toolsSetsFilter[property];
        },
        set(value) {
            const v = typeof value === "string"
                ? value.trim()
                : value;

            this.$store.dispatch(
                "setToolsSetsFilter",
                {
                    ...this.toolsSetsFilter,
                    [property]: v,
                },
            );
        },
    };
}

export default {
    name: "FilterToolsSets",
    components: {
        PoeDatePicker,
        SelectFilter,
    },
    setup() {
        return {
            TOOLS_AGES,
        };
    },
    data() {
        return {
            showFilter: false,
        };
    },
    computed: {
        ...mapGetters({
            regions: "regions",
            unitsFull: "unitsFull",
            toolsSetsNamesCodes: "toolsSetsNamesCodes",
            currentUser: "currentUser",
            toolsSetsFilter: "toolsSetsFilter",
        }),
        unitsFiltered() {
            const uf = this.regionId
                ? this.unitsFull.filter((u) => u.region_id === this.regionId)
                : this.unitsFull;

            return !!(this.currentUser.rightsObject.RIGHT_TOOLS_READ & RIGHTS_CONSTANTS.ALL)
                ? uf
                : uf.filter((u) => this.currentUser.units.includes(u.id));
        },
        regionId: {
            get() {
                return this.toolsSetsFilter.region_id;
            },
            set(value) {
                this.$store.dispatch(
                    "setToolsSetsFilter",
                    {
                        ...this.toolsSetsFilter,
                        region_id: value,
                        unit_id: value ? null : this.unitId,
                    },
                );
            },
        },
        unitId: generateProperty("unit_id"),
        toolSetNameId: generateProperty("tool_set_name_id"),
        toolInventarNumber: generateProperty("inventar_number"),
        tollInventarExact: generateProperty("inventar_exact"),
        toolAge: generateProperty("tool_age"),
        toolWorkInDtFrom: generateProperty("tool_work_in_dt_from"),
        toolWorkInDtTill: generateProperty("tool_work_in_dt_till"),
        filterIsNotEmpty() {
            return Object.keys(this.toolsSetsFilter).reduce(
                (a, key) => a || this.toolsSetsFilter[key],
                false,
            );
        },
    },
};
</script>
