<template>
    <div
        :class="[
            'row-wrapper',
            getColorClass(request.request_kind_id, request.request_status_id)
        ]"
        @click="$emit('startEdit', requestIndex)"
    >
        <div class="cell cell-center">
            {{ (currentPage - 1) * 20 + requestIndex + 1 }}
            <div
                v-if="notsForRequest > 0"
                color="red-9"
                class="badge"
            >
                {{ notsForRequest }}
            </div>
        </div>
        <div
            v-if="doShowPerformerKind"
            class="cell cell-center"
        >
            <div
                v-if="request.request_performer_kind === PERFORM_KIND.SENIOR"
                class="hot-line-logo"
            >
                ГЛ
            </div>
        </div>
        <div class="cell cell-center">
            {{ $dayjs(request.request_date_add).format("DD.MM.YYYY") }}
        </div>
        <div class="cell cell-center cell-grid">
            <div />
            <div>
                {{ request.number }}
            </div>
            <div>
                <q-btn
                    v-if="allowEditNumber"
                    round
                    :icon="matAutorenew"
                    size="sm"
                    @click="(e) => $emit('changeNumber', e, requestIndex)"
                />
            </div>
        </div>
        <div class="cell cell-center">
            {{ request.hotlinegas_number }}
        </div>
        <div class="cell cell-center">
            {{ request.result_d
                ? $dayjs(request.result_d).format("DD.MM.YYYY")
                : "" }}
        </div>
        <div
            class="cell"
            v-html="formUnitName(request.unit_id, request.contact_id)"
        />
        <div class="cell cell-center">
            {{ getReqKindTitle(request.request_kind_id) }}
        </div>
        <div class="cell cell-center">
            {{ getReqStatusTitle(request.request_status_id) }}
        </div>
        <div class="cell">
            {{ request.request_text }}
        </div>
        <div
            class="cell cell-center"
            style="cursor: default;"
            @click="(event) => event.stopPropagation()"
        >
            <q-btn
                v-if="allowDelRequest"
                round
                :icon="matDelete"
                size="sm"
                class="poeso-table--control-button"
                @click="$emit('deleteRequest', requestIndex)"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
    PERFORM_KIND,
    REQUEST_KINDS_VALUES,
    RIGHTS_CONSTANTS,
} from "@/store/constants";

export default {
    name: "RequestRow",
    props: {
        request: {
            type: Object,
            required: true,
        },
        requestIndex: {
            type: Number,
            required: true,
        },
        currentPage: {
            type: Number,
            required: true,
        },
        doShowPerformerKind: {
            type: Boolean,
            default: true,
        },
    },
    emits: [
        "startEdit",
        "deleteRequest",
        "changeNumber",
    ],
    setup() {
        return {
            PERFORM_KIND,
        };
    },
    computed: {
        ...mapGetters({
            currentUser: "currentUser",
            getUserPostTitle: "getUserPostTitle",
            getUserFIO: "getUserFIO",
            unitsFull: "unitsFull",
            getRegionName: "getRegionName",
            getReqKindTitle: "getReqKindTitle",
            getReqStatusTitle: "getReqStatusTitle",
            notifications: "notifications",
        }),
        allowEditNumber() {
            return !!(this.currentUser.rightsObject.RIGHT_REQUEST_EDIT_NUMBER & RIGHTS_CONSTANTS.FULL);
        },
        allowDelRequest() {
            return !!(this.currentUser.rightsObject.RIGHT_REQUEST_DELETE & RIGHTS_CONSTANTS.FULL);
        },
        notsForRequest() {
            return this.notifications.filter((n) => n.object_id === this.request.id).length;
        },
    },
    methods: {
        getColorClass(requestKindId, requestStatusId) {
            if (requestKindId === REQUEST_KINDS_VALUES.MISTAKE ||
                requestKindId === REQUEST_KINDS_VALUES.REVOKE) {
                return "request-kind__mistake";
            }
            return `request-status__${requestStatusId}`;
        },
        formUnitName(unitId, contactId) {
            const emplPostTitle = this.getUserPostTitle(contactId);
            const unit = this.unitsFull.find((u) => u.id === unitId);
            return [
                unit ? this.getRegionName(unit.region_id) : "",
                unit ? unit.name : "",
                unit ? unit.address : "",
                this.getUserFIO(contactId),
                emplPostTitle ? `${emplPostTitle}` : "",
            ].join("<br>");
        },
    },
};
</script>
