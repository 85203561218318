<template>
    <div class="users-filter">
        <div class="users-filter">
            <SelectFilter
                v-model="regionId"
                :options="regions"
                label="Регион"
                clearable
            />

            <q-input
                v-model="title"
                outlined
                dense
                clearable
                label="Наименование"
                class="users-filter--input"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import SelectFilter from "@/components/Common/SelectFilter.vue";

function generateProperty(property) {
    return {
        get() {
            return this.sparesSetsItemsFilter[property];
        },
        set(value) {
            const v = typeof value === "string"
                ? value.trim()
                : value;

            this.$store.dispatch(
                "setSparesSetsItemsFilter",
                {
                    ...this.sparesSetsItemsFilter,
                    [property]: v,
                },
            );
        },
    };
}

export default {
    name: "FilterSparesSetsItems",
    components: {
        SelectFilter,
    },
    computed: {
        ...mapGetters({
            regions: "regions",
            sparesSetsItemsFilter: "sparesSetsItemsFilter",
        }),
        regionId: generateProperty("regionId"),
        title: generateProperty("title"),
    },
};
</script>
