<template>
    <div
        class="row-wrapper"
        @click="(e) => $emit('startEdit', e, toolIndex)"
    >
        <div class="cell cell-center">
            {{ (toolsPageNumber - 1) * 20 + 1 + toolIndex }}
        </div>
        <div
            class="cell"
            v-html="orgName"
        />
        <div class="cell">
            {{ toolsSetTitle }}
        </div>
        <div :class="[
            'cell',
            toolF.tool_kind_id ? '' : 'cell-warning',
        ]">
            {{ getToolKindName(toolF.tool_kind_id) }}
        </div>
        <div :class="[
            'cell',
            toolF.manufacturer_id ? '' : 'cell-warning',
        ]">
            {{ getManufacturerName(toolF.manufacturer_id) }}
        </div>
        <div :class="[
            'cell',
            toolF.model_id ? '' : 'cell-warning',
        ]">
            {{ toolModelName }}
        </div>
        <div class="cell cell-wrap">
            {{ toolF.serial_number }}
        </div>
        <div class="cell cell-wrap">
            {{ toolF.inventar_number }}
        </div>
        <div class="cell cell-center">
            {{ toolF.work_in_dt ? $dayjs(toolF.work_in_dt).format("DD.MM.YYYY") : "" }}
        </div>
        <div class="cell cell-center">
            {{ toolsStateName(toolF.state) }}
        </div>
        <div class="cell">
            {{ toolF.description }}
        </div>
        <div class="cell cell-center">
            <q-btn
                v-if="canWriteTool"
                round
                :icon="matContentCopy"
                size="sm"
                class="poeso-table--control-button"
                @click="(e) => $emit('startEdit', e, toolIndex, true)"
            >
                <q-tooltip
                    anchor="top right"
                    self="bottom right"
                    :delay="300"
                    class="bg-amber text-body2 text-black shadow-4"
                >
                    Создать копию
                </q-tooltip>
            </q-btn>
        </div>
        <div class="cell cell-center">
            <q-btn
                v-if="canWriteTool"
                round
                :icon="matDelete"
                size="sm"
                class="poeso-table--control-button"
                @click="(e) => $emit('deleteItem', e, toolF)"
            >
                <q-tooltip
                    :delay="300"
                    anchor="top right"
                    self="bottom right"
                    class="bg-amber text-body2 text-black shadow-4"
                >
                    Удалить
                </q-tooltip>
            </q-btn>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { toolsStateName } from "@/helpers/helpers";

export default {
    name: "ToolRow",
    props: {
        toolF: {
            type: Object,
            required: true,
        },
        toolIndex: {
            type: Number,
            required: true,
        },
        canWriteTool: {
            type: Boolean,
            required: true,
        },
    },
    emits: [
        "startEdit",
        "deleteItem",
    ],
    setup() {
        return {
            toolsStateName,
        };
    },
    data() {
        return {
            toolsSetTitle: "",
        };
    },
    computed: {
        ...mapGetters({
            toolsSetName: "toolsSetName",
            getToolKindName: "getToolKindName",
            getManufacturerName: "getManufacturerName",
            getToolModelName: "getToolModelName",
            toolsPageNumber: "toolsPageNumber",
            getOrgProperty: "getOrgProperty",
            getOrgIdOfUnit: "getOrgIdOfUnit",
            unitsFull: "unitsFull",
            getRegionName: "getRegionName",
        }),
        toolModelName() {
            return this.toolF.model_id
                ? this.getToolModelName(this.toolF.model_id)
                : this.toolF.model;
        },
        orgName() {
            const oUnit = this.unitsFull.find((u) => u.id === this.toolF.unit_id);

            const regionTitle = oUnit
                ? this.getRegionName(oUnit.region_id)
                : "";

            return [
                regionTitle,
                this.getOrgProperty(this.getOrgIdOfUnit(this.toolF.unit_id), "name"),
                oUnit ? oUnit.address : "",
            ]
                .join(",<br>")
                .trim();
        },
    },
    mounted() {
        if (this.toolF.tools_set_id) {
            this.$store.dispatch("getToolsSetSingle", this.toolF.tools_set_id)
                .then((data) => {
                    this.toolsSetTitle = this.toolsSetName(data.toolSet.type_id);
                });
        }
    },
    methods: {
    },
};
</script>
