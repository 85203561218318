<template>
    <div class="content content__flex-with-table">
        <div class="content-title">
            Оборудование ({{ toolsCount }})
            <q-space />
            <FilterTools />
            <q-btn
                round
                :icon="matSave"
            >
                <q-tooltip
                    :delay="300"
                    anchor="top right"
                    self="bottom middle"
                    class="bg-amber text-body2 text-black shadow-4"
                >
                    Сохранить список оборудования в файл
                </q-tooltip>
                <q-menu autoClose>
                    <q-list>
                        <q-item
                            clickable
                            @click="saveToolsToFile('docx')"
                        >
                            <q-item-section>
                                В формате docx
                            </q-item-section>
                        </q-item>
                        <q-item
                            clickable
                            @click="saveToolsToFile('xlsx')"
                        >
                            <q-item-section>
                                В формате xlsx
                            </q-item-section>
                        </q-item>
                    </q-list>
                </q-menu>
            </q-btn>
        </div>
        <div class="pagination">
            <q-pagination
                v-model="currentPage"
                color="blue-grey-7"
                :max="toolsPagesCount"
                :maxPages="8"
                :boundaryNumbers="true"
            />
        </div>
        <div class="content-body">
            <div class="tools-grid">
                <!-- heder -->
                <div class="cell cell-header cell-center">
                    №
                </div>
                <div class="cell cell-header cell-center">
                    Регион, Объект, Адрес
                </div>
                <div class="cell cell-header cell-center">
                    Вид комплекта оборудования
                </div>
                <div class="cell cell-header cell-center">
                    Вид оборудования
                </div>
                <div class="cell cell-header cell-center">
                    Производитель
                </div>
                <div class="cell cell-header cell-center">
                    Наименование
                </div>
                <div class="cell cell-header cell-center">
                    Серийный номер
                </div>
                <div class="cell cell-header cell-center">
                    Инвентарный номер
                </div>
                <!-- <div class="cell cell-header cell-center">
                    Входит в комплект
                </div> -->
                <div class="cell cell-header cell-center">
                    Ввод в эксплуатацию
                </div>
                <div class="cell cell-header cell-center">
                    Состояние
                </div>
                <div class="cell cell-header cell-center">
                    Примечание
                </div>
                <div class="cell cell-header cell-center" />
                <div class="cell cell-header cell-center">
                    <q-btn
                        v-if="canWriteTool"
                        round
                        :icon="matAdd"
                        size="0.7rem"
                        color="primary"
                        @click="(e) => startEdit(e, null)"
                    />
                </div>
                <!-- data -->
                <ToolRow
                    v-for="(toolF, toolIndex) in toolsFiltered"
                    :key="toolF.id"
                    :toolF="toolF"
                    :toolIndex="toolIndex"
                    :canWriteTool="canWriteTool"
                    @startEdit="startEdit"
                    @deleteItem="deleteItem"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

import DialogConfirm from "@/components/Dialogs/DialogConfirm.vue";

import FilterTools from "@/components/Filters/FilterTools.vue";
import DialogEditTool from "@/components/Dialogs/DialogEditTool/DialogEditTool.vue";

import {
    RIGHTS_CONSTANTS,
} from "@/store/constants";
import ToolRow from "./ToolsRow.vue";

export default {
    name: "Tools",
    components: {
        FilterTools,
        ToolRow,
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            // access to component instance via `vm`
            if (from.name !== "ToolsAgeCount") {
                vm.$store.dispatch("setToolsFilter", { toolsFilter: null });
            }
        });
    },
    computed: {
        ...mapGetters({
            currentUser: "currentUser",
            toolsFiltered: "toolsFiltered",
            getToolKindName: "getToolKindName",
            getToolModelName: "getToolModelName",
            toolsPagesCount: "toolsPagesCount",
            toolsPageNumber: "toolsPageNumber",
            toolsCount: "toolsCount",
        }),
        currentPage: {
            get() {
                return this.toolsPageNumber;
            },
            set(value) {
                this.$store.dispatch("setToolsPageNumber", value);
            },
        },
        canWriteTool() {
            return !!(this.currentUser.rightsObject.RIGHT_TOOLS_WRITE & RIGHTS_CONSTANTS.FULL);
        },
    },
    methods: {
        async startEdit(event, index, createNew = false) {
            event.stopPropagation();
            const sel = window.getSelection();
            if (sel.type === "Range") {
                return;
            }

            let toolForEdit = {};

            if (index !== null) {
                const tool = this.toolsFiltered[index];

                // get date (it might be not set)
                const work_in_dt = (index !== null
                    ? this.$dayjs(tool.work_in_dt).format("DD.MM.YYYY")
                    : null);
                const guaranty_dt = (index !== null && this.toolsFiltered[index].guaranty_dt
                    ? this.$dayjs(tool.guaranty_dt).format("DD.MM.YYYY")
                    : null);

                toolForEdit = {
                    ...tool,
                    work_in_dt,
                    guaranty_dt,
                };

                toolForEdit.traits = await this.$store.dispatch(
                    "getToolKindTraitsValues",
                    {
                        toolId: tool.id,
                        kindId: tool.tool_kind_id,
                    },
                );

                toolForEdit.filesListDocs = await this.$store.dispatch(
                    "getFilesListFromDB",
                    {
                        section: "tool_docs",
                        id: tool.id,
                    },
                );

                toolForEdit.filesListPictures = await this.$store.dispatch(
                    "getFilesListFromDB",
                    {
                        section: "tool_pictures",
                        id: tool.id,
                    },
                );
            }

            this.$q.dialog({
                component: DialogEditTool,
                componentProps: {
                    itemForEdit: toolForEdit,
                    createNew,
                },
            });
        },
        deleteItem(event, tool) {
            event.stopPropagation();

            this.$q.dialog({
                component: DialogConfirm,
                componentProps: {
                    dialogMessage: [
                        "Удалить оборудование '",
                        this.getToolKindName(tool.tool_kind_id),
                        " ",
                        this.toolModelName(tool),
                        "'?",
                    ].join(""),
                },
            })
                .onOk(() => {
                    this.$store.dispatch("deleteTool", tool.id)
                        .then((message) => {
                            this.$q.notify({
                                type: "notify-success",
                                message,
                            });
                        })
                        .catch((err) => {
                            this.$q.notify({
                                type: "notify-failure",
                                message: err,
                            });
                        });
                });
        },
        toolModelName(tool) {
            return tool.model_id
                ? this.getToolModelName(tool.model_id)
                : tool.model;
        },
        saveToolsToFile(docType) {
            this.$q.dialog({
                component: DialogConfirm,
                componentProps: {
                    dialogMessage: `Список содержит ${this.toolsCount} единиц оборудования. Продолжить?`,
                    labelOk: "Продолжить",
                },
            })
                .onOk(() => {
                    this.$store.dispatch("getToolsFromDBFullList", docType);
                });
        },
    },
};
</script>
