<template>
    <q-dialog
        ref="dialog"
        persistent
    >
        <q-card :style="{
            width: '800px',
            maxWidth: '800px',
        }">
            <div
                v-if="showProgress"
                class="req-dialog-cover"
            >
                <q-circular-progress
                    :value="valueProgress"
                    class="text-light-blue q-ma-md"
                    color="light-blue"
                    centerColor="grey-1"
                    showValue
                    size="5.5em"
                />
            </div>
            <q-card-section>
                <div class="text-h6">
                    Импорт оборудования из Excel файла
                </div>
            </q-card-section>

            <q-separator />

            <q-card-section
                style="max-height: 70vh;"
                class="scroll"
            >
                <SelectFilter
                    v-model="regionId"
                    :options="regions"
                    label="Регион"
                    clearable
                    class="q-mb-sm"
                />
                <SelectFilter
                    v-model="unitId"
                    :options="unitsFiltered"
                    label="Объект"
                    clearable
                    class="q-mb-sm"
                    optionLabel="name"
                    scopeAttr1="not-needed"
                    scopeAttr2="name"
                    scopeAttr3="address"
                    extendedScope
                />

                <q-file
                    v-model="excelFile"
                    outlined
                    dense
                    label="Файл для импорта"
                    accept=".xlsx"
                    class="q-mb-sm"
                    clearable
                />

                <q-checkbox
                    v-model="bClearData"
                    label="Удалить ранее загруженное оборудование"
                />

                <div :style="{
                    display: 'flex',
                    justifyContent: 'center',
                }">
                    <q-btn
                        label="Импортировать"
                        :disable="!unitId || !excelFile"
                        @click="parseFile"
                    />
                </div>

                <div
                    class="q-my-sm"
                    :style="{
                        maxHeight: '200px',
                        overflow: 'auto',
                    }"
                    v-html="result"
                />
            </q-card-section>

            <q-separator />

            <q-card-actions align="right">
                <q-btn
                    v-close-popup
                    flat
                    label="Закрыть"
                    color="primary"
                    :icon="matCancel"
                    @click="hide"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import XlsxPopulate from "xlsx-populate";
import axios from "axios";
import { mapGetters } from "vuex";
import {
    TOOLS_OWNERS_TYPES,
    TOOLS_STATES,
} from "@/store/constants";
import SelectFilter from "@/components/Common/SelectFilter.vue";

const iOle = -2209161600000;
const notValidInventars = [
    "",
    "бн",
    "б\\н",
    "б/н",
    "б.н.",
    "б./н.",
    "БН",
    "Б\\Н",
    "Б/Н",
    "BN",
    "нет",
    "НЕТ",
    "ожидание",
    "без номера",
    "ЗБ",
    "НЕ ПОЛУЧЕН",
    "-",
    "з/б",
    "б/у",
    "отсутствует",
    "?",
    "delete",
    "Списан",
    "БЕЗ И/Н",
    "м/з",
    "н.д.",
];

export default {
    name: "DialogImportFromExcel",
    components: {
        SelectFilter,
    },
    data() {
        return {
            regionId: null,
            unitId: null,
            excelFile: null,
            result: "",
            bClearData: false,
            showProgress: false,
            valueProgress: 0,
        };
    },
    computed: {
        ...mapGetters({
            regions: "regions",
            unitsFull: "unitsFull",
            toolsKinds: "toolsKinds",
            manufacturers: "manufacturers",
            toolsModels: "toolsModels",
            toolsSetsNames: "toolsSetsNames",
        }),
        unitsFiltered() {
            return this.regionId
                ? this.unitsFull.filter((u) => u.region_id === this.regionId)
                : this.unitsFull;
        },
    },
    methods: {
        show() {
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        async parseFile() {
            this.showProgress = true;

            const arrayBuffer = await this.excelFile.arrayBuffer();
            const toolsToAdd = [];
            const errorsList = [];
            const unitObj = this.unitsFull.find((u) => u.id === this.unitId);
            let iToolsSetAdded = 0;
            let iTollsAdded = 0;

            const workbook = await XlsxPopulate.fromDataAsync(arrayBuffer);

            // loop on data
            let iCount = 2;

            const headerContent = [
                "Код объекта".toLowerCase(),
                "Объект".toLowerCase(),
                "Вид комплекта оборудования".toLowerCase(),
                "Вид оборудования".toLowerCase(),
                "Производитель".toLowerCase(),
                "Наименование".toLowerCase(),
                "Серийный номер".toLowerCase(),
                "Инвентарный номер".toLowerCase(),
                "Принадлежность оборудования".toLowerCase(),
                "Дата ввода в эксплуатацию".toLowerCase(),
                "Срок Гарантии".toLowerCase(),
                "Состояние".toLowerCase(),
                "Наименование по бухучёту".toLowerCase(),
                "Примечание".toLowerCase(),
            ];

            const headerLine = [
                workbook.sheet(0).cell("A1").value().trim(), // Код объекта
                workbook.sheet(0).cell("B1").value().trim(), // Объект
                workbook.sheet(0).cell("C1").value().trim(), // Вид комплекта оборудования
                workbook.sheet(0).cell("D1").value().trim(), // Вид оборудо...
                workbook.sheet(0).cell("E1").value().trim(), // Производитель
                workbook.sheet(0).cell("F1").value().trim(), // Наименование
                workbook.sheet(0).cell("G1").value().trim(), // Серийный №
                workbook.sheet(0).cell("H1").value().trim(), // Инвентарный №
                workbook.sheet(0).cell("I1").value().trim(), // Принадлежность оборудования
                workbook.sheet(0).cell("J1").value().trim(), // Дата ввода
                workbook.sheet(0).cell("K1").value().trim(), // Срок гарантии
                workbook.sheet(0).cell("L1").value().trim(), // Состояние
                workbook.sheet(0).cell("M1").value().trim(), // Имя по бухучету
                workbook.sheet(0).cell("N1").value().trim(), // Примечание
            ];

            for (let i = 0; i < headerContent.length; i++) {
                if (headerContent[i] !== headerLine[i].toLowerCase()) {
                    this.result = [
                        "Неверная структура колонок файла",
                        `колонка ${i + 1} должна содержать "${headerContent[i]}""`,
                        `по факту содержит "${headerLine[i]}"`,
                    ].join("<br>");
                    this.showProgress = false;
                    return;
                }
            }

            let bDataPresent = false;
            do {
                const vA = workbook.sheet(0).cell(`A${iCount}`).value(); // Код объекта
                const vB = workbook.sheet(0).cell(`B${iCount}`).value(); // Объект
                const vC = workbook.sheet(0).cell(`C${iCount}`).value(); // Вид комплекта оборудования
                const vD = workbook.sheet(0).cell(`D${iCount}`).value(); // Вид оборудо...
                const vE = workbook.sheet(0).cell(`E${iCount}`).value(); // Производитель
                const vF = workbook.sheet(0).cell(`F${iCount}`).value(); // Наименование
                const vG = workbook.sheet(0).cell(`G${iCount}`).value(); // Серийный №
                const vH = workbook.sheet(0).cell(`H${iCount}`).value(); // Инвентарный №
                const vI = workbook.sheet(0).cell(`I${iCount}`).value(); // Принадлежность оборудования
                const vJ = workbook.sheet(0).cell(`J${iCount}`).value(); // Дата ввода
                const vK = workbook.sheet(0).cell(`K${iCount}`).value(); // Срок гарантии
                const vL = workbook.sheet(0).cell(`L${iCount}`).value(); // Состояние
                const vM = workbook.sheet(0).cell(`M${iCount}`).value(); // Имя по бухучету
                const vN = workbook.sheet(0).cell(`N${iCount}`).value(); // Примечание

                bDataPresent = vA || vB || vC || vD || vE || vF || vG || vH || vI || vJ || vK || vL || vM || vN;
                if (!bDataPresent) {
                    break;
                }

                const tool = {
                    unit_id: this.unitId,
                };

                if (!vA) {
                    errorsList.push(`Строка ${iCount}. VN код не указан`);
                    iCount++;
                    continue;
                }

                if (!vA.startsWith(unitObj.vncode)) {
                    errorsList.push(`Строка ${iCount}. VN код не совпадает с кодом выбранного объекта`);
                    iCount++;
                    continue;
                }

                if (!vC) {
                    tool.toolsSetNameId = null;
                }
                else {
                    const toolsSetName = this.toolsSetsNames.find((tsn) => tsn.name.trim().toLowerCase() === vC.trim().toLowerCase());

                    tool.toolsSetNameId = toolsSetName ? toolsSetName.id : null;
                }

                if (!vD) {
                    errorsList.push(`Строка ${iCount}. Вид оборудования оборудования не указан`);
                    iCount++;
                    continue;
                }

                const toolKind = this.toolsKinds.find((tk) => tk.title.trim().toLowerCase() === vD.trim().toLowerCase());
                if (!toolKind) {
                    errorsList.push(`Строка ${iCount}. Вид оборудования не найден: ${vD.trim()}`);
                    iCount++;
                    continue;
                }

                tool.tool_kind_id = toolKind.id;

                if (!vE) {
                    errorsList.push(`Строка ${iCount}. Производитель оборудования не указан`);
                    iCount++;
                    continue;
                }

                const manufacturer = this.manufacturers.find((m) => m.title.trim().toLowerCase() === vE.trim().toLowerCase());
                if (!manufacturer) {
                    errorsList.push(`Строка ${iCount}. Производитель оборудования в справочнике не найден: ${vE.trim()}`);
                    iCount++;
                    continue;
                }

                tool.manufacturer_id = manufacturer.id;

                if (!vF) {
                    errorsList.push(`Строка ${iCount}. Модель оборудования не указана`);
                    iCount++;
                    continue;
                }

                // eslint-disable-next-line
                // const toolModel = this.toolsModels.find((tm) => {
                //     const tmModel = tm.model.trim().toLowerCase();
                //     const shModel = vF.toString().trim().toLowerCase();

                //     return tmModel === shModel;
                // });

                const tms = this.toolsModels.filter((tm) => {
                    const tmModel = tm.model.trim().toLowerCase();
                    const shModel = vF.toString().trim().toLowerCase();

                    return tmModel === shModel;
                });

                if (tms.length === 0) {
                    errorsList.push(`Строка ${iCount}. Модель оборудования в справочнике не найдена: ${vF.toString().trim()}`);
                    iCount++;
                    continue;
                }

                if (!tms.map((t) => t.tool_kind_id).includes(toolKind.id)) {
                    errorsList.push(`Строка ${iCount}. Для модели ${vF.toString().trim()} неверно указан вид оборудования ${vD}`);
                    iCount++;
                    continue;
                }

                if (!tms.map((t) => t.manufacturer_id).includes(manufacturer.id)) {
                    errorsList.push(`Строка ${iCount}. Для модели ${vF.toString().trim()} неверно указан производитель ${vE}`);
                    iCount++;
                    continue;
                }

                const toolModel = tms.find((t) => (
                    t.tool_kind_id === toolKind.id &&
                    t.manufacturer_id === manufacturer.id
                ));

                tool.model_id = toolModel.id;

                tool.serial_number = vG ? vG.toString().trim() : null;
                tool.inventar_number = vH ? vH.toString().trim() : null;

                if (!vI) {
                    errorsList.push(`Строка ${iCount}. Принадлежность оборудования не указана`);
                    iCount++;
                    continue;
                }

                const ownerType = TOOLS_OWNERS_TYPES.find((e) => e.label.toLowerCase() === vI.trim().toLowerCase());

                if (!ownerType) {
                    errorsList.push(`Строка ${iCount}. Принадлежность оборудования указана не верно`);
                    iCount++;
                    continue;
                }

                tool.owner_type_id = ownerType.value;

                if (typeof vJ === "number") {
                    const iN = Math.round((vJ * 24 * 60 * 60 * 1000) + iOle);
                    tool.work_in_dt = this.$dayjs(iN).toDate();
                }

                if (typeof vJ === "string") {
                    tool.work_in_dt = this.$dayjs(vJ.trim(), "DD.MM.YYYY").toDate();
                }

                if (tool.work_in_dt && vK) {
                    tool.guaranty_dt = this.$dayjs(tool.work_in_dt).add(+vK, "months").toDate();
                }

                if (!vL) {
                    errorsList.push(`Строка ${iCount}. Состояние оборудования не указано`);
                    iCount++;
                    continue;
                }

                const toolState = TOOLS_STATES.find((e) => e.title.toLowerCase() === vL.trim().toLowerCase());

                if (!toolState) {
                    errorsList.push(`Строка ${iCount}. Состояние оборудования указано не верно: ${vL.trim()}`);
                    iCount++;
                    continue;
                }

                tool.state = toolState.id;

                tool.buh_name = vM ? vM.toString().trim() : null;
                tool.description = vN ? vN.toString().trim() : null;

                toolsToAdd.push(tool);

                iCount++;
            }
            while (bDataPresent);

            const toolsSets = Object.groupBy(
                toolsToAdd,
                ({ inventar_number }, index) => {
                    if (notValidInventars.includes(inventar_number)) {
                        return `null_${index}`;
                    }

                    return (inventar_number ? inventar_number.toString().toUpperCase() : `null_${index}`);
                },
            );
            // console.log(toolsToAdd);
            // console.log(toolsSets);

            const inventarsNumbers = Object.keys(toolsSets);

            if (this.bClearData) {
                await axios.post(`${this.siteHost}/api/tools_and_sets_clean/${this.unitId}`);
            }

            for (let i = 0; i < inventarsNumbers.length; i++) {
                const inventarNumber = inventarsNumbers[i];

                // const tools = toolsSets[inventarNumber];
                // let toolsSetWorkInDt = new Date();

                // for (const tool of tools) {
                //     if (tool.work_in_dt < toolsSetWorkInDt) {
                //         toolsSetWorkInDt = tool.work_in_dt;
                //     }
                // }

                const res = await axios.post(
                    `${this.siteHost}/api/tools_import`,
                    {
                        unitId: this.unitId,
                        inventarNumber,
                        // work_in_dt: toolsSetWorkInDt,
                        tools: toolsSets[inventarNumber],
                    },
                );

                if (res.data.iToolsSetId) {
                    iToolsSetAdded++;
                }
                iTollsAdded += res.data.iTollsAdded;

                this.valueProgress = parseFloat(((i * 100) / inventarsNumbers.length).toFixed(2));
            }

            errorsList.unshift(`Найдено ошибок в файле: ${errorsList.length}`);
            errorsList.unshift(`Добавлено единиц оборудования ${iTollsAdded}`);
            errorsList.unshift(`Добавлено комплектов оборудования ${iToolsSetAdded}`);

            this.result = errorsList.join("<br>");

            this.showProgress = false;
        },
    },
};
</script>
