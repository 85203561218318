import axios from "axios";
import Notify from 'quasar/src/plugins/notify/Notify.js';;
import {
    siteHost,
    RIGHTS_CONSTANTS,
} from "@/store/constants";

import { saveToolsToDocx } from "../helpers/saveDataToDocx";
import { saveToolsToXlsx } from "../helpers/saveDataToXlsx";

const toolsFilterEmpty = {
    units_ids: [],
    serial_exact: false,
    inventar_exact: false,
    tool_model_exact: false,
    tool_in_set: false,
    tool_work_in_dt_from: null,
    tool_work_in_dt_till: null,
    tool_state: null,
    tool_description: "",
    tool_set_name_id: null,
    tool_exchange_stock: false,
};

export default {
    state: {
        tools: [],
        toolsTraits: [],
        toolsCount: 0,
        toolsFilter: { ...toolsFilterEmpty },
        toolsAgeCount: [],
        toolsAgeCountFilter: {},
    },
    getters: {
        toolsFiltered: (state) => state.tools,
        toolsCount: (state) => state.toolsCount,
        toolsFilter: (state) => state.toolsFilter,
        toolsAgeCountFilter: (state) => state.toolsAgeCountFilter,
        toolsAgeCount: (state) => state.toolsAgeCount,
    },
    actions: {
        getToolsFromDB({ commit, state, getters }) {
            return new Promise((resolve, reject) => {
                // if can't read - return
                if (!(getters.currentUser.rightsObject.RIGHT_TOOLS_READ & RIGHTS_CONSTANTS.OWN) &&
                    !(getters.currentUser.rightsObject.RIGHT_TOOLS_READ & RIGHTS_CONSTANTS.ALL)) {
                    resolve();
                    return;
                }

                let params = {
                    ...state.toolsFilter,
                    pageNumber: getters.toolsPageNumber,
                    fullList: !!state.toolsFilter.fullList,
                };
                if (!(getters.currentUser.rightsObject.RIGHT_TOOLS_READ & RIGHTS_CONSTANTS.ALL)) {
                    params = {
                        ...params,
                        units: getters.currentUser.units,
                    };
                }

                params = JSON.stringify(params)
                    .replace(/\//g, "%2F")
                    .replace(/\\/g, "%5C");

                axios.get(`${siteHost}/api/tool/${params}`)
                    .then((res) => {
                        commit("setTools", res.data.tools);
                        commit("setToolsPagesCount", res.data.pagesCount);
                        commit("setToolsCount", res.data.itemsCount);

                        if (getters.toolsPageNumber > res.data.pagesCount) {
                            commit("setToolsPageNumber", 1);
                        }
                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getToolsFromDBFullList({ commit, state, getters }, docType) {
            return new Promise((resolve, reject) => {
                // if can't read - return
                if (!(getters.currentUser.rightsObject.RIGHT_TOOLS_READ & RIGHTS_CONSTANTS.OWN) &&
                    !(getters.currentUser.rightsObject.RIGHT_TOOLS_READ & RIGHTS_CONSTANTS.ALL)) {
                    resolve();
                    return;
                }

                let params = {
                    ...state.toolsFilter,
                    pageNumber: getters.toolsPageNumber,
                    fullList: true,
                };
                if (!(getters.currentUser.rightsObject.RIGHT_TOOLS_READ & RIGHTS_CONSTANTS.ALL)) {
                    params = {
                        ...params,
                        units: getters.currentUser.units,
                    };
                }

                params = JSON.stringify(params)
                    .replace(/\//g, "%2F")
                    .replace(/\\/g, "%5C");

                commit("setMainSpinnerState", true);

                axios.get(`${siteHost}/api/tool/${params}`)
                    .then(async (res) => {
                        if (docType === "docx") {
                            await saveToolsToDocx(
                                res.data.tools,
                                getters,
                            );
                        }
                        else {
                            await saveToolsToXlsx(
                                res.data.tools,
                                getters,
                            );
                        }
                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                    })
                    .finally(() => {
                        commit("setMainSpinnerState", false);
                    });
            });
        },
        saveTool({ dispatch }, tool) {
            // console.log(JSON.parse(JSON.stringify(tool)));
            return new Promise((resolve, reject) => {
                const formData = new FormData();

                formData.append("section", "tool_docs");
                formData.append("filesList", JSON.stringify(tool.filesListDocs));
                tool.filesListDocs.forEach((file) => {
                    if (file.blob) {
                        formData.append("tool_docs", file.blob);
                    }
                });

                formData.append("section", "tool_pictures");
                formData.append("filesListPictures", JSON.stringify(tool.filesListPictures));
                tool.filesListPictures.forEach((file) => {
                    if (file.blob) {
                        formData.append("tool_pictures", file.blob);
                    }
                });

                delete tool.filesListDocs;
                delete tool.filesListPictures;
                formData.append("tool", JSON.stringify(tool));

                const url = tool.id
                    ? `${siteHost}/api/tool/${tool.id}`
                    : `${siteHost}/api/tool`;

                const method = tool.id
                    ? "put"
                    : "post";

                axios[method](
                    url,
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    },
                )
                    .then(async (response) => {
                        await dispatch("getToolsFromDB");
                        // dispatch("getToolsAgeCountFromDB");

                        Notify.create({
                            type: "notify-success",
                            message: response.data.message,
                        });

                        resolve();
                    })
                    .catch((err) => {
                        Notify.create({
                            type: "notify-failure",
                            message: err.response.data.message,
                        });

                        reject();
                    });
            });
        },
        deleteTool({ dispatch }, toolId) {
            return new Promise((resolve, reject) => {
                axios.delete(`${siteHost}/api/tool/${toolId}`)
                    .then((response) => {
                        dispatch("getToolsFromDB");
                        // dispatch("getToolsAgeCountFromDB");
                        resolve(response.data.message);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        setToolsFilter({ commit, dispatch }, { toolsFilter, doUpdate = true }) {
            commit("setToolsFilter", toolsFilter);
            commit("setToolsPageNumber", 1);
            if (doUpdate) {
                commit("setMainSpinnerState", true);
                dispatch("getToolsFromDB")
                    .then(() => {
                        commit("setMainSpinnerState", false);
                    });
            }
        },
        setToolsAgeCountFilter({ commit, dispatch }, toolsAgeCountFilter) {
            commit("setToolsAgeCountFilter", toolsAgeCountFilter);
            dispatch("getToolsAgeCountFromDB");
        },
        getToolsAgeCountFromDB({ commit, getters }) {
            return new Promise((resolve, reject) => {
                const params = !!(getters.currentUser.rightsObject.RIGHT_TOOLS_READ & RIGHTS_CONSTANTS.ALL)
                    ? {
                        region_id: getters.toolsAgeCountFilter.region_id,
                        hostId: getters.toolsAgeCountFilter.host_id,
                        unitId: getters.toolsAgeCountFilter.unit_id,
                    }
                    : {
                        region_id: getters.toolsAgeCountFilter.region_id,
                        hostId: getters.toolsAgeCountFilter.host_id,
                        unitId: getters.toolsAgeCountFilter.unit_id,
                        units: getters.currentUser.units,
                    };

                axios.get(
                    `${siteHost}/api/tools_count`,
                    {
                        params,
                    },
                )
                    .then((res) => {
                        commit("setToolsAgeCount", res.data.counts);
                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        // for working with new tools
        getSingleToolFromDB(context, toolId) {
            return new Promise((resolve, reject) => {
                const filter = {
                    pageNumber: 1,
                    toolId,
                };

                axios.get(`${siteHost}/api/tool/${JSON.stringify(filter)}`)
                    .then((res) => {
                        resolve(res.data.tools.length > 0
                            ? res.data.tools[0]
                            : {});
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        updateSingleToolInDB(context, tool) {
            return new Promise((resolve, reject) => {
                axios.put(
                    `${siteHost}/api/tool_attr/${tool.id}`,
                    tool,
                )
                    .then((res) => {
                        resolve(res);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
    },
    mutations: {
        setTools(state, tools) {
            state.tools = tools;
        },
        setToolsCount(state, toolsCount) {
            state.toolsCount = toolsCount;
        },
        setToolsFilter(state, toolsFilter) {
            state.toolsFilter = toolsFilter || { ...toolsFilterEmpty };
        },
        setToolsAgeCountFilter(state, toolsAgeCountFilter) {
            state.toolsAgeCountFilter = toolsAgeCountFilter;
        },
        setToolsAgeCount(state, toolsAgeCount) {
            state.toolsAgeCount = toolsAgeCount;
        },
    },
};
