<template>
    <div class="content content__flex-with-table">
        <div class="content-title">
            Список заявок ({{ reqsCount }})
            <q-space />
            <FilterRequests />
            <q-btn
                round
                :icon="matSave"
            >
                <q-tooltip
                    :delay="300"
                    anchor="top right"
                    self="bottom middle"
                    class="bg-amber text-body2 text-black shadow-4"
                >
                    Выгрузить текущий список заявок
                </q-tooltip>
                <q-menu autoClose>
                    <q-list>
                        <q-item
                            clickable
                            @click="createRequestsList('docx')"
                        >
                            <q-item-section>
                                В формате docx
                            </q-item-section>
                        </q-item>
                        <q-item
                            clickable
                            @click="createRequestsList('xlsx')"
                        >
                            <q-item-section>
                                В формате xlsx
                            </q-item-section>
                        </q-item>
                    </q-list>
                </q-menu>
            </q-btn>
        </div>

        <div class="pagination">
            <q-pagination
                v-model="currentPage"
                color="blue-grey-7"
                :max="reqsPagesCount"
                :maxPages="8"
                :boundaryNumbers="true"
            />
        </div>

        <div class="requests-grid">
            <!-- header -->
            <div class="cell cell-header cell-center">
                №
            </div>
            <div class="cell cell-header cell-center">
                ГЛ / Филиал
            </div>
            <div class="cell cell-header cell-center">
                Дата регистрации
            </div>
            <div class="cell cell-header cell-center">
                Номер
            </div>
            <div class="cell cell-header cell-center">
                Номер на ГЛ
                <q-tooltip
                    anchor="top middle"
                    self="center middle"
                    class="bg-amber text-body2 text-black shadow-4"
                >
                    Номер на "Горячей Линии"
                </q-tooltip>
            </div>
            <div class="cell cell-header cell-center">
                Дата исполнения
            </div>
            <div class="cell cell-header cell-center">
                Организация
            </div>
            <div class="cell cell-header cell-center">
                Тип
            </div>
            <div class="cell cell-header cell-center">
                Состояние
            </div>
            <div class="cell cell-header cell-center">
                Содержание
            </div>
            <div class="cell cell-header" />
            <!-- data -->
            <RequestsRow
                v-for="(request, requestIndex) in requests"
                :key="requestIndex"
                :request="request"
                :requestIndex="requestIndex"
                :currentPage="currentPage"
                @startEdit="startEdit"
                @deleteRequest="deleteRequest"
                @changeNumber="changeNumber"
            />
        </div>
        <div class="app-controls">
            <ButtonNewItem
                v-if="allowCreateRequest"
                buttonToolTip="Создать заявку"
                @startEdit="startEdit"
            />
        </div>
    </div>
    <div
        v-if="showSpinner"
        class="req-dialog-cover"
    >
        <q-spinner-grid
            color="primary"
            size="5.5em"
        />
    </div>
</template>

<script>
import { mapGetters } from "vuex";

import {
    RIGHTS_CONSTANTS,
} from "@/store/constants";

import FilterRequests from "@/components/Filters/FilterRequests.vue";
import DialogConfirm from "@/components/Dialogs/DialogConfirm.vue";
import ButtonNewItem from "@/components/Common/ButtonNewItem.vue";
import DialogEditRequest from "@/components/Dialogs/DialogEditRequest/DialogEditRequest.vue";
import DialogChangeRequestNumber from "@/components/Dialogs/DialogChangeRequestNumber.vue";
import RequestsRow from "./RequestsRow.vue";

export default {
    name: "Requests",
    components: {
        FilterRequests,
        ButtonNewItem,
        RequestsRow,
    },
    data() {
        return {
            showSpinner: false,
        };
    },
    computed: {
        ...mapGetters({
            requests: "requests",
            reqsPagesCount: "reqsPagesCount",
            reqsPageNumber: "reqsPageNumber",
            currentUser: "currentUser",
            reqsCount: "reqsCount",
        }),
        currentPage: {
            get() {
                return this.reqsPageNumber;
            },
            set(value) {
                this.$store.dispatch("setReqsPageNumber", value);
            },
        },
        allowCreateRequest() {
            return !!(this.currentUser.rightsObject.RIGHT_REQUEST_CREATE &
                (RIGHTS_CONSTANTS.OWN | RIGHTS_CONSTANTS.ALL));
        },
    },
    mounted() {
        this.$store.dispatch("cleanReqsFilter");
    },
    methods: {
        startEdit(index) {
            const sel = window.getSelection();
            if (sel.type === "Range") {
                return;
            }

            let requestForEdit = {};
            if (index !== undefined) {
                requestForEdit = JSON.parse(JSON.stringify(this.requests[index]));
            }

            this.$q.dialog({
                component: DialogEditRequest,
                componentProps: {
                    requestForEdit,
                },
            });
        },
        deleteRequest(index) {
            this.$q.dialog({
                component: DialogConfirm,
                componentProps: {
                    dialogMessage: `Удалить заявку № ${this.requests[index].number}?`,
                },
            })
                .onOk(() => {
                    this.$store.dispatch("deleteRequest", this.requests[index].id)
                        .then(() => {
                            this.$q.notify({
                                type: "notify-success",
                                message: "Заявка удалена",
                            });
                        })
                        .catch((err) => {
                            this.$q.notify({
                                type: "notify-failure",
                                message: err,
                            });
                        });
                });
        },
        createRequestsList(docType) {
            this.$q.dialog({
                component: DialogConfirm,
                componentProps: {
                    dialogMessage: "Формирование документа может занять продолжительное время",
                    labelOk: "Продолжить",
                    labelCancel: "Отменить",
                },
            })
                .onOk(() => {
                    this.$store.dispatch(
                        "getAllRequestsFromDB",
                        docType,
                    );
                });
        },
        changeNumber(event, index) {
            event.stopPropagation();
            this.$q.dialog({
                component: DialogChangeRequestNumber,
                componentProps: {
                    request: this.requests[index],
                },
            });
        },
    },
};
</script>
