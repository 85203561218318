<template>
    <div>
        <div class="text-h6">
            Запчасти
        </div>
        <div :class="[
            'q-pb-xs',
            allowViewCost ? 'request-spares-grid' : 'request-spares-grid--short',
        ]">
            <!-- header -->
            <div class="cell cell-header cell-center">
                №
            </div>
            <div class="cell cell-header cell-center">
                Наименование
            </div>
            <div
                v-if="allowViewCost"
                class="cell cell-header cell-center"
            >
                Стоимость
            </div>
            <div class="cell cell-header cell-center">
                Количество
            </div>
            <div class="cell cell-header cell-center">
                <q-btn
                    round
                    :icon="matAdd"
                    size="sm"
                    color="primary"
                    :disable="!canBeEdited"
                    @click="addSpare"
                />
            </div>
            <!-- data -->
            <RequestSpareRow
                v-for="(requestSpare, index) in reqSpares"
                :key="index"
                :requestSpare="requestSpare"
                :spareSetItemOrig="getSpareSetItemOrig(requestSpare.spare_id)"
                :index="index"
                :canBeEdited="canBeEdited"
                :allowViewCost="allowViewCost"
            />
        </div>
        <div
            v-if="allowViewCost"
            class="q-pb-xs"
        >
            <q-input
                :modelValue="reqSparesCost"
                readonly
                outlined
                dense
                label="Общая стоимость запчастей"
                class="q-py-xs"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import RequestSpareRow from "./RequestSpareRow.vue";
import DialogSearchSpare from "./DialogSearchSpare.vue";

export default {
    name: "MovingRepairSpares",
    components: {
        RequestSpareRow,
    },
    props: {
        canBeEdited: {
            type: Boolean,
            required: true,
        },
        allowViewCost: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        ...mapGetters({
            request: "request",
            reqSpares: "reqSpares",
            sparesSetsItems: "sparesSetsItems",
        }),
        reqSparesCost() {
            return this.reqSpares.reduce(
                (acc, spare) => (
                    acc + (+spare.cost * +spare.amount)
                ),
                0,
            ).toFixed(2);
        },
        sparesSetsItemsOrig() {
            return this.sparesSetsItems.map((item) => ({
                ...item,
                requests_spares: item.requests_spares.filter((rs) => rs.request_id !== this.request.id),
            }));
        },
    },
    methods: {
        getSpareSetItemOrig(spareSetItemId) {
            return this.sparesSetsItemsOrig.find((e) => e.id === spareSetItemId);
        },
        addSpare() {
            this.$q.dialog({
                component: DialogSearchSpare,
            })
                .onOk((spare) => {
                    this.$store.dispatch("addRequestSpare", spare);
                });
        },
    },
};
</script>
