import axios from "axios";
import { createStore } from "vuex";
import Notify from 'quasar/src/plugins/notify/Notify.js';;
import { siteHost } from "./constants";

import auth from "./auth";
import usersRolesUnits from "./usersRolesUnits";
import users from "./users";
import notifications from "./notifications";
import organizations from "./organizations";
import posts from "./posts";
import regions from "./regions";
import manufacturers from "./manufacturers";
import tools from "./tools";
import toolsKinds from "./toolsKinds";
import toolsKindsTraits from "./toolsKindsTraits";
import toolsModels from "./toolsModels";
import toolsSets from "./toolsSets";
import sparesSets from "./sparesSets";
import roles from "./roles";
import requests from "./requests";
import requestValidation from "./requestValidation";
import pagination from "./pagination";
import programs from "./programs";
import requestsInner from "./requestsInner";
import requestDocs from "./requestDocs";
import requestSpares from "./requestSpares";
import requestWorks from "./requestWorks";
import requestComments from "./requestComments";
import requestToolsCommissioning from "./requestToolsCommissioning";
import filesForSigners from "./filesForSigners";
import servicesRepairs from "./servicesRepairs";
import problemsCategories from "./problemsCategories";
import commonDisrepairs from "./commonDisrepairs";
import gasSoftRenewals from "./gasSoftRenewals";
import manuals from "./manuals";

const poesoStore = createStore({
    state: {
        showMainSpinner: false,
        versionsHistory: "",
        serverAppVersion: "",
    },
    getters: {
        appDevMode: () => process.env.VUE_APP_MODE === "development",
        showMainSpinner: (state) => state.showMainSpinner,
        versionsHistory: (state) => state.versionsHistory,
        serverAppVersion: (state) => state.serverAppVersion,
    },
    actions: {
        async loadDataFromDB({ commit, dispatch }) {
            commit("setMainSpinnerState", true);

            const pA = dispatch("getRegionsFromDB");
            const pB = dispatch("getOrganizationsFromDB");

            Promise
                .all([pA, pB])
                .finally(() => {
                    const p01 = dispatch("getUnitsFromDB");
                    const p02 = dispatch("getOrgKindsFromDB");
                    const p03 = dispatch("getPostsFromDB");
                    const p04 = dispatch("getProgramsFromDB");
                    const p05 = dispatch("getProgramsSubsystemsFromDB");
                    const p06 = dispatch("getProblemsCategoriesFromDB");
                    const p07 = dispatch("getUsersFromDB");
                    const p08 = dispatch("getRolesListFromDB");
                    const p09 = dispatch("getUsersRolesFromDB");
                    const p10 = dispatch("getUsersUnitsFromDB");
                    const p11 = dispatch("getServicesRepairsFromDB");
                    const p12 = dispatch("getManufacturersFromDB");
                    const p13 = dispatch("getToolsKindsFromDB");
                    const p14 = dispatch("getToolsKindsTraitsFromDB");
                    const p15 = dispatch("getToolsModelsFromDB");
                    const p16 = dispatch("getToolsSetsNamesFromDB");
                    const p17 = dispatch("getSparesSetsFromDB");
                    const p20 = dispatch("getCommonDisrepairsFromDB");
                    const p21 = dispatch("getVersionsHistory");

                    Promise
                        .all([
                            p01, p02, p03, p04, p05, p06, p07, p08, p09, p10,
                            p11, p12, p13, p14, p15, p16, p17, p20,
                            p21,
                        ])
                        .finally(() => {
                            commit("setMainSpinnerState", false);
                        });
                });
        },
        getVersionsHistory({ commit }) {
            return new Promise((resolve, reject) => {
                axios.get(`${siteHost}/api/versions-history`)
                    .then((res) => {
                        commit(
                            "setVersionsHistory",
                            res.data.versionsHistory ? res.data.versionsHistory.version_history : "",
                        );
                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        saveVersionsHistory({ commit }, versionsHistory) {
            return new Promise((resolve, reject) => {
                axios.post(
                    `${siteHost}/api/versions-history`,
                    { versionsHistory },
                )
                    .then(() => {
                        commit("setVersionsHistory", versionsHistory);
                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        setServerAppVersion({ commit }, version) {
            commit("setServerAppVersion", version);
        },
    },
    mutations: {
        setMainSpinnerState(state, spinnerState) {
            state.showMainSpinner = spinnerState;
        },
        setVersionsHistory(state, versionsHistory) {
            state.versionsHistory = versionsHistory;
        },
        setServerAppVersion(state, version) {
            state.serverAppVersion = version;
        },
    },
    modules: {
        auth,
        usersRolesUnits,
        users,
        notifications,
        organizations,
        posts,
        regions,
        manufacturers,
        tools,
        toolsKinds,
        toolsKindsTraits,
        toolsModels,
        toolsSets,
        sparesSets,
        roles,
        requests,
        requestValidation,
        pagination,
        programs,
        requestsInner,
        requestDocs,
        requestSpares,
        requestWorks,
        requestComments,
        requestToolsCommissioning,
        filesForSigners,
        servicesRepairs,
        problemsCategories,
        commonDisrepairs,
        gasSoftRenewals,
        manuals,
    },
});

// Add a request interceptor
axios.defaults.withCredentials = true;

axios.interceptors.request.use(
    (config) => {
        // Do something before request is sent
        // console.log("AXIOS REQUEST INTERCEPTOR");
        config.headers.userId = poesoStore.getters.currentUser.id;
        config.headers.accessToken = poesoStore.getters.currentUser.accessToken;
        return config;
    },
    (error) => {
        // Do something with request error
        console.log("AXIOS ERROR");
        return Promise.reject(error);
    },
);

axios.interceptors.response.use(
    (response) => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        // console.log(response.headers.freshtoken);
        // console.log(response.headers.appversion);

        // console.log(response);

        if (!response.config.url.includes("public/files")) {
            poesoStore.dispatch("setUserToken", response.headers.freshtoken);
        }

        poesoStore.dispatch("setServerAppVersion", response.headers.appversion);
        // poesoStore.dispatch("setNotsCount", response.headers.notscount);

        return response;
    },
    (error) => {
        // console.log(error.response);
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        console.log(error);

        if (!error.response) {
            Notify.create({
                type: "notify-failure",
                message: "Ошибка сети. Перегрузите страницу",
            });
        }
        else if ([404, 409].includes(error.response.status)) {
            //
        }
        else {
            poesoStore.dispatch("logout");
            // router.push({ name: "Home" });
            if (window.location.pathname !== "/login") {
                window.location.assign("/");
            }
        }
        return Promise.reject(error);
    },
);

export default poesoStore;
