<template>
    <q-dialog
        ref="dialog"
        persistent
    >
        <q-card style="width: 700px; max-width: 80vw;">
            <q-card-section>
                <div class="text-h6">
                    Выбор новой организации для объекта
                </div>
            </q-card-section>

            <q-card-section>
                <SelectFilter
                    v-model="regionId"
                    :options="regions"
                    label="Регион"
                    class="q-mb-sm"
                    clearable
                />
                <SelectFilter
                    v-model="organizationId"
                    :options="organizationsFiltered"
                    optionLabel="name"
                    label="Организация"
                    clearable
                    extendedScope
                    scopeAttr1="name"
                    scopeAttr2="not_needed"
                    scopeAttr3="vncode"
                />
            </q-card-section>
            <q-card-actions align="right">
                <q-btn
                    flat
                    label="Переместить"
                    color="primary"
                    :icon="matSave"
                    :disable="!organizationId"
                    @click="onOkClick"
                />
                <q-btn
                    v-close-popup
                    flat
                    label="Отменить"
                    color="primary"
                    :icon="matCancel"
                    @click="hide"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import SelectFilter from "@/components/Common/SelectFilter.vue";

export default {
    name: "DialogChooseOrg",
    components: {
        SelectFilter,
    },
    emits: ["ok"],
    data() {
        return {
            regionId: null,
            organizationId: null,
        };
    },
    computed: {
        ...mapGetters({
            regions: "regions",
            organizations: "orgs",
        }),
        organizationsFiltered() {
            return this.regionId
                ? this.organizations.filter((o) => o.region_id === this.regionId)
                : this.organizations;
        },
    },
    watch: {
        regionId() {
            this.organizationId = null;
        },
    },
    methods: {
        show() {
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        onOkClick() {
            this.$emit("ok", this.organizationId);
            this.hide();
        },
    },
};
</script>
