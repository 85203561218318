<template>
    <q-btn
        label="Выбор оборудования"
        class="cell-span-col-3"
        color="primary"
        :disable="cannotBeEdited"
        @click="toolCommissioningStartSearch"
    />

    <div class="tools-commissioning-grid cell-span-col-6">
        <template
            v-for="toolC in toolsList"
            :key="toolC.tool_id"
        >
            <div class="cell">
                {{ `${toolC.toolDescription}, серийный № ${toolC.serialNumber}` }}
            </div>
            <q-input
                v-model="toolC.settling_place"
                borderless
                dense
                class="cell"
                autogrow
                label="Место установки"
                :disable="cannotBeEdited"
                @update:modelValue="saveToolsList"
            />
            <q-input
                v-model="toolC.instructed_person"
                borderless
                dense
                class="cell"
                autogrow
                label="ФИО лица, прошедшего инструктаж"
                :disable="cannotBeEdited"
                @update:modelValue="saveToolsList"
            />
        </template>
    </div>
    <q-select
        v-model="result"
        outlined
        dense
        class="cell-span-col-6"
        label="Результат исполнения"
        clearable
        :options="PERFORM_RESULTS_COMMISSIONING"
        emitValue
        mapOptions
        :disable="cannotBeEdited"
    />
    <q-select
        v-model="commissioningYear"
        outlined
        dense
        class="cell-span-col-2"
        label="Год выпуска"
        clearable
        :options="yearsList"
        :disable="cannotBeEdited"
    />
    <PoeDatePicker
        v-model="resultDate"
        dateFormat="YMD"
        outlined
        dense
        class="cell-span-col-2"
        label="Дата исполнения"
        emptyDateAllowed
        :minDate="new Date(request.request_date_add)"
        :disable="cannotBeEdited"
        classOuter=""
    />
    <q-field
        outlined
        dense
        class="cell-span-col-2"
        label="Время исполнения"
        :stackLabel="result_time !== null"
        color="dark"
        labelColor="grey-9"
        disable
    >
        <div>
            {{ result_time }}
        </div>
    </q-field>
    <q-input
        v-model="fulfielledWorks"
        outlined
        dense
        class="cell-span-col-6"
        type="textarea"
        :inputStyle="{ resize: 'none' }"
        label="Выполненные работы"
        :disable="cannotBeEdited"
    />
    <q-btn
        filled
        class="cell-span-col-2"
        color="primary"
        label="Сформировать Акт СО-1.3"
        :disable="cannotBeEdited || actIsPresentCO13 || dataNotFilled"
        @click="createActCo13"
    />
    <q-field
        outlined
        dense
        class="cell-span-col-2"
        label="№ акта СО-1.3"
        :stackLabel="!!co13Number"
    >
        <div
            class="self-center full-width no-outline"
            tabindex="0"
        >
            {{ co13Number }}
        </div>
    </q-field>
    <q-field
        outlined
        dense
        class="cell-span-col-2"
        label="Дата акта СО-1.3"
        :stackLabel="!!co13Date"
    >
        <div
            class="self-center full-width no-outline"
            tabindex="0"
        >
            {{ co13Date }}
        </div>
    </q-field>
</template>

<script>
import { mapGetters } from "vuex";
import { date } from "quasar";
import generateRequestProperty from "@/helpers/generateRequestProperty";
import { dateDiffYMD } from "@/helpers/helpers";
import { createDoc } from "@/helpers/createDoc";
import {
    REQUEST_STATUS_VALUES,
    PERFORM_RESULTS_IDS,
    PERFORM_RESULTS_COMMISSIONING,
    PROGRAM_TYPES_VALUES,
    DATE_LOCALE_2,
} from "@/store/constants";
import PoeDatePicker from "@/components/Common/PoeDatePicker.vue";
import DialogSigners from "@/components/Dialogs/DialogSigners/DialogSigners.vue";
import DialogSearchToolCommissioning from "@/components/Dialogs/DialogSearchToolCommissioning.vue";

export default {
    name: "DialogEditRequestMovingCommissioning",
    components: {
        PoeDatePicker,
    },
    props: {
        canBeEdited: {
            type: Boolean,
            required: true,
        },
    },
    setup() {
        const y = (new Date()).getFullYear();

        return {
            REQUEST_STATUS_VALUES,
            PERFORM_RESULTS_COMMISSIONING,
            yearsList: Array.from({ length: 20 }, (v, i) => y - i),
        };
    },
    data() {
        return {
            toolsList: [],
        };
    },
    computed: {
        ...mapGetters({
            regions: "regions",
            request: "request",
            currentUser: "currentUser",
            requestDocs: "requestDocs",
            getOrgIdOfUnit: "getOrgIdOfUnit",
            getOrgProperty: "getOrgProperty",
            getUserFIO: "getUserFIO",
            getUserFIOshort: "getUserFIOshort",
            getUserPostTitle: "getUserPostTitle",
            getUserDueOrder: "getUserDueOrder",
            getManufacturerName: "getManufacturerName",
            getToolKindName: "getToolKindName",
            getToolModelName: "getToolModelName",
            unitAddress: "unitAddress",
            servicesRepairs: "servicesRepairs",
            getSignersCountByOrgKind: "getSignersCountByOrgKind",
            programs: "programs",
            requestToolsCommissioning: "requestToolsCommissioning",
        }),
        commissioningYear: generateRequestProperty("commissioning_year"),
        fulfielledWorks: generateRequestProperty("fulfielled_works"),
        result: generateRequestProperty("result"),
        resultDate: generateRequestProperty("result_d"),
        co13Number: generateRequestProperty("co_13_number"),
        co13Date: {
            get() {
                return this.request.co_13_d
                    ? date.formatDate(
                        this.request.co_13_d,
                        "DD.MM.YYYY",
                    )
                    : null;
            },
            set(v) {
                this.$store.dispatch(
                    "setRequestField",
                    {
                        field: "co_13_d",
                        value: v,
                    },
                );
            },
        },
        cannotBeEdited() {
            return (
                // status
                this.request.request_status_id !== REQUEST_STATUS_VALUES.PERFORMING ||
                // rights
                !this.canBeEdited
            );
        },
        result_time() {
            return this.resultDate
                ? dateDiffYMD(this.request.request_date_add, this.resultDate)
                : null;
        },
        signersCountByOrgKind() {
            return this.getSignersCountByOrgKind(this.request.unit_id);
        },
        regionTitleInstrumentalCase() {
            const regionId = this.getOrgProperty(
                this.getOrgIdOfUnit(this.request.unit_id),
                "region_id",
            );

            const region = this.regions.find((r) => r.id === regionId);

            return region ? region.instrumental_case : "XXXX";
        },
        serviceCenter() {
            const sr = this.servicesRepairs.find((s) => (
                s.region_id === this.currentUser.region_id &&
                s.service_type === 1
            ));

            return sr
                ? `${sr.title}, ${sr.address}`
                : "";
        },
        vncode() {
            return this.getOrgProperty(
                this.getOrgIdOfUnit(this.request.unit_id),
                "vncode",
            );
        },
        requestYear() {
            return new Date(this.request.request_date_add).getFullYear();
        },
        fileName() {
            return `${this.vncode}_actCO1.3_${this.request.number}_${this.requestYear}.docx`;
        },
        actIsPresentCO13() {
            return this.requestDocs
                .map((rd) => rd.file_name)
                .includes(this.fileName);
        },
        dataNotFilled() {
            if (this.toolsList.length === 0) {
                return true;
            }

            const tollsListFileld = this.toolsList.reduce(
                (a, e) => a && !!e.settling_place && !!e.instructed_person,
                true,
            );

            if (
                !tollsListFileld ||
                !this.result ||
                !this.commissioningYear ||
                !this.resultDate ||
                !this.fulfielledWorks
            ) {
                return true;
            }

            return false;
        },
    },
    watch: {
        "requestDocs.length": {
            handler() {
                if (!this.actIsPresentCO13) {
                    this.co13Number = null;
                    this.co13Date = null;
                }
            },
        },
        result(v) {
            this.fulfielledWorks = v === PERFORM_RESULTS_IDS.FULFILED
                ? "Работы по вводу в эксплуатацию выполнены в полном объеме"
                : "";
        },
    },
    created() {
        // this.getToolFromDb();
    },
    mounted() {
        if (!this.actIsPresentCO13) {
            this.co13Number = null;
            this.co13Date = null;
        }

        this.toolsList = this.requestToolsCommissioning.map((e) => ({ ...e }));
        this.updateToolsList(this.toolsList);
    },
    methods: {
        async updateToolsList(tools) {
            // await this.$store.dispatch("setRequestLoading", true);
            await this.$store.dispatch("setReqeustLoadingProgress", 0.001);
            const toolsExt = [];

            for (let i = 0; i < tools.length; i++) {
                await this.$store.dispatch("setReqeustLoadingProgress", ((i + 1) * 100) / tools.length);

                const toolA = await this.getToolFromDb(tools[i].tool_id);

                const toolP = this.toolsList.find((t) => t.tool_id === tools[i].tool_id);

                const toolDescription = toolA
                    ? [
                        `${this.getToolKindName(toolA.tool_kind_id) || "?"}`,
                        `${this.getManufacturerName(toolA.manufacturer_id) || "?"}`,
                        `${this.getToolModelName(toolA.model_id) || "?"}`,
                        // ` серийный № ${toolA.serial_number || "?"}`,
                    ].join(", ")
                    : "";

                let toolSoftNames = "";
                let toolSoftVersions = "";

                if (toolA && toolA.tool_softwares && toolA.tool_softwares.length > 0) {
                    const softNames = [];
                    const softVers = [];

                    for (let j = 0; j < toolA.tool_softwares.length; j++) {
                        const pr = this.programs.find((p) => p.id === toolA.tool_softwares[j].program_id);
                        if (pr && pr.type === PROGRAM_TYPES_VALUES.SOFT_COMMON) {
                            softNames.push(pr.title);
                            softVers.push(pr.version);
                        }
                    }
                    toolSoftNames = softNames.join(", ");
                    toolSoftVersions = softVers.join(", ");
                }

                toolsExt.push({
                    tool_id: tools[i].tool_id,
                    settling_place: toolP
                        ? toolP.settling_place
                        : "",
                    instructed_person: toolP
                        ? toolP.instructed_person
                        : "",
                    toolDescription,
                    serialNumber: toolA.serial_number,
                    toolSoftNames,
                    toolSoftVersions,
                });
            }

            this.toolsList = toolsExt;
            this.$store.dispatch("setRequestToolsCommissioning", this.toolsList);
            // await this.$store.dispatch("setRequestLoading", false);
            await this.$store.dispatch("setReqeustLoadingProgress", 0);
        },
        toolCommissioningStartSearch() {
            this.$q.dialog({
                component: DialogSearchToolCommissioning,
                componentProps: {
                    toolsIdsOrig: this.toolsList.map((t) => t.tool_id),
                },
            })
                .onOk(async (toolsCommissioning) => {
                    await this.updateToolsList(toolsCommissioning.map((t) => ({
                        tool_id: t,
                    })));
                });
        },
        getToolFromDb(toolId) {
            return new Promise((resolve) => {
                this.$store.dispatch("getSingleToolFromDB", toolId)
                    .then((toolFromDb) => {
                        resolve(toolFromDb);
                    })
                    .catch(() => {
                        resolve({
                            serial_number: "",
                            inventar_number: "",
                        });
                    });
            });
        },
        saveToolsList() {
            this.$store.dispatch("setRequestToolsCommissioning", this.toolsList);
        },
        createActCo13() {
            if (!this.request.hotlinegas_number) {
                this.$q.notify({
                    type: "notify-failure",
                    message: "Создание акта невозможно, заполние поле 'Номер на \"ГЛ ГАС ПРАВОСУДИЕ\"'",
                });
                return;
            }

            this.$q.dialog({
                component: DialogSigners,
                componentProps: {
                    signersCountByOrgKind: this.signersCountByOrgKind,
                    showDocDate: true,
                    allowElectronSign: false,
                },
            })
                .onOk(({
                    actDate,
                    signers,
                    signType,
                    signersCount,
                }) => {
                    const dateDocLong = date.formatDate(
                        date.extractDate(actDate, "DD.MM.YYYY"),
                        "«DD» MMMM YYYY г.",
                        DATE_LOCALE_2,
                    );

                    this.co13Number = `${this.vncode}-${this.request.number}/${this.requestYear}`;
                    this.co13Date = date.extractDate(actDate, "DD.MM.YYYY");

                    const items = [];

                    for (let i = 0; i < this.toolsList.length; i++) {
                        items.push({
                            tool_order: i + 1,
                            tool_name: this.toolsList[i].toolDescription,
                            tool_serial: this.toolsList[i].serialNumber || "",
                            c_year: this.commissioningYear,
                            c_place: this.toolsList[i].settling_place,
                            tool_soft_name: this.toolsList[i].toolSoftNames,
                            tool_soft_version: this.toolsList[i].toolSoftVersions,
                            c_fio: this.toolsList[i].instructed_person,
                        });
                    }

                    createDoc(
                        signersCount === 3 ? "act-co-1.3.docx" : "act-co-1.3-2.docx",
                        this.fileName,
                        {
                            act_number: this.co13Number,
                            act_date: dateDocLong,
                            act_org_title: this.getOrgProperty(
                                this.getOrgIdOfUnit(this.request.unit_id),
                                "name",
                            ),
                            act_org_address: this.unitAddress(this.request.unit_id),
                            signer_iac_fio_full: this.getUserFIO(signers[0].id),
                            signer_iac_post: this.getUserPostTitle(signers[0].id),
                            signer_iac_dueorder: this.getUserDueOrder(signers[0].id) || signers[0].dueOrder,
                            signer_iac_fio_short: this.getUserFIOshort(signers[0].id),
                            signer_unit_post: this.getUserPostTitle(signers[1].id),
                            signer_unit_dueorder: this.getUserDueOrder(signers[1].id) || signers[1].dueOrder,
                            signer_unit_fio_short: this.getUserFIOshort(signers[1].id),
                            signer_final_fio_full: this.getUserFIO(signers[2].id) || this.getUserFIO(signers[1].id),
                            signer_final_post: this.getUserPostTitle(signers[2].id) || this.getUserPostTitle(signers[1].id),
                            signer_final_dueorder: this.getUserDueOrder(signers[2].id) ||
                                signers[2].dueOrder ||
                                this.getUserDueOrder(signers[1].id) ||
                                signers[1].dueOrder,
                            signer_final_fio_short: this.getUserFIOshort(signers[2].id) || this.getUserFIOshort(signers[1].id),
                            service_center: this.serviceCenter,

                            region_title: this.regionTitleInstrumentalCase,
                            items,
                        },
                    )
                        .then((fileDoc) => {
                            this.$store.dispatch(
                                "addRequestDoc",
                                {
                                    file_name: fileDoc.name,
                                    mimetype: fileDoc.type,
                                    section: "requestDocs",
                                    blob: fileDoc,
                                    signers_count: signers[2].id ? 3 : 2,
                                    signer_iac_id: signers[0].id,
                                    signer_unit_id: signers[1].id,
                                    signer_final_id: signers[2].id,
                                    sign_type: signType,
                                },
                            );
                            this.$q.notify({
                                type: "notify-success",
                                message: "Акт сформирован.<br>Помещен в раздел 'Документы'",
                            });
                        });
                });
        },
    },
};
</script>
