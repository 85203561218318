<template>
    <q-input
        v-model="commentComment"
        outlined
        dense
        autogrow
        label="Новое сообщение"
        class="q-mb-sm"
        hideBottomSpace
        :rules="[val => val.length < 2000 || 'Не более 2000 символов']"
    />
    <div class="comment-files-grid">
        <div
            class="cell"
            :style="{
                display: 'block',
            }"
        >
            <div
                v-for="(file, index) in commentFiles"
                :key="index"
                :style="{
                    display: 'inline-block',
                    whiteSpace: 'nowrap',
                }"
            >
                {{ file.file_name }}
                <q-btn
                    :icon="matDelete"
                    round
                    size="0.5rem"
                    class="q-ma-sm"
                    @click="removeFile(index)"
                />
            </div>
        </div>
        <div class="cell">
            <FilesList
                v-model:filesList="commentFiles"
                fileSection="requestCommentFiles"
                :maxFileSize="10485760"
            />
        </div>
        <div class="cell">
            <q-select
                v-model="usersToNotificate"
                :options="possibleUsers"
                emitValue
                mapOptions
                multiple
                outlined
                dense
            />
        </div>
        <div class="cell">
            <q-btn
                round
                :icon="matSend"
                size="sm"
                :disable="commentComment.length < 5"
                @click="addComment"
            >
                <q-tooltip
                    :delay="300"
                    anchor="top right"
                    self="bottom middle"
                    class="bg-amber text-body2 text-black shadow-4"
                >
                    Отправить комментарий
                </q-tooltip>
            </q-btn>
        </div>
    </div>

    <div class="request-comments-grid">
        <!-- header -->
        <div class="cell cell-header cell-center">
            №
        </div>
        <div class="cell cell-header cell-center">
            Дата
        </div>
        <div class="cell cell-header cell-center">
            Пользователь
        </div>
        <div class="cell cell-header cell-center">
            Сообщение
        </div>
        <!-- data -->
        <div
            v-for="(comment, index) in requestComments"
            :key="index"
            class="row-wrapper"
        >
            <div class="cell cell-center">
                {{ index + 1 }}
            </div>
            <div class="cell cell-center">
                {{ $dayjs(comment.date_time_d).format("DD.MM.YYYY HH:mm") }}
            </div>
            <div class="cell">
                {{ getUserPostTitle(comment.user_id) }}
                {{ getUserFIO(comment.user_id) || "Сотрудник другой организации" }}
            </div>
            <div class="cell request-comment--comment">
                <q-input
                    :modelValue="comment.comment"
                    borderless
                    dense
                    autogrow
                    class="q-mb-sm"
                    readonly
                />
                <div
                    v-for="file, indexF in comment.files"
                    :key="`${comment.id}_${indexF}`"
                    class="request-comment--file"
                >
                    {{ file.file_name }}
                    <q-btn
                        v-if="file.path"
                        round
                        :icon="matDownload"
                        size="0.5rem"
                        @click="() => saveDocAsFile(`${siteHost}/${file.path}`, file.file_name)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { saveAs } from "file-saver";
import FilesList from "@/components/FilesList.vue";

export default {
    name: "DialogEditRequestComments",
    components: {
        FilesList,
    },
    data() {
        return {
            commentComment: "",
            commentFiles: [],
            usersToNotificate: [],
        };
    },
    computed: {
        ...mapGetters({
            request: "request",
            requestComments: "requestComments",
            getUserPostTitle: "getUserPostTitle",
            getUserFIO: "getUserFIO",
            getUserFIOshort: "getUserFIOshort",
            requestEngineers: "requestEngineers",
            currentUser: "currentUser",
        }),
        possibleUsers() {
            let retVal = Array
                .from(new Set([
                    ...this.requestEngineers,
                    ...this.requestComments.map((rc) => rc.user_id),
                    this.request.contact_id,
                ]))
                .filter((e) => e !== this.currentUser.id);

            retVal = retVal.map((e) => ({
                value: e,
                label: this.getUserFIOshort(e) || "Сотрудник другой организации",
            }));

            return retVal;
        },
    },
    mounted() {
        this.usersToNotificate = this.possibleUsers.map((e) => e.value);
        this.$store.dispatch("clearNotificationForRequest", this.request.id);
    },
    methods: {
        addComment() {
            this.$store.dispatch(
                "addRequestComment",
                {
                    request_id: this.request.id,
                    comment: this.commentComment,
                    files: this.commentFiles,
                    usersIdsToNofificate: this.usersToNotificate,
                    notificationInfo: `Сообщение по заявке №${this.request.number}`,
                },
            );
            this.commentComment = "";
            this.commentFiles = [];
        },
        saveDocAsFile(url, fileName) {
            saveAs(url, fileName);
        },
        removeFile(index) {
            this.commentFiles.splice(index, 1);
        },
    },
};
</script>
