import axios from "axios";
import Notify from 'quasar/src/plugins/notify/Notify.js';;
import { siteHost } from "./constants";

export default {
    state: {
        servicesRepairs: [],
        filter: {
            region_id: null,
        },
    },
    getters: {
        servicesRepairs: (state) => (state.filter.region_id
            ? state.servicesRepairs.filter((sr) => sr.region_id === state.filter.region_id)
            : state.servicesRepairs),
        servicesRepairsFilter: (state) => state.filter,
    },
    actions: {
        getServicesRepairsFromDB({ commit }) {
            return new Promise((resolve, reject) => {
                axios.get(`${siteHost}/api/services_repairs`)
                    .then((res) => {
                        commit(
                            "setServicesRepairs",
                            res.data.services_repairs,
                        );
                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                    });

                commit("setServicesRepairs", []);
            });
        },
        addServiceRepair({ dispatch }, serviceRepair) {
            axios.post(
                `${siteHost}/api/services_repairs`,
                serviceRepair,
            )
                .then(() => {
                    dispatch("getServicesRepairsFromDB");
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        updateServiceRepair({ dispatch }, serviceRepair) {
            axios.put(
                `${siteHost}/api/services_repairs/${serviceRepair.id}`,
                serviceRepair,
            )
                .then(() => {
                    dispatch("getServicesRepairsFromDB");
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        deleteServiceRepair({ dispatch }, serviceRepairId) {
            axios.delete(`${siteHost}/api/services_repairs/${serviceRepairId}`)
                .then(() => {
                    dispatch("getServicesRepairsFromDB");
                })
                .catch((err) => {
                    Notify.create({
                        type: "notify-failure",
                        message: err.response.data.message,
                    });
                });
        },
        setServicesRepairsFilter({ commit }, filter) {
            commit("setServicesRepairsFilter", filter);
        },
    },
    mutations: {
        setServicesRepairs(state, servicesRepairs) {
            state.servicesRepairs = servicesRepairs;
        },
        setServicesRepairsFilter(state, filter) {
            state.filter = filter;
        },
    },
};
