<template>
    <q-select
        ref="refSelect"
        :modelValue="model"
        :multiple="multiple"
        :useInput="!multiple"
        :hideSelected="!multiple"
        fillInput
        inputDebounce="0"
        :options="optionsF"
        optionValue="id"
        :optionLabel="optionLabel"
        mapOptions
        emitValue
        :clearable="clearable"
        outlined
        dense
        @filter="filterFn"
        @update:modelValue="updateModelValue"
        @popup-hide="popupHide"
    >
        <template #no-option>
            <q-item>
                <q-item-section class="text-grey">
                    No results
                </q-item-section>
            </q-item>
        </template>
        <template #selected>
            {{ selectedItems }}
        </template>
        <template
            v-if="multiple"
            #option="{ itemProps, opt, selected, toggleOption }"
        >
            <q-item
                v-bind="itemProps"
                :style="{
                    maxWidth: maxWidth,
                }"
            >
                <q-item-section>
                    <q-item-label>
                        {{ opt[scopeAttr1] }}
                        {{ opt[scopeAttr2] }}
                    </q-item-label>
                    <q-item-label caption>
                        {{ opt[scopeAttr3] }}
                    </q-item-label>
                </q-item-section>
                <q-item-section side>
                    <q-checkbox
                        :modelValue="selected"
                        @update:model-value="toggleOption(opt)"
                    />
                </q-item-section>
            </q-item>
        </template>
        <template
            v-else-if="extendedScope"
            #option="scope"
        >
            <q-item
                v-bind="scope.itemProps"
                :style="{
                    maxWidth: maxWidth,
                }"
            >
                <q-item-section>
                    <q-item-label>
                        {{ scope.opt[scopeAttr1] }}
                        {{ scope.opt[scopeAttr2] }}
                    </q-item-label>
                    <q-item-label caption>
                        {{ scope.opt[scopeAttr3] }}
                    </q-item-label>
                </q-item-section>
            </q-item>
        </template>
    </q-select>
</template>

<script>
import { ref, toRef } from "vue";

export default {
    name: "SelectFilter",
    props: {
        options: {
            type: Array,
            required: true,
        },
        modelValue: {
            type: [Number, Array],
            default: null,
        },
        clearable: {
            type: Boolean,
            default: false,
        },
        extendedScope: {
            type: Boolean,
            default: false,
        },
        scopeAttr1: {
            type: String,
            default: "title",
        },
        scopeAttr2: {
            type: String,
            default: "title",
        },
        scopeAttr3: {
            type: String,
            default: "title",
        },
        optionLabel: {
            type: String,
            default: "title",
        },
        multiple: {
            type: Boolean,
            default: false,
        },
    },
    emits: ["update:modelValue"],
    setup(props, context) {
        // const optionsF = toRef(props.options);
        const optionsF = ref(props.options);
        const model = toRef(props, "modelValue");

        return {
            model,
            optionsF,

            filterFn(val, update) {
                update(() => {
                    const needle = val.toLocaleLowerCase();
                    // optionsF.value = props.options
                    optionsF.value = props.options
                        .filter((v) => v[props.optionLabel].toLocaleLowerCase().indexOf(needle) > -1);
                });
            },

            updateModelValue(v) {
                context.emit("update:modelValue", v);
            },

            popupHide() {
                optionsF.value = props.options;
            },
        };
    },
    data() {
        return {
            maxWidth: "300px",
        };
    },
    computed: {
        hasError() {
            return this.$refs.refSelect.hasError;
        },
        selectedItems() {
            if (this.model.length === 0) {
                return "";
            }

            const el = this.optionsF.find((e) => e.id === this.model[0]);

            if (this.model.length === 1) {
                return el[this.scopeAttr1];
            }

            return `${el[this.scopeAttr1]} и еще ${this.model.length - 1}`;
        },
    },
    mounted() {
        this.maxWidth = `${this.$refs.refSelect.$el.clientWidth - 20}px`;
    },
    methods: {
        validate() {
            return this.$refs.refSelect.validate();
        },
    },
};
</script>
