<template>
    <div class="content content__flex-with-table">
        <div class="content-title">
            Запросы к администратору
            <q-space />
            <FilterRequestsInner />
        </div>
        <div class="pagination">
            <q-pagination
                v-model="currentPage"
                color="blue-grey-7"
                :max="pagesCount"
                :maxPages="8"
                :boundaryNumbers="true"
            />
        </div>
        <div class="content-body">
            <div class="requests-inner-grid">
                <div class="cell cell-header cell-center">
                    №
                </div>
                <div class="cell cell-header cell-center">
                    Номер
                </div>
                <div class="cell cell-header cell-center">
                    Дата
                </div>
                <div class="cell cell-header cell-center">
                    Регион
                </div>
                <div class="cell cell-header cell-center">
                    Организация
                </div>
                <div class="cell cell-header cell-center">
                    Автор
                </div>
                <div class="cell cell-header cell-center">
                    Вид запроса
                </div>
                <div class="cell cell-header cell-center">
                    Запрос
                </div>
                <div class="cell cell-header cell-center">
                    Состояние
                </div>
                <div class="cell cell-header cell-center">
                    Комментарий
                </div>
                <div class="cell cell-header cell-center">
                    Файлы
                </div>
                <div class="cell cell-header" />
                <RequestsInnerRow
                    v-for="(request, requestIndex) in requestsInner"
                    :key="requestIndex"
                    :requestIndex="(+currentPage - 1) * 20 + requestIndex + 1"
                    :request="request"
                />
            </div>
        </div>
    </div>
    <div
        v-if="showSpinner"
        class="req-dialog-cover"
    >
        <q-spinner-grid
            color="primary"
            size="5.5em"
        />
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import FilterRequestsInner from "@/components/Filters/FilterRequestsInner.vue";
import RequestsInnerRow from "./RequestsInnerRow.vue";

export default {
    name: "RequestsInner",
    components: {
        FilterRequestsInner,
        RequestsInnerRow,
    },
    data() {
        return {
            showSpinner: false,
        };
    },
    computed: {
        ...mapGetters({
            requestsInner: "requestsInner",
            getUserFIO: "getUserFIO",
            getOrgProperty: "getOrgProperty",
            reqsInnerFilter: "reqsInnerFilter",
            iRequestsInnerCount: "iRequestsInnerCount",
        }),
        // requestsOnPage() {
        //     return this.requestsInner.slice((this.currentPage - 1) * 20, this.currentPage * 20);
        // },
        pagesCount() {
            return Math.ceil(this.iRequestsInnerCount / 20);
        },
        currentPage: {
            get() {
                return this.reqsInnerFilter.pageNumber + 1;
            },
            set(value) {
                this.$store.dispatch(
                    "setReqsInnerFilter",
                    {
                        ...this.reqsInnerFilter,
                        pageNumber: value - 1,
                    },
                );
            },
        },
    },
    mounted() {
        this.showSpinner = true;
        this.$store.dispatch("getRequestsInnerFromDB")
            .then(() => {
                this.showSpinner = false;
            });
    },
};
</script>
