<template>
    <q-dialog
        ref="dialog"
        persistent
    >
        <q-card style="width: 700px; max-width: 80vw;">
            <q-card-section>
                <div class="text-h6">
                    Вид комплекта оборудования
                </div>
            </q-card-section>

            <q-separator />

            <q-card-section
                style="max-height: 70vh;"
                class="scroll"
            >
                <q-input
                    ref="refName"
                    v-model="item.name"
                    label="Наименование"
                    outlined
                    dense
                    class="q-mb-sm"
                    :rules="[(val) => val &&
                        0 < val.length &&
                        val.length < 100 || 'Значение от 1 до 100 символов']"
                    hideBottomSpace
                />
                <q-input
                    ref="refCode"
                    v-model="item.code"
                    label="Код"
                    outlined
                    dense
                    class="q-mb-sm"
                    type="number"
                    :rules="[(val) => val && +val < 2147483648 || 'Число менее 2147483648']"
                    hideBottomSpace
                />
                <q-select
                    v-model="item.system_ts_id"
                    :options="SYSTEMS_TS"
                    emitValue
                    mapOptions
                    outlined
                    dense
                    clearable
                    label="Система ТС"
                    class="q-mb-sm"
                />
            </q-card-section>

            <q-separator />

            <q-card-actions align="right">
                <q-btn
                    flat
                    label="Сохранить"
                    color="primary"
                    :icon="matSave"
                    @click="onOkClick"
                />
                <q-btn
                    v-close-popup
                    flat
                    label="Отменить"
                    color="primary"
                    :icon="matCancel"
                    @click="hide"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import {
    SYSTEMS_TS,
} from "@/store/constants";

export default {
    name: "DialogEditToolsSetName",
    props: {
        itemForEdit: {
            type: Object,
            required: true,
        },
    },
    emits: ["ok"],
    setup() {
        return {
            SYSTEMS_TS,
        };
    },
    data() {
        return {
            item: {},
        };
    },
    methods: {
        show() {
            this.item = { ...this.itemForEdit };
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        onOkClick() {
            this.$refs.refName.validate();
            this.$refs.refCode.validate();
            if (
                this.$refs.refName.hasError ||
                this.$refs.refCode.hasError
            ) {
                return;
            }

            this.$emit("ok", this.item);

            this.hide();
        },
    },
};
</script>
