<template>
    <div
        class="row-wrapper"
        @click="$emit('editItem', index)"
    >
        <div class="cell cell-center">
            {{ (+currentPage - 1) * 20 + index + 1 }}
        </div>
        <div
            class="cell"
            v-html="unitName"
        />
        <div class="cell">
            {{ toolsSetName(toolsSet.type_id) }}
        </div>
        <div class="cell cell-column tools-sets-list">
            <div
                v-for="(t, i) in toolsList"
                :key="i"
                class="cell"
            >
                {{ t }}
            </div>
        </div>
        <div class="cell">
            {{ toolsSet.inventar_number }}
        </div>
        <div class="cell cell-center">
            {{ toolsSet.work_in_dt
                ? dateYMDtoDMY(toolsSet.work_in_dt)
                : "Не указана дата ввода в эксплуатацию" }}
        </div>
        <div class="cell cell-center">
            {{ toolsSet.work_in_dt
                ? dateDiffYMD(toolsSet.work_in_dt)
                : "" }}
        </div>
        <div class="cell cell-center">
            <q-btn
                round
                :icon="matContentCopy"
                size="sm"
                @click="createCopy"
            >
                <q-tooltip
                    anchor="top right"
                    self="bottom right"
                    :delay="300"
                    class="bg-amber text-body2 text-black shadow-4"
                >
                    Создать копию
                </q-tooltip>
            </q-btn>
        </div>
        <div class="cell cell-center">
            <q-btn
                round
                :icon="matDelete"
                size="0.7rem"
                @click="deleteToolsSet"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { dateYMDtoDMY, dateDiffYMD } from "@/helpers/helpers";
import DialogConfirm from "@/components/Dialogs/DialogConfirm.vue";

export default {
    name: "ToolsSetRow",
    props: {
        index: {
            type: Number,
            required: true,
        },
        currentPage: {
            type: Number,
            required: true,
        },
        toolsSet: {
            type: Object,
            required: true,
        },
    },
    emits: ["editItem"],
    setup() {
        return {
            dateYMDtoDMY,
            dateDiffYMD,
        };
    },
    computed: {
        ...mapGetters({
            toolsSetName: "toolsSetName",
            unitsFull: "unitsFull",
            getToolKindName: "getToolKindName",
            getManufacturerName: "getManufacturerName",
            getToolModelName: "getToolModelName",
            getRegionName: "getRegionName",
        }),
        regionId() {
            const unit = this.unitsFull.find((u) => u.id === this.toolsSet.unit_id);

            return unit ? this.getRegionName(unit.region_id) : "???";
        },
        unitName() {
            const unit = this.unitsFull.find((u) => u.id === this.toolsSet.unit_id);

            return unit
                ? [
                    this.getRegionName(unit.region_id),
                    unit.fullname,
                ].join(",<br>")
                : "???";
        },
        toolsList() {
            return this.toolsSet.tools.map((t) => {
                const k = this.getToolKindName(t.tool_kind_id);
                const m = this.getManufacturerName(t.manufacturer_id);

                return `${k || "?"} ${m || "?"} ${t.serial_number || "?"}`;
            });
        },
    },
    methods: {
        createCopy(e) {
            e.stopPropagation();
            this.$emit("editItem", this.index, true);
        },
        deleteToolsSet(e) {
            e.stopPropagation();

            this.$q.dialog({
                component: DialogConfirm,
                componentProps: {
                    labelOk: "Удалить",
                    dialogMessage: "Удалить комплект оборудования<br>(учетные единцы не будут удалены)?",
                },
            })
                .onOk(() => {
                    this.$store.dispatch("deleteToolSet", this.toolsSet.id);
                });
        },
    },
};
</script>
