<template>
    <q-dialog
        v-model="showFilter"
        position="top"
    >
        <q-card style="width: 70%; max-width: 70%;">
            <div class="filter-grid--tool">
                <SelectFilter
                    v-model="regionId"
                    :options="regions"
                    label="Регион"
                    clearable
                />

                <SelectFilter
                    v-model="unitsIds"
                    :options="unitsFiltered"
                    multiple
                    extendedScope
                    optionLabel="name"
                    scopeAttr1="name"
                    scopeAttr2="not_needed"
                    scopeAttr3="address"
                    clearable
                    label="Объект"
                    @clear="() => unitsIds = []"
                />

                <SelectFilter
                    v-model="toolSetNameId"
                    :options="toolsSetsNamesCodes"
                    label="Вид комплекта оборудования"
                    optionLabel="name"
                    clearable
                    outlined
                    dense
                />
                <q-select
                    v-model="toolKindId"
                    outlined
                    dense
                    clearable
                    :options="toolsKindsWithNull"
                    optionValue="id"
                    optionLabel="title"
                    mapOptions
                    emitValue
                    label="Вид оборудования"
                />
                <q-select
                    v-model="manufacturerId"
                    outlined
                    dense
                    clearable
                    :options="manufacturers"
                    optionValue="id"
                    optionLabel="title"
                    mapOptions
                    emitValue
                    label="Производитель оборудования"
                />
                <q-input
                    v-model="toolModel"
                    outlined
                    dense
                    clearable
                    label="Наименование"
                    debounce="500"
                />

                <PoeDatePicker
                    v-model="toolWorkInDtFrom"
                    labelTitle="Дата ввода в эксплуатацию от"
                    emptyDateAllowed
                    classOuter=""
                />
                <PoeDatePicker
                    v-model="toolWorkInDtTill"
                    labelTitle="Дата ввода в эксплуатацию по"
                    emptyDateAllowed
                    classOuter=""
                />
                <q-select
                    v-model="toolAge"
                    outlined
                    dense
                    clearable
                    :options="TOOLS_AGES"
                    optionValue="value"
                    optionLabel="title"
                    mapOptions
                    emitValue
                    label="Срок использования"
                />
                <q-select
                    ref="refUnit"
                    v-model="toolState"
                    outlined
                    dense
                    :options="TOOLS_STATES"
                    optionValue="id"
                    optionLabel="title"
                    mapOptions
                    emitValue
                    label="Состояние"
                    clearable
                />

                <q-input
                    v-model="toolSerialNumber"
                    outlined
                    dense
                    clearable
                    label="Серийный номер"
                    debounce="500"
                >
                    <template #append>
                        <q-checkbox v-model="tollSerialExact">
                            <q-tooltip
                                :delay="250"
                                class="bg-amber text-body2 text-black shadow-4"
                            >
                                Точное совпадение
                            </q-tooltip>
                        </q-checkbox>
                    </template>
                </q-input>
                <q-input
                    v-model="toolInventarNumber"
                    outlined
                    dense
                    clearable
                    label="Инвентарный номер"
                    debounce="500"
                >
                    <template #append>
                        <q-checkbox v-model="tollInventarExact">
                            <q-tooltip
                                :delay="250"
                                class="bg-amber text-body2 text-black shadow-4"
                            >
                                Точное совпадение
                            </q-tooltip>
                        </q-checkbox>
                    </template>
                </q-input>
                <q-select
                    v-model="toolOwnerTypeId"
                    outlined
                    dense
                    :options="TOOLS_OWNERS_TYPES"
                    mapOptions
                    emitValue
                    clearable
                    label="Принадлежность оборудования"
                />
                <q-input
                    v-model="toolDescription"
                    outlined
                    dense
                    clearable
                    label="Примечание"
                    debounce="500"
                />
                <q-input
                    v-model="toolCostRemainFrom"
                    outlined
                    dense
                    clearable
                    label="Остаточная стоимость от"
                    type="number"
                    debounce="500"
                />
                <q-input
                    v-model="toolCostRemainUpto"
                    outlined
                    dense
                    clearable
                    label="Остаточная стоимость до"
                    type="number"
                    debounce="500"
                />
                <div>
                    <q-toggle
                        v-model="toolInSet"
                        label="Входит в комплект"
                    />
                </div>
                <div>
                    <q-toggle
                        v-model="toolExchangeStock"
                        label="Обменный фонд"
                    />
                </div>
            </div>

            <div class="filter-controls">
                <q-btn
                    label="Очистить"
                    @click="$store.dispatch('setToolsFilter', { toolsFilter: null })"
                />
                <q-btn
                    label="Закрыть"
                    @click="showFilter = false"
                />
            </div>
        </q-card>
    </q-dialog>

    <q-btn
        v-if="filterIsNotEmpty"
        round
        :icon="matSearchOff"
        @click="$store.dispatch('setToolsFilter', { toolsFilter: null })"
    >
        <q-tooltip
            :delay="300"
            anchor="top right"
            self="bottom right"
            class="bg-amber text-body2 text-black shadow-4"
        >
            Очистить фильтр
        </q-tooltip>
    </q-btn>
    <q-btn
        round
        :icon="matSearch"
        :color="filterIsNotEmpty ? 'primary' : ''"
        :textColor="filterIsNotEmpty ? 'white' : 'black'"
        @click="showFilter = !showFilter"
    />
</template>

<script>
import { mapGetters } from "vuex";
import {
    RIGHTS_CONSTANTS,
    TOOLS_AGES,
    TOOLS_STATES,
    TOOLS_OWNERS_TYPES,
} from "@/store/constants";
import PoeDatePicker from "@/components/Common/PoeDatePicker.vue";
import SelectFilter from "@/components/Common/SelectFilter.vue";

function generateProperty(property) {
    return {
        get() {
            return this.toolsFilter[property];
        },
        set(value) {
            const v = typeof value === "string"
                ? value.trim()
                : value;

            this.$store.dispatch(
                "setToolsFilter",
                {
                    toolsFilter: {
                        ...this.toolsFilter,
                        [property]: v,
                    },
                },
            );
        },
    };
}

export default {
    name: "FilterTools",
    components: {
        PoeDatePicker,
        SelectFilter,
    },
    setup() {
        return {
            TOOLS_AGES,
            TOOLS_STATES,
            TOOLS_OWNERS_TYPES,
        };
    },
    data() {
        return {
            showFilter: false,
        };
    },
    computed: {
        ...mapGetters({
            regions: "regions",
            unitsFull: "unitsFull",
            toolsSetsNamesCodes: "toolsSetsNamesCodes",
            toolsKinds: "toolsKinds",
            toolsFilter: "toolsFilter",
            currentUser: "currentUser",
            manufacturers: "manufacturers",
        }),
        unitsFiltered() {
            const uf = this.regionId
                ? this.unitsFull.filter((u) => u.region_id === this.regionId)
                : this.unitsFull;

            return !!(this.currentUser.rightsObject.RIGHT_TOOLS_READ & RIGHTS_CONSTANTS.ALL)
                ? uf
                : uf.filter((u) => this.currentUser.units.includes(u.id));
        },
        regionId: {
            get() {
                return this.toolsFilter.region_id;
            },
            set(value) {
                this.$store.dispatch(
                    "setToolsFilter",
                    {
                        toolsFilter: {
                            ...this.toolsFilter,
                            region_id: value,
                        },
                    },
                );

                if (value) {
                    this.unitsIds = [];
                }
            },
        },
        unitsIds: generateProperty("units_ids"),
        toolSetNameId: generateProperty("tool_set_name_id"),
        toolSerialNumber: generateProperty("serial_number"),
        tollSerialExact: generateProperty("serial_exact"),
        toolInventarNumber: generateProperty("inventar_number"),
        tollInventarExact: generateProperty("inventar_exact"),
        toolAge: generateProperty("tool_age"),
        manufacturerId: generateProperty("manufacturer_id"),
        toolModel: generateProperty("tool_model"),
        toolModelExact: generateProperty("tool_model_exact"),
        toolInSet: generateProperty("tool_in_set"),
        toolExchangeStock: generateProperty("tool_exchange_stock"),
        toolWorkInDtFrom: generateProperty("tool_work_in_dt_from"),
        toolWorkInDtTill: generateProperty("tool_work_in_dt_till"),
        toolState: generateProperty("tool_state"),
        toolOwnerTypeId: generateProperty("tool_owner_type_id"),
        toolDescription: generateProperty("tool_description"),
        toolCostRemainFrom: generateProperty("tool_cost_remain_from"),
        toolCostRemainUpto: generateProperty("tool_cost_remain_upto"),
        toolKindId: {
            get() {
                return this.toolsFilter.tool_kind_ids
                    ? this.toolsFilter.tool_kind_ids[0]
                    : null;
            },
            set(value) {
                this.$store.dispatch(
                    "setToolsFilter",
                    {
                        toolsFilter: {
                            ...this.toolsFilter,
                            tool_kind_ids: value !== null ? [value] : null,
                        },
                    },
                );
            },
        },
        toolsKindsWithNull() {
            return [
                ...this.toolsKinds,
                {
                    id: 0,
                    title: "--- не указан ---",
                },
            ];
        },
        filterIsNotEmpty() {
            return Object.keys(this.toolsFilter).reduce(
                (a, key) => {
                    if (key === "units_ids") {
                        return a || this.toolsFilter[key].length > 0;
                    }

                    return a || this.toolsFilter[key];
                },
                false,
            );
        },
    },
};
</script>
