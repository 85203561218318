<template>
    <q-input
        ref="refDateInput"
        v-model="dateLocal"
        outlined
        dense
        mask="##.##.####"
        clearable
        :label="labelTitle"
        :class="classOuter"
        hideBottomSpace
        :rules="[() => !errorMessageL || errorMessageL]"
        :errorMessage="errorMessageL"
        lazyRules
    >
        <template #prepend>
            <q-icon
                :name="matEvent"
                class="cursor-pointer"
            >
                <q-popup-proxy
                    ref="qDateProxy"
                    transitionShow="scale"
                    transitionHide="scale"
                >
                    <q-date
                        v-model="dateLocal"
                        mask="DD.MM.YYYY"
                        minimal
                        :locale="DATE_LOCALE"
                    />
                </q-popup-proxy>
            </q-icon>
        </template>
    </q-input>
</template>

<script>
import { DATE_LOCALE } from "@/store/constants";
import { dateYMDtoDMY, dateDMYtoYMD } from "@/helpers/helpers";

export default {
    name: "PoeDatePicker",
    props: {
        error: {
            type: Boolean,
            default: false,
        },
        errorMessage: {
            type: String,
            default: "",
        },
        labelTitle: {
            type: String,
            default: "Дата",
        },
        classOuter: {
            type: String,
            default: "q-mb-sm",
        },
        modelValue: {
            type: String,
            default: "",
        },
        emptyDateAllowed: {
            type: Boolean,
            default: false,
        },
        futureDateAllowed: {
            type: Boolean,
            default: true,
        },
        dateFormat: {
            type: String,
            default: "DMY",
        },
        minDate: {
            type: Object,
            default: () => new Date(0),
        },
    },
    emits: ["update:modelValue"],
    setup() {
        return {
            DATE_LOCALE,
        };
    },
    data() {
        return {
            dateLocalT: "",
        };
    },
    computed: {
        dateLocal: {
            get() {
                return this.dateLocalT;
            },
            set(value) {
                this.dateLocalT = value;

                this.$refs.qDateProxy.hide();

                if (!value) {
                    this.dateLocalT = "";
                    this.$emit("update:modelValue", null);
                    return;
                }

                if (this.dateFormat === "DMY") {
                    this.$emit(
                        "update:modelValue",
                        value,
                    );
                }
                else {
                    this.$emit(
                        "update:modelValue",
                        dateDMYtoYMD(value),
                    );
                }
            },
        },
        formatIsValid() {
            const regExpDate = /[0-3]\d.[01]\d.\d\d\d\d/;
            return (
                this.dateLocalT === null || this.dateLocalT === ""
                    ? true
                    : !!this.dateLocal.match(regExpDate)
            );
        },
        errorMessageL() {
            if (this.emptyDateAllowed && !this.dateLocal) {
                return "";
            }

            if (!this.emptyDateAllowed && !this.dateLocal) {
                return "Введите дату";
            }

            if (!this.formatIsValid) {
                return "Введите дату в формате DD.MM.YYYY";
            }

            if (!this.$dayjs(this.dateLocal, "DD.MM.YYYY", true).isValid()) {
                return "Неверная дата";
            }

            if (this.dateLocal) {
                if (dateDMYtoYMD(this.dateLocal) < this.$dayjs(this.minDate).format("YYYY-MM-DD")) {
                    return `Дата должна быть больше или равна ${this.$dayjs(this.minDate).format("DD.MM.YYYY")}`;
                }
            }

            return (
                this.futureDateAllowed ||
                this.$dayjs(this.dateLocalT, "DD.MM.YYYY").valueOf() < Date.now()
            )
                ? "" : "Укажите дату не позднее сегодняшней";
        },
        hasError() {
            return !!this.errorMessageL;
        },
    },
    watch: {
        modelValue(v) {
            if (v === null) {
                this.dateLocalT = "";
            }
        },
    },
    mounted() {
        if (this.modelValue) {
            this.dateLocalT = this.dateFormat === "DMY"
                ? this.modelValue
                : dateYMDtoDMY(this.modelValue);
        }
    },
    methods: {
        validate() {
            this.$refs.refDateInput.validate();
        },
    },
};
</script>
