<template>
    <q-dialog
        ref="dialog"
        persistent
    >
        <q-card class="dialog-q-card">
            <div
                v-if="showSpinner"
                class="req-dialog-cover"
            >
                <q-spinner-grid
                    color="primary"
                    size="5.5em"
                />
            </div>
            <div class="content-title">
                Выбор оборудования для ввода в эксплуатацию
            </div>
            <q-separator />

            <!-- <div class="search-tools-sets-grid">
                {{ toolsSetsWaiting.length }}
            </div> -->

            <div class="search-tool-grid">
                <!-- header -->
                <div class="cell cell-header cell-center">
                    №
                </div>
                <div class="cell cell-header cell-center">
                    Вид оборудования
                </div>
                <div class="cell cell-header cell-center">
                    Производитель
                </div>
                <div class="cell cell-header cell-center">
                    Наименование
                </div>
                <div class="cell cell-header cell-center">
                    Серийный номер
                </div>
                <div class="cell cell-header cell-center">
                    <q-checkbox
                        :modelValue="allSelectionValue"
                        indeterminateValue="maybe"
                        @update:model-value="toggleToolSelectAll"
                    />
                </div>
                <!-- data -->
                <div
                    v-for="(tool, toolIndex) in toolsFiltered"
                    :key="toolIndex"
                    class="row-wrapper"
                >
                    <div class="cell cell-center">
                        {{ toolIndex + 1 }}
                    </div>
                    <div class="cell">
                        {{ getToolKindName(tool.tool_kind_id) }}
                    </div>
                    <div class="cell">
                        {{ getManufacturerName(tool.manufacturer_id) }}
                    </div>
                    <div class="cell">
                        {{ getToolModelName(tool.model_id) }}
                    </div>
                    <div class="cell">
                        {{ tool.serial_number }}
                    </div>
                    <div class="cell cell-center">
                        <q-checkbox
                            :modelValue="toolsIds.includes(tool.id)"
                            @update:model-value="toggleToolSelection(tool.id)"
                        />
                    </div>
                </div>
            </div>

            <q-space />
            <q-separator />

            <q-card-actions align="right">
                <q-btn
                    flat
                    label="Выбрать"
                    color="primary"
                    :icon="matCheck"
                    :disable="toolsIds.length === 0"
                    @click="onOkClick"
                />
                <q-btn
                    flat
                    label="Отменить"
                    color="primary"
                    :icon="matCancel"
                    @click="hide"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { TOOLS_STATES_VALUES } from "@/store/constants";

export default {
    name: "DialogSearchToolCommissioning",
    props: {
        toolsIdsOrig: {
            type: Array,
            required: true,
        },
    },
    emits: ["ok"],
    data() {
        return {
            showSpinner: false,
            toolsIds: this.toolsIdsOrig,
        };
    },
    computed: {
        ...mapGetters({
            request: "request",
            toolsFilter: "toolsFilter",
            toolsFiltered: "toolsFiltered",
            getToolKindName: "getToolKindName",
            getManufacturerName: "getManufacturerName",
            getToolModelName: "getToolModelName",
            toolsSetsWaiting: "toolsSetsWaiting",
        }),
        allSelectionValue() {
            if (this.toolsIds.length === this.toolsFiltered.length) {
                return true;
            }

            if (this.toolsIds.length === 0) {
                return false;
            }

            return "maybe";
        },
    },
    methods: {
        show() {
            this.$store.dispatch(
                "setToolsFilter",
                {
                    toolsFilter: {
                        units_ids: [this.request.unit_id],
                        tool_state: TOOLS_STATES_VALUES.WAITING,
                        fullList: true,
                    },
                },
            );

            this.$store.dispatch("getToolsSetsWaiting", this.request.unit_id);

            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        toggleToolSelectAll(value) {
            if (value === true) {
                this.toolsIds = this.toolsFiltered.map((t) => t.id);
            }
            if (value === false) {
                this.toolsIds = [];
            }
        },
        toggleToolSelection(toolId) {
            if (this.toolsIds.includes(toolId)) {
                this.toolsIds = this.toolsIds.filter((e) => e !== toolId);
            }
            else {
                this.toolsIds.push(toolId);
            }
        },
        onOkClick() {
            this.$emit("ok", this.toolsIds);

            this.hide();
        },
    },
};
</script>
