<template>
    <div class="content content__flex login-form">
        <h1>Информационная система ФГБУ ИАЦ Судебного департамента (ИС ИАЦ)</h1>

        <q-form @submit="login">
            <q-input
                v-model="userName"
                label="Логин"
                outlined
                class="login-input"
                lazyRules
                :rules="[(val) => val && val.length > 0 || 'Введите логин']"
            />

            <q-input
                v-model="userPassword"
                label="Password"
                outlined
                :type="isPwd ? 'password' : 'text'"
                class="login-input"
                lazyRules
                :rules="[(val) => val && val.length > 0 || 'Введите пароль']"
            >
                <template #append>
                    <q-icon
                        :name="isPwd ? matVisibilityOff : matVisibility"
                        class="cursor-pointer"
                        @click="isPwd = !isPwd"
                    />
                </template>
            </q-input>

            <q-btn
                label="Войти"
                color="grey"
                class="login-input--button"
                type="submit"
            />
        </q-form>
    </div>
</template>

<script>
export default {
    name: "Login",
    data() {
        return {
            userName: "",
            userPassword: "",
            isPwd: true,
        };
    },
    methods: {
        login() {
            this.$store.dispatch(
                "login",
                {
                    userName: this.userName,
                    userPassword: this.userPassword,
                },
            )
                .then(() => {
                    this.$router.push("/");
                })
                .catch((err) => {
                    this.$q.notify({
                        type: "notify-failure",
                        message: err.reason,
                    });
                });
        },
    },
};
</script>
