import {
    RIGHTS_CONSTANTS,
} from "@/store/constants";

export const pathsAndRights = [
    {
        path: "menuItemCreateRequest",
        rightsA: [
            {
                rightName: "RIGHT_REQUEST_CREATE",
                rightValue:
                    RIGHTS_CONSTANTS.OWN |
                    RIGHTS_CONSTANTS.ALL,
            },
        ],
    },
    {
        path: "menuItemСreateMultipleRequests",
        rightsA: [
            {
                rightName: "RIGHT_REQUEST_MULTIPLE",
                rightValue: RIGHTS_CONSTANTS.FULL,
            },
        ],
    },

    {
        path: "/requests",
        rightsA: [
            {
                rightName: "RIGHT_REQUEST_READ",
                rightValue:
                    RIGHTS_CONSTANTS.OWN |
                    RIGHTS_CONSTANTS.ALL,
            },
        ],
    },
    {
        path: "/documents-list",
        rightsA: [
            {
                rightName: "RIGHT_SIGNING_DOCS_IAC",
                rightValue: RIGHTS_CONSTANTS.FULL,
            },
            {
                rightName: "RIGHT_SIGNING_DOCS_UNIT",
                rightValue: RIGHTS_CONSTANTS.FULL,
            },
            {
                rightName: "RIGHT_SIGNING_DOCS_FINAL",
                rightValue: RIGHTS_CONSTANTS.FULL,
            },
        ],
    },
    {
        path: "menuListTools",
        rightsA: [
            {
                rightName: "RIGHT_TOOLS_READ",
                rightValue:
                    RIGHTS_CONSTANTS.OWN |
                    RIGHTS_CONSTANTS.ALL,
            },
        ],
    },
    {
        path: "menuItemCreateTool",
        rightsA: [
            {
                rightName: "RIGHT_TOOLS_WRITE",
                rightValue: RIGHTS_CONSTANTS.FULL,
            },
        ],
    },
    {
        path: "importFromExcel",
        rightsA: [
            {
                rightName: "RIGHT_TOOL_IMPORT",
                rightValue: RIGHTS_CONSTANTS.FULL,
            },
        ],
    },
    {
        path: "/spares",
        rightsA: [
            {
                rightName: "RIGHT_ZIP",
                rightValue:
                    RIGHTS_CONSTANTS.READ |
                    RIGHTS_CONSTANTS.FULL,
            },
        ],
    },
    {
        path: "menuListReferences",
        rightsA: [
            {
                rightName: "RIGHT_REFERENCES",
                rightValue: RIGHTS_CONSTANTS.FULL,
            },
        ],
    },
    {
        path: "/regions",
        rightsA: [
            {
                rightName: "RIGHT_REGIONS",
                rightValue: RIGHTS_CONSTANTS.FULL,
            },
        ],
    },
    {
        path: "/hosts",
        rightsA: [
            {
                rightName: "RIGHT_ORGANIZATIONS",
                rightValue: RIGHTS_CONSTANTS.READ | RIGHTS_CONSTANTS.FULL,
            },
        ],
    },
    {
        path: "/organizations",
        rightsA: [
            {
                rightName: "RIGHT_ORGANIZATIONS",
                rightValue: RIGHTS_CONSTANTS.READ | RIGHTS_CONSTANTS.FULL,
            },
        ],
    },
    {
        path: "/organization-kinds",
        rightsA: [
            {
                rightName: "RIGHT_ORGANIZATIONS_KINDS",
                rightValue: RIGHTS_CONSTANTS.FULL,
            },
        ],
    },

    {
        path: "/tools-models",
        rightsA: [
            {
                rightName: "RIGHT_TOOLS_MODELS",
                rightValue: RIGHTS_CONSTANTS.READ | RIGHTS_CONSTANTS.FULL,
            },
        ],
    },
    {
        path: "/tools-kinds",
        rightsA: [
            {
                rightName: "RIGHT_TOOLS_KINDS",
                rightValue: RIGHTS_CONSTANTS.FULL,
            },
        ],
    },
    {
        path: "/tools-kinds-traits",
        rightsA: [
            {
                rightName: "RIGHT_TOOLS_KINDS_TRAITS",
                rightValue: RIGHTS_CONSTANTS.FULL,
            },
        ],
    },
    {
        path: "/tools-sets-names",
        rightsA: [
            {
                rightName: "RIGHT_TOOLS_SETS_KINDS",
                rightValue: RIGHTS_CONSTANTS.FULL,
            },
        ],
    },
    {
        path: "/manufacturers",
        rightsA: [
            {
                rightName: "RIGHT_TOOLS_MANUFACTURERS",
                rightValue: RIGHTS_CONSTANTS.READ | RIGHTS_CONSTANTS.FULL,
            },
        ],
    },

    {
        path: "/users",
        rightsA: [
            {
                rightName: "RIGHT_USERS_LIST",
                rightValue: RIGHTS_CONSTANTS.FULL,
            },
        ],
    },
    {
        path: "/posts",
        rightsA: [
            {
                rightName: "RIGHT_USERS_POSTS",
                rightValue: RIGHTS_CONSTANTS.FULL,
            },
        ],
    },
    {
        path: "/users-hotline",
        rightsA: [
            {
                rightName: "RIGHT_USERS_HL",
                rightValue: RIGHTS_CONSTANTS.READ | RIGHTS_CONSTANTS.FULL,
            },
        ],
    },
    {
        path: "/services-repairs",
        rightsA: [
            {
                rightName: "RIGHT_SERVICE_REPAIR_EDIT",
                rightValue: RIGHTS_CONSTANTS.FULL,
            },
        ],
    },
    {
        path: "/programs-subsystems",
        rightsA: [
            {
                rightName: "RIGHT_REFERENCE_GAS_SUBSYSTEM",
                rightValue: RIGHTS_CONSTANTS.READ | RIGHTS_CONSTANTS.FULL,
            },
        ],
    },
    {
        path: "/programs",
        rightsA: [
            {
                rightName: "RIGHT_PROGRAMS",
                rightValue: RIGHTS_CONSTANTS.READ | RIGHTS_CONSTANTS.FULL,
            },
        ],
    },
    {
        path: "/common-disrepairs",
        rightsA: [
            {
                rightName: "RIGHT_ACT_DISREPAIR_EDIT",
                rightValue: RIGHTS_CONSTANTS.FULL,
            },
        ],
    },
    {
        path: "/problems-categories",
        rightsA: [
            {
                rightName: "RIGHT_PROBLEMS_CATEGORIES",
                rightValue: RIGHTS_CONSTANTS.READ | RIGHTS_CONSTANTS.FULL,
            },
        ],
    },
    {
        path: "menuListManagement",
        rightsA: [
            {
                rightName: "RIGHT_SETTINGS",
                rightValue: RIGHTS_CONSTANTS.FULL,
            },
        ],
    },
    {
        path: "createFormular",
        rightsA: [
            {
                rightName: "RIGHT_REPORT_FORMULAR",
                rightValue: RIGHTS_CONSTANTS.FULL,
            },
        ],
    },
    {
        path: "createEnclosure1",
        rightsA: [
            {
                rightName: "RIGHT_REPORT_ENCLOSURE_1",
                rightValue: RIGHTS_CONSTANTS.FULL,
            },
        ],
    },
    {
        path: "createEnclosure2",
        rightsA: [
            {
                rightName: "RIGHT_REPORT_ENCLOSURE_2",
                rightValue: RIGHTS_CONSTANTS.FULL,
            },
        ],
    },
    {
        path: "createRequestsArchive",
        rightsA: [
            {
                rightName: "RIGHT_REPORT_REQUESTS_ARCHIEVE",
                rightValue: RIGHTS_CONSTANTS.FULL,
            },
        ],
    },
    {
        path: "createRepairReport1",
        rightsA: [
            {
                rightName: "RIGHT_REPORT_REPAIRS_1",
                rightValue: RIGHTS_CONSTANTS.FULL,
            },
        ],
    },
    {
        path: "createRepairReport2",
        rightsA: [
            {
                rightName: "RIGHT_REPORT_REPAIRS_2",
                rightValue: RIGHTS_CONSTANTS.FULL,
            },
        ],
    },
    {
        path: "createRepairReport3",
        rightsA: [
            {
                rightName: "RIGHT_REPORT_REPAIRS_3",
                rightValue: RIGHTS_CONSTANTS.FULL,
            },
        ],
    },
    {
        path: "createRepairShortTable",
        rightsA: [
            {
                rightName: "RIGHT_REPORT_REPAIRS_SHORT",
                rightValue: RIGHTS_CONSTANTS.FULL,
            },
        ],
    },
    {
        path: "createReportWaitingRepair",
        rightsA: [
            {
                rightName: "RIGHT_REPORT_REPAIRS_WAITING",
                rightValue: RIGHTS_CONSTANTS.FULL,
            },
        ],
    },
    {
        path: "createFailureReportForm",
        rightsA: [
            {
                rightName: "RIGHT_REPORT_FAILURES",
                rightValue: RIGHTS_CONSTANTS.FULL,
            },
        ],
    },
    {
        path: "createSubsystemReport",
        rightsA: [
            {
                rightName: "RIGHT_REPORT_SUBSYSTEM",
                rightValue: RIGHTS_CONSTANTS.FULL,
            },
        ],
    },
    {
        path: "createHardwareReport1",
        rightsA: [
            {
                rightName: "RIGHT_REPORT_TOOL_N1",
                rightValue: RIGHTS_CONSTANTS.FULL,
            },
        ],
    },
];
