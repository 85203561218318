<template>
    <div class="row-wrapper">
        <div class="cell cell-center">
            {{ requestIndex }}
        </div>
        <div class="cell cell-center">
            {{ request.id }}
        </div>
        <div
            class="cell cell-center"
            v-html="$dayjs(request.date_create).format('DD.MM.YYYY<br>HH:mm')"
        />
        <div class="cell">
            {{ region }}
        </div>
        <div class="cell">
            {{ author ? getOrgProperty(author.organization_id, "name") : "Пользователь не найден" }}
        </div>
        <div class="cell">
            {{ authorFio }}
        </div>
        <div class="cell">
            {{ requestInnerKindTitle(request.request_inner_kind_id) }}
        </div>
        <div
            class="cell"
            style="overflow-wrap: anywhere;"
            v-html="requestMessageFormat(request.message)"
        />
        <div class="cell cell-center">
            {{ requestStateToString(request.state) }}
        </div>
        <div
            class="cell"
            style="overflow-wrap: anywhere;"
        >
            {{ request.response_message }}
        </div>
        <div
            class="cell"
            style="flex-direction: column;"
        >
            <template
                v-for="(f, index) in request.files"
                :key="index"
            >
                <q-img
                    v-if="f.mimetype.startsWith('image')"
                    :src="`${siteHost}/${f.path}`"
                    spinnerColor="white"
                    style="width: 90px"
                    @click="saveFile(f)"
                />
                <div
                    v-else
                    style="color: blue; text-decoration: underline; cursor: pointer;"
                    @click="saveFile(f)"
                >
                    {{ f.file_name }}
                </div>
            </template>
        </div>
        <div class="cell cell-center">
            <q-btn
                round
                :icon="matEdit"
                size="sm"
                class="poeso-table--control-button"
            >
                <q-menu>
                    <q-list style="min-width: 250px">
                        <template
                            v-for="(status, index) in requestStatuses"
                            :key="index"
                        >
                            <q-separator v-if="status.state == -1" />
                            <q-item
                                v-else
                                v-close-popup
                                clickable
                                @click="updateRequest('status', status.state)"
                            >
                                <q-item-section side>
                                    <q-icon :name="status.icon" />
                                </q-item-section>
                                <q-item-section>
                                    {{ status.label }}
                                </q-item-section>
                            </q-item>
                        </template>

                        <q-separator />

                        <q-item clickable>
                            <q-item-section side>
                                <q-icon name="keyboard_arrow_left" />
                            </q-item-section>
                            <q-item-section>
                                Сменить вид запроса
                            </q-item-section>

                            <q-menu
                                anchor="top end"
                                self="top start"
                            >
                                <q-list>
                                    <q-item
                                        v-for="(innderKind, index) in REQUEST_INNER_KINDS"
                                        :key="index"
                                        v-close-popup
                                        dense
                                        clickable
                                        @click="updateRequest('kind', innderKind)"
                                    >
                                        <q-item-section>
                                            {{ innderKind.label }}
                                        </q-item-section>
                                    </q-item>
                                </q-list>
                            </q-menu>
                        </q-item>
                    </q-list>
                </q-menu>
            </q-btn>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { REQUEST_INNER_KINDS } from "@/store/constants";
import { saveAs } from "file-saver";
import DialogConfirm from "@/components/Dialogs/DialogConfirm.vue";
import DialogSystemRequestResponse from "@/components/Dialogs/DialogSystemRequestResponse.vue";

export default {
    name: "RequestsInnerRow",
    props: {
        requestIndex: {
            type: Number,
            default: 0,
        },
        request: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            requestStatuses: [
                { state: 1, label: "Активно", icon: this.matAutorenew },
                { state: 2, label: "Выполнено", icon: this.matCheck },
                { state: 3, label: "Отложено", icon: this.matRotateLeft },
                { state: -1 },
                { state: 4, label: "Удалить", icon: this.matDelete },
            ],
            REQUEST_INNER_KINDS,
        };
    },
    computed: {
        ...mapGetters({
            users: "users",
            getOrgProperty: "getOrgProperty",
            getRegionName: "getRegionName",
        }),
        author() {
            const user = this.users.find((u) => u.id === this.request.author_id);

            return user || null;
        },
        authorFio() {
            return this.author
                ? `${this.author.surname} ${this.author.name} ${this.author.patronymic || ""}`
                : "Пользователь не найден";
        },
        region() {
            if (!this.author) {
                return "Пользователь не найден";
            }

            const regionId = this.getOrgProperty(this.author.organization_id, "region_id");
            return this.getRegionName(regionId);
        },
    },
    methods: {
        requestStateToString(requestState) {
            const retVal = this.requestStatuses.find((s) => s.state === requestState);
            return retVal ? retVal.label : "???";
        },
        requestMessageFormat(message) {
            return message.replace(/\n/g, "<br />");
        },
        requestInnerKindTitle(reqId) {
            const reqKind = REQUEST_INNER_KINDS.find((rk) => rk.value === reqId);
            return reqKind
                ? reqKind.label
                : "";
        },
        updateRequest(type, requestState) {
            if (type === "status" && requestState === 2) {
                this.$q.dialog({
                    component: DialogSystemRequestResponse,
                })
                    .onOk((response) => {
                        this.$store.dispatch(
                            "updateRequestInner",
                            {
                                ...response,
                                requestId: this.request.id,
                                state: requestState,
                            },
                        );
                    });
            }
            if (type === "status" && requestState === 4) {
                this.$q.dialog({
                    component: DialogConfirm,
                    componentProps: {
                        dialogMessage: `Удалить запрос от 
                            ${this.$dayjs(this.request.date_create).format("DD.MM.YYYY HH:mm")}?`,
                    },
                })
                    .onOk(() => {
                        this.$store.dispatch(
                            "deleteRequestInner",
                            {
                                requestId: this.request.id,
                            },
                        );
                    });
            }
            if (type === "status" && (requestState === 1 || requestState === 3)) {
                this.$store.dispatch(
                    "updateRequestInner",
                    {
                        requestId: this.request.id,
                        state: requestState,
                    },
                );
            }

            if (type === "kind") {
                this.$store.dispatch(
                    "updateRequestInner",
                    {
                        requestId: this.request.id,
                        request_inner_kind_id: requestState.value,
                    },
                );
            }
        },
        saveFile(picture) {
            saveAs(`${this.siteHost}/${picture.path}`, picture.file_name);
        },
    },
};
</script>
