import axios from "axios";
import Notify from 'quasar/src/plugins/notify/Notify.js';;
import { siteHost } from "./constants";

export default {
    state: {
        requestsInner: [],
        innerRequestDocs: [],
        reqsInnerFilter: { pageNumber: 0 },
        iRequestsInnerCount: 0,
    },
    getters: {
        requestsInner: (state) => state.requestsInner,
        innerRequestDocs: (state) => state.innerRequestDocs,
        reqsInnerFilter: (state) => state.reqsInnerFilter,
        iRequestsInnerCount: (state) => state.iRequestsInnerCount,
    },
    actions: {
        //----------------------------------------------------------------------
        getRequestsInnerFromDB({ commit, getters }) {
            return new Promise((resolve, reject) => {
                const f = JSON.stringify(getters.reqsInnerFilter);
                axios.get(`${siteHost}/api/request_inner/${f}`)
                    .then((res) => {
                        commit("setRequestsInner", res.data);
                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        createRequestToAdmin({ state, dispatch }, request) {
            // console.log(request);
            return new Promise((resolve, reject) => {
                const formData = new FormData();
                formData.append("innerRequest", JSON.stringify(request));
                formData.append("section", "innerRequest");
                // formData.append("innerRequestDocs", JSON.stringify(state.innerRequestDocs));

                state.innerRequestDocs.forEach((file) => {
                    if (file.blob) {
                        formData.append("innerRequest", file.blob);
                    }
                });

                axios.post(
                    `${siteHost}/api/request_inner`,
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    },
                )
                    .then(() => {
                        dispatch("getRequestsInnerFromDB");
                        resolve("OK");
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        updateRequestInner({ dispatch }, payload) {
            axios.put(`${siteHost}/api/request_inner/${payload.requestId}`, payload)
                .then(() => {
                    dispatch("getRequestsInnerFromDB");
                    Notify.create({
                        type: "notify-success",
                        message: "Запрос обновлен",
                    });
                })
                .catch((err) => console.log(err));
        },
        deleteRequestInner({ dispatch }, { requestId }) {
            axios.delete(`${siteHost}/api/request_inner/${requestId}`)
                .then(() => {
                    dispatch("getRequestsInnerFromDB");
                    Notify.create({
                        type: "notify-success",
                        message: "Запрос удален",
                    });
                })
                .catch((err) => console.log(err));
        },
        //----------------------------------------------------------------------
        setInnerRequestDocs({ commit }, docs) {
            commit("setInnerRequestDocs", docs);
        },
        addInnerRequestDoc({ commit }, doc) {
            commit("addInnerRequestDoc", doc);
        },
        delInnerRequestDoc({ commit }, index) {
            commit("delInnerRequestDoc", index);
        },
        //----------------------------------------------------------------------
        setReqsInnerFilter({ commit, dispatch }, filter) {
            commit("setReqsInnerFilter", filter);
            dispatch("getRequestsInnerFromDB");
        },
    },
    mutations: {
        setRequestsInner(state, { request_inner, iRequestsInnerCount }) {
            state.requestsInner = request_inner;
            state.iRequestsInnerCount = iRequestsInnerCount;
        },
        //----------------------------------------------------------------------
        setInnerRequestDocs(state, docs) {
            state.innerRequestDocs = docs;
        },
        addInnerRequestDoc(state, doc) {
            state.innerRequestDocs.push(doc);
        },
        delInnerRequestDoc(state, index) {
            state.innerRequestDocs.splice(index, 1);
        },
        //----------------------------------------------------------------------
        setReqsInnerFilter(state, filter) {
            state.reqsInnerFilter = filter;
        },
    },
};
