<template>
    <div
        style="display: flex; gap: 5px; align-items: center;"
        @click="toolStartSearch"
    >
        <q-field
            ref="reqTool"
            v-model="request.tool_id"
            outlined
            label="Оборудование"
            dense
            style="cursor: pointer; width: 100%; max-width: 100%;"
            hideBottomSpace
            errorMessage="Укажите оборудование"
            :stackLabel="!!reqToolChosen.length"
            :error="requestValidations.validations.tool_id && !reqToolChosen.length"
            :disable="disable"
        >
            <div
                class="text-black"
                style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis;"
            >
                {{ reqToolChosen }}
            </div>
            <q-tooltip
                :delay="300"
                anchor="top right"
                self="bottom right"
                class="bg-amber text-body2 text-black shadow-4"
            >
                {{ reqToolChosen }}
            </q-tooltip>
        </q-field>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
    REQUEST_KINDS_VALUES,
    GUARANTY_TYPES_VALUES,
} from "@/store/constants";
import DialogSearchTool from "@/components/Dialogs/DialogSearchTool.vue";

export default {
    name: "DialogEditRequestTool",
    props: {
        disable: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            reqToolChosen: "",
        };
    },
    computed: {
        ...mapGetters({
            request: "request",
            requestValidations: "requestValidations",
            getManufacturerName: "getManufacturerName",
            getToolKindName: "getToolKindName",
            getToolModelName: "getToolModelName",
            toolsSetsNames: "toolsSetsNames",
        }),
    },
    mounted() {
        this.searchToolName(this.request.tool_id);
    },
    methods: {
        toolStartSearch() {
            if (this.disable) {
                return;
            }

            if (window.getSelection().toString().length > 0) {
                return;
            }

            this.$store.dispatch(
                "setToolsFilter",
                {
                    toolsFilter: {
                        units_ids: [this.request.unit_id],
                    },
                    doUpdate: false,
                },
            );
            this.$q.dialog({
                component: DialogSearchTool,
                componentProps: {
                    toolsWithSoft: [
                        REQUEST_KINDS_VALUES.SOFT_SPECIAL,
                        REQUEST_KINDS_VALUES.SOFT_COMMON,
                    ]
                        .includes(this.request.request_kind_id),
                },
            })
                .onOk((tool) => {
                    this.$store.dispatch(
                        "setRequestField",
                        {
                            field: "tool_id",
                            value: tool.id,
                        },
                    );
                    if (this.request.request_kind_id === REQUEST_KINDS_VALUES.REPAIR_HARD) {
                        if (!tool.guaranty_dt) {
                            this.$store.dispatch(
                                "setRequestField",
                                {
                                    field: "repair_kind_id",
                                    value: GUARANTY_TYPES_VALUES.NOT_GUARANTY,
                                },
                            );
                        }
                        else {
                            const m = this.$dayjs(tool.guaranty_dt);
                            const repairKindId = m.valueOf() >= Date.now()
                                ? GUARANTY_TYPES_VALUES.GUARANTY
                                : GUARANTY_TYPES_VALUES.NOT_GUARANTY;

                            this.$store.dispatch(
                                "setRequestField",
                                {
                                    field: "repair_kind_id",
                                    value: repairKindId,
                                },
                            );
                        }
                    }
                    if (this.request.request_kind_id === REQUEST_KINDS_VALUES.RESTORE) {
                        if (tool.set_id) {
                            const toolSet = this.toolsSetsNames.find((e) => e.id === tool.set_id);

                            if (toolSet) {
                                this.$store.dispatch(
                                    "setRequestField",
                                    {
                                        field: "system_ts_id",
                                        value: toolSet.system_ts_id,
                                    },
                                );
                            }
                        }
                    }

                    this.searchToolName(tool.id);
                });
        },
        searchToolName(toolId) {
            if (toolId) {
                this.$store.dispatch("getSingleToolFromDB", toolId)
                    .then((tool) => {
                        this.reqToolChosen = [
                            this.getToolKindName(tool.tool_kind_id),
                            this.getManufacturerName(tool.manufacturer_id),
                            this.getToolModelName(tool.model_id),
                            "ИН:",
                            tool.inventar_number,
                            "СН:",
                            tool.serial_number,
                        ].join(" ");
                    });
            }
            else {
                this.reqToolChosen = "";
            }
        },
    },
};
</script>
