import axios from "axios";
import { siteHost, NOTIFICATION_STATES } from "./constants";

export default {
    state: {
        notifications: [],
        // notsCount: 0,
    },
    getters: {
        notifications: (state) => state.notifications,
        notsCount: (state) => state.notifications.length,
    },
    actions: {
        // setNotsCount({ commit }, notsCount) {
        //     commit("setNotsCount", notsCount);
        // },
        getNotificationsFromDB({ commit, getters }) {
            return new Promise((resolve, reject) => {
                axios.get(`${siteHost}/api/notifications/${getters.currentUser.id}`)
                    .then((res) => {
                        commit("setNotifications", res.data.notifications);
                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        markNotificationReaded({ dispatch }, notificationId) {
            axios.put(
                `${siteHost}/api/notifications/${notificationId}`,
                {
                    state: NOTIFICATION_STATES.READED,
                },
            )
                .then(() => {
                    dispatch("getNotificationsFromDB");
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        clearNotificationForRequest({ dispatch, getters }, requestId) {
            const notsIds = getters.notifications
                .filter((n) => n.object_id === requestId)
                .map((n) => n.id);

            for (let i = 0; i < notsIds.length; i++) {
                dispatch("markNotificationReaded", notsIds[i]);
            }
        },
        // checkNewNotifications({ dispatch, getters }) {
        //     axios.get(`${siteHost}/api/notifications/${getters.currentUser.id}`)
        //         .then((res) => {
        //             if (res.data.iCount > 0) {
        //                 dispatch("getNotificationsFromDB");
        //             }
        //         });
        // },
    },
    mutations: {
        // setNotsCount(state, notsCount) {
        //     state.notsCount = notsCount;
        // },
        setNotifications(state, notifications) {
            state.notifications = notifications;
        },
    },
};
