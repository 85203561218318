<template>
    <div class="user-menu">
        <template v-if="currentUser.isAuthenticated">
            <span class="user-fio">
                {{ currentUser.surname }}
                {{ currentUser.name }}
                {{ currentUser.patronymic }},
            </span>
            {{ postTitle(currentUser.post_id) }},
            {{ getOrgProperty(currentUser.organization_id, "name") }}
        </template>

        <q-btn
            v-if="currentUser.isAuthenticated"
            round
            :icon="matNotifications"
            color="secondary"
        >
            <q-badge
                v-if="notsCount > 0"
                color="red-9"
                :label="notsCount"
                floating
            />
            <q-popup-proxy>
                <Notifications />
            </q-popup-proxy>
        </q-btn>

        <q-btn
            round
            :icon="currentUser.isAuthenticated ? matEngineering : matPermIdentity"
            color="secondary"
        >
            <q-menu autoClose>
                <q-list style="min-width: 200px">
                    <q-item
                        v-if="currentUser.isAuthenticated"
                        clickable
                        @click="logOut"
                    >
                        <q-item-section>Выйти из программы</q-item-section>
                    </q-item>
                    <q-item
                        v-if="!currentUser.isAuthenticated"
                        clickable
                        @click="logIn"
                    >
                        <q-item-section>Войти в программу</q-item-section>
                    </q-item>
                </q-list>
            </q-menu>
        </q-btn>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import Notifications from "../../pages/Notifications/Notifications.vue";

export default {
    name: "UserMenu",
    components: {
        Notifications,
    },
    computed: {
        ...mapGetters({
            currentUser: "currentUser",
            postTitle: "postTitle",
            getOrgProperty: "getOrgProperty",
            notsCount: "notsCount",
            notifications: "notifications",
        }),
    },
    methods: {
        logIn() {
            if (this.$router.currentRoute.fullPath !== "/login") {
                this.$router.push("/login");
            }
        },
        logOut() {
            this.$store.dispatch("logout");
            this.$router.push("/");
            // this.logIn();
        },
        openNotifications() {
            if (this.$route.path !== "/notifications") {
                this.$router.push("/notifications");
            }
        },
    },
};
</script>
