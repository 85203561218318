<template>
    <div class="cell cell-center">
        {{ index + 1 }}
    </div>
    <div class="cell">
        {{ spareSetItemOrig.title }}
        <!-- {{ spareSetItemOrig }} -->
    </div>
    <div
        v-if="allowViewCost"
        class="cell"
    >
        {{ spareSetItemOrig.cost }}
    </div>
    <div class="cell">
        <q-input
            v-model="amount"
            borderless
            dense
            class="col"
            type="number"
            :disable="!canBeEdited"
            :error="amountError"
            errorMessage="Превышено количество в ЗИПе"
            hideBottomSpace
        />
    </div>
    <div class="cell cell-center">
        <q-btn
            round
            :icon="matDelete"
            size="sm"
            class="poeso-table--control-button"
            :disable="!canBeEdited"
            @click="removeSpare"
        />
    </div>
</template>

<script>
export default {
    name: "RequestSpareRow",
    props: {
        spareSetItemOrig: {
            type: Object,
            required: true,
        },
        requestSpare: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
        canBeEdited: {
            type: Boolean,
            required: true,
        },
        allowViewCost: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        amount: {
            get() {
                return this.requestSpare.amount;
            },
            set(value) {
                if (+value < 1) {
                    this.$q.notify({
                        type: "notify-failure",
                        message: "Количество не может быть меньше 1",
                    });
                    return;
                }

                if (+value > this.spareSetItemOrig.amount) {
                    this.$q.notify({
                        type: "notify-failure",
                        message: `Количество не может быть больше ${this.spareSetItemOrig.amount} (остаток в ЗИПе)`,
                    });
                    return;
                }

                this.$store.dispatch(
                    "setRequestSpare",
                    {
                        index: this.index,
                        requestSpare: {
                            ...this.requestSpare,
                            amount: value,
                        },
                    },
                );
            },
        },
        amountError() {
            const spareRest = this.spareSetItemOrig.amount -
                this.spareSetItemOrig.requests_spares.reduce((a, e) => a + e.amount, 0);
            return this.amount > spareRest;
        },
    },
    methods: {
        removeSpare() {
            this.$store.dispatch("delRequestSpare", this.index);
        },
    },
};
</script>
