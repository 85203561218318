<template>
    <q-dialog
        ref="dialog"
        persistent
    >
        <q-card style="width: 700px; max-width: 80vw;">
            <q-card-section>
                <div class="text-h6">
                    {{ dialogTitle }}
                </div>
            </q-card-section>

            <q-separator />

            <q-card-section
                class="scroll q-pa-none"
                style="max-height: 70vh; height: 60vh;"
            >
                <div class="simple-three-grid">
                    <div class="cell cell-header cell-center">
                        №
                    </div>
                    <div class="cell cell-header cell-center">
                        ФИО
                    </div>
                    <div class="cell cell-header cell-center" />
                    <div
                        v-for="(engineer, engineerIndex) in workers"
                        :key="engineerIndex"
                        class="row-wrapper"
                    >
                        <div class="cell cell-center">
                            {{ engineerIndex + 1 }}
                        </div>
                        <div class="cell">
                            {{ engineer.surname }}
                            {{ engineer.name }}
                            {{ engineer.patronymic || "" }}
                        </div>
                        <div class="cell cell-center">
                            <q-checkbox
                                :modelValue="chosenEngineersIds.includes(engineer.id)"
                                @update:model-value="toggleEngineer(engineer.id)"
                            />
                        </div>
                    </div>
                </div>
            </q-card-section>

            <q-separator />

            <q-card-actions align="right">
                <q-btn
                    v-close-popup
                    flat
                    label="Назначить"
                    color="primary"
                    :icon="matCheck"
                    @click="chooseEngineers"
                />
                <q-btn
                    v-close-popup
                    flat
                    label="Отменить"
                    color="primary"
                    :icon="matCancel"
                    @click="hide"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "DialogSearchEngineer",
    props: {
        dialogTitle: {
            type: String,
            required: true,
        },
        workerType: {
            type: String,
            default: "engineer",
        },
    },
    emits: ["ok"],
    data() {
        return {
            chosenEngineersIds: [],
        };
    },
    computed: {
        ...mapGetters({
            engineers: "engineers",
            usersHotline: "usersHotline",
            requestEngineers: "requestEngineers",
        }),
        workers() {
            return this.workerType === "engineer"
                ? this.engineers
                : this.usersHotline;
        },
    },
    methods: {
        show() {
            this.chosenEngineersIds = [...this.requestEngineers]
                .filter((id) => this.workers.map((w) => w.id).includes(id));
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        chooseEngineers() {
            this.$emit("ok", this.chosenEngineersIds);

            this.hide();
        },
        toggleEngineer(id) {
            if (this.chosenEngineersIds.includes(id)) {
                this.chosenEngineersIds = this.chosenEngineersIds.filter((el) => el !== id);
            }
            else {
                this.chosenEngineersIds.push(id);
            }
        },
    },
};
</script>
