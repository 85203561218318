<template>
    <div class="header">
        <div class="toolbar">
            <div class="header-title">
                <div v-if="currentUser.isAuthenticated && serverAppVersion && serverAppVersion !== version">
                    <!-- {{ serverAppVersion }} /
                    {{ version }} -->
                    <q-btn
                        label="Доступна новая версия. Нажмите для обновления"
                        color="red-5"
                        @click="windowLocationReload"
                    />
                </div>
                <!--
                ГАС Правосудие
                -->
            </div>

            <UserMenu />
        </div>
        <MainMenu v-if="currentUser.isAuthenticated" />
    </div>
</template>
<script>
import { mapGetters } from "vuex";

import MainMenu from "@/components/Common/MainMenu.vue";
import UserMenu from "@/components/Common/UserMenu.vue";
import { version } from "../../../package.json";

export default {
    name: "Header",
    components: {
        MainMenu,
        UserMenu,
    },
    setup() {
        return {
            version,
        };
    },
    computed: {
        ...mapGetters({
            serverAppVersion: "serverAppVersion",
            currentUser: "currentUser",
        }),
    },
    methods: {
        windowLocationReload() {
            window.location.reload(true);
        },
    },
};
</script>
