<template>
    <div class="toolbar">
        <q-btn
            round
            :icon="outlinedHome"
            unelevated
            @click="() => goToPath('/')"
        />

        <q-btn
            v-for="(list, index) in menuListsFiltered"
            :key="index"
            :label="list.title"
            :textColor="getItemTextColor(list)"
            unelevated
            @click="() => list.path && list.path.startsWith('/') ? goToPath(list.path) : null"
        >
            <q-menu fit>
                <q-list style="min-width: 100px; max-width: 400px;">
                    <template
                        v-for="(item, itemInd) in list.items"
                        :key="`${item.title}_${itemInd}`"
                    >
                        <q-separator
                            v-if="item.title === 'separator'"
                            :key="`s_${itemInd}`"
                        />
                        <q-item
                            v-else-if="item.method"
                            :key="`m_${itemInd}`"
                            v-close-popup
                            clickable
                            :disable="item.disable"
                            @click="() => this[`${item.method}`]()"
                        >
                            <q-item-section>{{ item.title }}</q-item-section>
                        </q-item>

                        <q-item
                            v-else-if="item.items"
                            :key="`l_${itemInd}`"
                            :disable="item.disable"
                            clickable
                        >
                            <q-item-section>{{ item.title }}</q-item-section>
                            <q-item-section side>
                                <q-icon name="keyboard_arrow_right" />
                            </q-item-section>
                            <q-menu
                                autoClose
                                fit
                                anchor="center right"
                                self="top left"
                            >
                                <q-list style="min-width: 100px; max-width: 400px;">
                                    <template v-for="(item2, itemInd2) in item.items">
                                        <q-separator
                                            v-if="item2.title === 'separator'"
                                            :key="`s_${itemInd2}`"
                                        />
                                        <q-item
                                            v-else-if="item2.method"
                                            :key="`m_${itemInd2}`"
                                            :disable="item2.disable"
                                            clickable
                                            @click="() => this[`${item2.method}`]()"
                                        >
                                            <q-item-section>{{ item2.title }}</q-item-section>
                                        </q-item>
                                        <q-item
                                            v-else
                                            :key="`i_${itemInd2}`"
                                            :clickable="item2.path !== ''"
                                            :disable="item2.path === '' || item2.disable"
                                            :active="item2.path === $route.path"
                                            @click="() => goToPath(item2.path)"
                                        >
                                            <q-item-section>{{ item2.title }}</q-item-section>
                                        </q-item>
                                    </template>
                                </q-list>
                            </q-menu>
                        </q-item>

                        <q-item
                            v-else
                            :key="`i_${itemInd}`"
                            v-close-popup
                            :clickable="item.path !== ''"
                            :disable="item.path === '' || item.disable"
                            :active="item.path === $route.path"
                            @click="() => goToPath(item.path)"
                        >
                            <q-item-section>{{ item.title }}</q-item-section>
                        </q-item>
                    </template>
                </q-list>
            </q-menu>
        </q-btn>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

import DialogEditRequest from "@/components/Dialogs/DialogEditRequest/DialogEditRequest.vue";
import DialogCreateMultipleRequests from "@/components/Dialogs/DialogCreateMultipleRequests.vue";
import DialogSystemRequest from "@/components/Dialogs/DialogSystemRequest.vue";
import DialogCreateEnclosure1 from "@/components/Dialogs/DialogCreateEnclosure1.vue";
import DialogCreateEnclosure2 from "@/components/Dialogs/DialogCreateEnclosure2.vue";
import DialogCreateRequestsArchive from "@/components/Dialogs/DialogCreateRequestsArchive.vue";
import DialogCreateRepairReport1 from "@/components/Dialogs/DialogCreateRepairReport1.vue";
import DialogCreateRepairReport2 from "@/components/Dialogs/DialogCreateRepairReport2.vue";
import DialogCreateRepairReport3 from "@/components/Dialogs/DialogCreateRepairReport3.vue";
import DialogCreateReportWaitingRepair from "@/components/Dialogs/DialogCreateReportWaitingRepair.vue";
import DialogCreateRepairShortTable from "@/components/Dialogs/DialogCreateRepairShortTable.vue";
import DialogCreateFailureReportForm from "@/components/Dialogs/DialogCreateFailureReportForm.vue";
import DialogCreateSubsystemReport from "@/components/Dialogs/DialogCreateSubsystemReport.vue";
import DialogCreateHardwareReport1 from "@/components/Dialogs/DialogCreateHardwareReport1.vue";
import DialogCheckToolsModels from "@/components/Dialogs/DialogCheckToolsModels.vue";
import DialogEditTool from "@/components/Dialogs/DialogEditTool/DialogEditTool.vue";
import DialogEditToolsSet from "@/components/Dialogs/DialogEditToolsSet/DialogEditToolsSet.vue";
import DialogCheckTools from "@/components/Dialogs/DialogCheckTools.vue";
// import DialogParseXlsx from "@/components/Dialogs/DialogParseXlsx.vue";
import DialogEditVersionsHistory from "@/components/Dialogs/DialogEditVersionsHistory.vue";
import DialogCreateFormularFull from "@/components/Dialogs/DialogCreateFormularFull.vue";
import DialogCreateFormular from "@/components/Dialogs/DialogCreateFormular.vue";
import DialogPassportUnitTools from "@/components/Dialogs/DialogPassportUnitTools.vue";
import DialogPassportSoftwareCommon from "@/components/Dialogs/DialogPassportSoftwareCommon.vue";
import DialogPassportSoftwareSpecial from "@/components/Dialogs/DialogPassportSoftwareSpecial.vue";
import DialogImportFromExcel from "@/components/Dialogs/DialogImportFromExcel.vue";

import { pathsAndRights } from "@/store/pathsAndRights";

export default {
    name: "MainMenu",
    data() {
        return {
            menuLists: [
                {
                    title: "Техническая поддержка",
                    items: [
                        {
                            title: "Новая заявка",
                            method: "createRequest",
                            path: "menuItemCreateRequest",
                        },
                        {
                            title: "Создание группы заявок",
                            method: "createMultipleRequests",
                            path: "menuItemСreateMultipleRequests",
                        },
                        { title: "separator" },
                        {
                            title: "Список заявок",
                            path: "/requests",
                        },
                        {
                            title: "Заявки головного исполнителя",
                            path: "/requests-hot-line",
                        },
                        { title: "separator" },
                        {
                            title: "Новости Технической поддержки",
                            path: "/gas-soft-renewal",
                        },
                    ],
                },
                {
                    title: "Документы на подпись",
                    path: "/documents-list",
                },
                {
                    title: "Оборудование",
                    path: "menuListTools",
                    items: [
                        {
                            title: "Добавить единицу оборудования",
                            method: "createTool",
                            path: "menuItemCreateTool",
                        },
                        {
                            title: "Добавить комплект оборудования",
                            method: "createToolsSet",
                            path: "menuItemCreateTool",
                        },
                        { title: "separator" },
                        {
                            title: "Список оборудования по типам",
                            path: "/tools-list",
                        },
                        {
                            title: "Общий список оборудования",
                            path: "/tools",
                        },
                        {
                            title: "Общий список оборудования по комплектам",
                            path: "/tools-sets",
                        },
                        { title: "separator" },
                        {
                            title: "Комплекты ЗИП",
                            path: "/spares",
                        },
                        { title: "separator" },
                        {
                            title: "Импорт оборудования из Excel",
                            method: "importFromExcel",
                        },
                    ],
                },
                {
                    title: "Отчеты",
                    path: "menuListReports",
                    items: [
                        {
                            title: "Приложение №1",
                            method: "createEnclosure1",
                        },
                        {
                            title: "Приложение №2",
                            method: "createEnclosure2",
                        },
                        {
                            title: "Журнал учета заявок в архив",
                            method: "createRequestsArchive",
                        },
                        {
                            title: "Отчет по ремонтам №1",
                            method: "createRepairReport1",
                        },
                        {
                            title: "Отчет по ремонтам №2",
                            method: "createRepairReport2",
                        },
                        {
                            title: "Отчет по ремонтам №3",
                            method: "createRepairReport3",
                        },
                        {
                            title: "Отчет о количестве ПТС на ремонт",
                            method: "createReportWaitingRepair",
                        },
                        {
                            title: "Краткий отчет по ремонту ПТС",
                            method: "createRepairShortTable",
                        },
                        {
                            title: "Отчет по отказам ПТС",
                            method: "createFailureReportForm",
                        },
                        {
                            title: "Отчет по подсистемам ГАС «Правосудие»",
                            method: "createSubsystemReport",
                        },
                        {
                            title: "Отчет по оборудованию №1",
                            method: "createHardwareReport1",
                        },
                    ],
                },
                {
                    title: "Справочники",
                    path: "menuListReferences",
                    items: [
                        {
                            title: "Регионы",
                            path: "/regions",
                        },
                        {
                            title: "Балансодержатели",
                            path: "/hosts",
                        },
                        {
                            title: "Организации",
                            path: "/organizations",
                        },
                        {
                            title: "Типы организаций",
                            path: "/organization-kinds",
                        },
                        { title: "separator" },
                        {
                            title: "Модели учетных единиц",
                            path: "/tools-models",
                        },
                        {
                            title: "Виды учетных единиц",
                            path: "/tools-kinds",
                        },
                        {
                            title: "Характеристики видов учетных единиц",
                            path: "/tools-kinds-traits",
                        },
                        {
                            title: "Виды комплектов учетных единиц",
                            path: "/tools-sets-names",
                        },
                        {
                            title: "Производители оборудования",
                            path: "/manufacturers",
                        },
                        { title: "separator" },
                        {
                            title: "Неисправности ПТС",
                            path: "/common-disrepairs",
                        },
                        {
                            title: "Категории проблем",
                            path: "/problems-categories",
                        },
                        { title: "separator" },
                        {
                            title: "Учетные записи",
                            path: "/users",
                        },
                        {
                            title: "Должности",
                            path: "/posts",
                        },
                        {
                            title: "Исполнители Техподдержки ",
                            path: "/users-hotline",
                        },
                        {
                            title: "Сервисные центры",
                            path: "/services-repairs",
                        },
                    ],
                },
                {
                    title: "ГАС «Правосудие»",
                    items: [
                        {
                            title: "Документация",
                            items: [
                                {
                                    title: "Приказ СД №52 от 17.03.2014_положение об эксплуатации ГАС_Правосудие",
                                    method: "openDoc52",
                                },
                                {
                                    title: [
                                        "Приказ  СД №273от 04.12.2014 Об утверждении порядка взаимодействия ФГБУ ИАЦ",
                                        "и его филиалов с федеральными судами общей юрисдикции",
                                    ].join(" "),
                                    method: "openDoc273",
                                },
                                {
                                    title: [
                                        "Поручение филиалам на 2024 год",
                                    ].join(" "),
                                    method: "openDoc_3",
                                },
                            ],
                        },
                        {
                            title: "Техническая Документация",
                            items: [
                                {
                                    title: "Формуляр",
                                    method: "createFormularFull",
                                },
                                {
                                    title: "Табличная часть формуляра",
                                    method: "createFormular",
                                },
                                {
                                    title: "Паспорт ТС",
                                    method: "createUnitToolsPassport",
                                },
                                {
                                    title: "Паспорт ОПО",
                                    method: "createSoftwareCommonPassport",
                                },
                                {
                                    title: "Паспорт СПО",
                                    method: "createSoftwareSpecialPassport",
                                },
                            ],
                        },
                        {
                            title: "Подсистемы ГАС 'Правосудие'",
                            path: "/programs-subsystems",
                        },
                        {
                            title: "Программное обеспечение",
                            path: "/programs",
                        },
                    ],
                },
                {
                    title: "Помощь",
                    items: [
                        // { title: "Краткая инструкция по использованию", path: "" },
                        // { title: "Справка по ПИ 'Сервисное обслуживание'", path: "" },
                        {
                            title: "Создать запрос администратору Системы",
                            method: "createSystemRequest",
                        },
                        { title: "separator" },
                        {
                            title: "Список версий",
                            method: "editVersionsHistory",
                        },
                        { title: "separator" },
                        {
                            title: "О программе",
                            path: "/about",
                        },
                        {
                            title: "Обучающие материалы",
                            path: "/manuals",
                        },
                    ],
                },
                {
                    title: "Администрирование",
                    path: "menuListManagement",
                    items: [
                        {
                            title: "Роли и права",
                            path: "/roles",
                        },
                        {
                            title: "Запросы администратору Системы",
                            path: "/request-inner",
                        },
                        { title: "separator" },
                        {
                            title: "Проверка моделей учетных единиц",
                            method: "checkToolsModels",
                        },
                        {
                            title: "Проверка учетных единиц",
                            method: "checkTools",
                        },
                        // { title: "separator" },
                        // {
                        //     title: "Подсчет оборудования",
                        //     method: "calcToolsFromXlsxData",
                        // },
                        // { title: "Импорт заявок", path: "/requests-import" },
                    ],
                },
            ],
        };
    },
    computed: {
        ...mapGetters({
            currentUser: "currentUser",
        }),
        menuListsFiltered() {
            return this.menuLists
                .filter((m) => this.checkPathAndRights(m))
                .map((m) => (!m.items
                    ? m
                    : {
                        ...m,
                        items: m.items.filter((item) => this.checkPathAndRights(item)),
                    }
                ));
        },
    },
    methods: {
        checkPathAndRights(menuItem) {
            if (!menuItem.path && !menuItem.method) {
                return true;
            }

            const pathRights = pathsAndRights.find((par) => (
                par.path === menuItem.path ||
                par.path === menuItem.method
            ));

            if (!pathRights) {
                return true;
            }

            const allow = pathRights.rightsA
                ? pathRights.rightsA.reduce(
                    (a, r) => (
                        a || !!(this.currentUser.rightsObject[r.rightName] & r.rightValue)
                    ),
                    false,
                )
                : true;

            return allow;
        },
        goToPath(path) {
            if (this.$route.path !== path) {
                this.$router.push(path);
            }
        },
        getItemTextColor(list) {
            if (list.items) {
                return list.items.map((e) => e.path).includes(this.$route.path) ? "primary" : null;
            }

            if (list.path) {
                return this.$route.path === list.path ? "primary" : null;
            }

            return null;
        },
        createRequest() {
            this.$q.dialog({
                component: DialogEditRequest,
                componentProps: {
                    requestForEdit: {},
                },
            });
        },
        createMultipleRequests() {
            this.$q.dialog({
                component: DialogCreateMultipleRequests,
            });
        },
        createSystemRequest() {
            this.$q.dialog({
                component: DialogSystemRequest,
            });
        },
        createEnclosure1() {
            this.$q.dialog({
                component: DialogCreateEnclosure1,
            });
        },
        createEnclosure2() {
            this.$q.dialog({
                component: DialogCreateEnclosure2,
            });
        },
        createRequestsArchive() {
            this.$q.dialog({
                component: DialogCreateRequestsArchive,
            });
        },
        createRepairReport1() {
            this.$q.dialog({
                component: DialogCreateRepairReport1,
            });
        },
        createRepairReport2() {
            this.$q.dialog({
                component: DialogCreateRepairReport2,
            });
        },
        createRepairReport3() {
            this.$q.dialog({
                component: DialogCreateRepairReport3,
            });
        },
        createReportWaitingRepair() {
            this.$q.dialog({
                component: DialogCreateReportWaitingRepair,
            });
        },
        createRepairShortTable() {
            this.$q.dialog({
                component: DialogCreateRepairShortTable,
            });
        },
        createFailureReportForm() {
            this.$q.dialog({
                component: DialogCreateFailureReportForm,
            });
        },
        createSubsystemReport() {
            this.$q.dialog({
                component: DialogCreateSubsystemReport,
            });
        },
        createHardwareReport1() {
            this.$q.dialog({
                component: DialogCreateHardwareReport1,
            });
        },
        editVersionsHistory() {
            this.$q.dialog({
                component: DialogEditVersionsHistory,
            });
        },
        importFromExcel() {
            this.$q.dialog({
                component: DialogImportFromExcel,
            });
        },
        createTool() {
            this.$q.dialog({
                component: DialogEditTool,
                componentProps: {
                    itemForEdit: {},
                },
            })
                .onOk((tool) => {
                    this.$store.dispatch(
                        "saveTool",
                        tool,
                    );
                });
        },
        createToolsSet() {
            this.$q.dialog({
                component: DialogEditToolsSet,
                componentProps: {
                    itemForEdit: {},
                    bCreateNew: false,
                },
            });
        },
        checkToolsModels() {
            this.$q.dialog({
                component: DialogCheckToolsModels,
            });
        },
        checkTools() {
            this.$q.dialog({
                component: DialogCheckTools,
            });
        },
        // calcToolsFromXlsxData() {
        //     this.$q.dialog({
        //         component: DialogParseXlsx,
        //     });
        // },
        openDoc52() {
            window.open(`${this.siteHost}/public/docs/Приказ СД №52 от 17.03.2014.pdf`);
        },
        openDoc273() {
            window.open(`${this.siteHost}/public/docs/Приказ СД №273 от 04.12.2014.pdf`);
        },
        openDoc_3() {
            window.open(`${this.siteHost}/public/docs/Поручение филиалам на 2024.pdf`);
        },
        createFormularFull() {
            this.$q.dialog({
                component: DialogCreateFormularFull,
            });
        },
        createFormular() {
            this.$q.dialog({
                component: DialogCreateFormular,
            });
        },
        createUnitToolsPassport() {
            this.$q.dialog({
                component: DialogPassportUnitTools,
            });
        },
        createSoftwareCommonPassport() {
            this.$q.dialog({
                component: DialogPassportSoftwareCommon,
            });
        },
        createSoftwareSpecialPassport() {
            this.$q.dialog({
                component: DialogPassportSoftwareSpecial,
            });
        },
        showWarning() {
            this.$q.notify({
                type: "notify-success",
                message: "Данный раздел находится в разработке",
            });
        },
    },
};
</script>
