<template>
    <div
        class="row-wrapper"
        @click="$emit('editItem', spareIndex)"
    >
        <div class="cell cell-center">
            {{ spareIndex + 1 }}
        </div>
        <div class="cell cell-center">
            {{ getRegionName(sparesSetRegionId(sparesSetItem.spares_set_id)) }}
        </div>
        <div class="cell cell-center">
            {{ sparesSetName(sparesSetItem.spares_set_id) }}
        </div>
        <div class="cell">
            {{ sparesSetItem.title }}
        </div>
        <div class="cell cell-right">
            {{ (+sparesSetItem.cost).toFixed(2) }}
        </div>
        <div class="cell cell-right">
            {{ sparesSetItem.amount }}
        </div>
        <div class="cell cell-right">
            {{ sparesSetItem.amount - spentInRequests }}
        </div>
        <div class="cell cell-center">
            <q-btn
                v-if="allowZipWrite"
                round
                :icon="matDelete"
                size="0.7rem"
                @click="deleteItem"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { RIGHTS_CONSTANTS } from "@/store/constants";
import DialogConfirm from "@/components/Dialogs/DialogConfirm.vue";

export default {
    name: "SparesRow",
    props: {
        spareIndex: {
            type: Number,
            required: true,
        },
        sparesSetItem: {
            type: Object,
            required: true,
        },
    },
    emits: ["editItem", "deleteItem"],
    computed: {
        ...mapGetters({
            getRegionName: "getRegionName",
            sparesSetName: "sparesSetName",
            sparesSetRegionId: "sparesSetRegionId",
            currentUser: "currentUser",
        }),
        allowZipWrite() {
            return !!(this.currentUser.rightsObject.RIGHT_ZIP & RIGHTS_CONSTANTS.FULL);
        },
        spentInRequests() {
            return this.sparesSetItem.requests_spares.reduce((a, e) => a + +e.amount, 0);
        },
    },
    methods: {
        deleteItem(event) {
            event.stopPropagation();

            this.$q.dialog({
                component: DialogConfirm,
                componentProps: {
                    dialogMessage: "Удалить ЗИП?",
                },
            })
                .onOk(() => {
                    this.$store.dispatch(
                        "deleteSparesSetItem",
                        this.sparesSetItem.id,
                    )
                        .then((message) => {
                            this.$q.notify({
                                type: "notify-success",
                                message,
                            });
                        })
                        .catch((err) => {
                            this.$q.notify({
                                type: "notify-failure",
                                message: err,
                            });
                        });
                });
        },
    },
};
</script>
