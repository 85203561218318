<template>
    <q-dialog
        ref="dialog"
        persistent
    >
        <q-card style="width: 700px; max-width: 80vw;">
            <q-card-section>
                <div class="text-h6">
                    Тип организации
                </div>
            </q-card-section>

            <q-separator />

            <q-card-section
                style="max-height: 70vh;"
                class="scroll"
            >
                <q-input
                    ref="refItem"
                    v-model="item.ksa_title"
                    label="Обозначение КСА"
                    outlined
                    dense
                    class="q-mb-sm"
                    :rules="[(val) => !val || (val &&
                        0 < val.length &&
                        val.length < 100) || 'Значение от 1 до 100 символов']"
                    hideBottomSpace
                />
                <q-input
                    ref="refItem"
                    v-model="item.title"
                    label="Наименование"
                    outlined
                    dense
                    class="q-mb-sm"
                    :rules="[(val) => val &&
                        0 < val.length &&
                        val.length < 200 || 'Значение от 1 до 200 символов']"
                    hideBottomSpace
                    autogrow
                />
                <q-field
                    label="Количество подписантов"
                    stackLabel
                    outlined
                    class="q-mb-sm"
                >
                    <q-option-group
                        v-model="item.signers_count"
                        :options="signersCount"
                        type="radio"
                    />
                </q-field>
                <q-checkbox
                    v-model="item.is_filial"
                    label="Организация является филиалом"
                />
            </q-card-section>

            <q-separator />

            <q-card-actions align="right">
                <q-btn
                    flat
                    label="Сохранить"
                    color="primary"
                    :icon="matSave"
                    @click="onOkClick"
                />
                <q-btn
                    v-close-popup
                    flat
                    label="Отменить"
                    color="primary"
                    :icon="matCancel"
                    @click="hide"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
export default {
    name: "DialogEditOrgKind",
    props: {
        itemForEdit: {
            type: Object,
            required: true,
        },
    },
    emits: ["ok"],
    data() {
        return {
            item: {
                ksa_title: "",
                title: "",
                is_filial: false,
                signers_count: 2,
            },
            signersCount: [
                { label: "2", value: 2 },
                { label: "3", value: 3 },
            ],
        };
    },
    methods: {
        show() {
            this.item = { ...this.itemForEdit };
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        onOkClick() {
            this.$refs.refItem.validate();
            if (this.$refs.refItem.hasError) {
                return;
            }

            this.$emit("ok", this.item);

            this.hide();
        },
    },
};
</script>
