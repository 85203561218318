import axios from "axios";
import { siteHost } from "./constants";

export default {
    state: {
        requestToolsCommissioning: [],
    },
    getters: {
        requestToolsCommissioning: (state) => state.requestToolsCommissioning,
    },
    actions: {
        getRequestToolsCommissioningFromDB({ commit }, reqId) {
            return new Promise((resolve, reject) => {
                axios.get(`${siteHost}/api/request_tools_commissioning/${reqId}`)
                    .then((res) => {
                        commit("setRequestToolsCommissioning", res.data.tools);
                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        setRequestToolsCommissioning({ commit }, toolsList) {
            commit("setRequestToolsCommissioning", toolsList);
        },
    },
    mutations: {
        setRequestToolsCommissioning(state, tools) {
            state.requestToolsCommissioning = tools;
        },
    },
};
