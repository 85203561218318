<template>
    <q-dialog
        ref="dialog"
        :maximized="maximizedToggle"
        persistent
    >
        <q-card class="req-dialog-card">
            <div
                v-if="requestSaving || requestIsLoading"
                class="req-dialog-cover"
            >
                <q-spinner-grid
                    color="primary"
                    size="5.5em"
                />
            </div>
            <div
                v-if="requestLoadingProgress"
                class="req-dialog-cover"
            >
                <q-circular-progress
                    :value="requestLoadingProgress"
                    class="text-light-blue q-ma-md"
                    color="light-blue"
                    centerColor="grey-1"
                    showValue
                    size="5.5em"
                >
                    {{ requestLoadingProgress.toFixed(2).padEnd(5, "0") }}
                </q-circular-progress>
            </div>
            <!-- status and title --------------------------------------------->
            <div class="req-dialog-title">
                <q-space />
                <q-btn
                    dense
                    flat
                    :icon="maximizedToggle ? mdiFullscreenExit : mdiFullscreen"
                    @click="maximizedToggle = !maximizedToggle"
                />
                <q-btn
                    dense
                    flat
                    :icon="matClose"
                    @click="closeRequest"
                />
            </div>
            <div class="req-dialog-status">
                <div class="cell">
                    № заявки
                </div>
                <div class="cell cell--data">
                    {{ request.number }}
                </div>
                <div class="cell">
                    Дата
                </div>
                <div class="cell cell--data">
                    {{ $dayjs(request.request_date_add).format("DD.MM.YYYY") }}
                </div>
                <div class="cell">
                    Тип заявки
                </div>
                <div class="cell cell--data">
                    {{ reqTypeString }}
                </div>
                <div class="cell">
                    Статус
                </div>
                <div class="cell cell--data">
                    {{ reqStatusString }}
                </div>
                <div class="cell">
                    Исполнитель / исполнители
                </div>
                <div
                    class="cell cell--data"
                    style="white-space: nowrap; overflow: hidden;"
                >
                    {{ engineersList }}
                    <q-tooltip
                        :delay="300"
                        anchor="bottom end"
                        self="center end"
                        class="bg-amber text-body2 text-black shadow-4"
                    >
                        {{ engineersList }}
                    </q-tooltip>
                </div>
            </div>

            <!-- tabs --------------------------------------------------------->
            <q-tabs
                v-model="tabCurrent"
                align="left"
                class="bg-primary text-white shadow-2"
                activeBgColor="accent"
                :breakpoint="0"
            >
                <q-tab
                    name="info"
                    :label="request.id ? 'Заявка' : 'Новая заявка'"
                />
                <q-tab
                    v-if="request.id && request.request_performer_kind == PERFORM_KIND.FILIAL"
                    name="tab2"
                    label="Движение"
                />
                <q-tab
                    v-if="request.id && request.request_performer_kind == PERFORM_KIND.FILIAL"
                    name="tab3"
                    label="Документы"
                />
                <q-tab
                    v-if="request.id && request.request_performer_kind == PERFORM_KIND.SENIOR"
                    name="hotline"
                    label="Техподдержка ГЛ"
                />
                <q-tab
                    v-if="request.id"
                    name="comments"
                    label="Чат по заявке"
                />
                <q-tab
                    v-if="request.id"
                    name="tab4"
                    label="История"
                />
            </q-tabs>

            <div :class="maximizedToggle ? 'req-panel-max' : 'req-panel-min'">
                <!-- tab panels ----------------------------------------------->
                <q-tab-panels
                    v-model="tabCurrent"
                    animated
                    keepAlive
                >
                    <q-tab-panel name="info">
                        <!-- common info -------------------------------------->
                        <DialogEditRequestCommon
                            :canBeEdited="!!canBeEdited"
                            @setRequestSaving="(v) => requestSaving = v"
                        />
                        <!-- files -------------------------------------------->
                        <Documents
                            fileSection="request"
                            :filesList="filesList"
                            :addDocToList="addDoc"
                            :deleteDoc="delDoc"
                            :readonly="!canBeEdited ||
                                (
                                    request.request_status_id !== REQUEST_STATUS_VALUES.WAITING_ASSIGNMENT &&
                                    request.request_status_id !== REQUEST_STATUS_VALUES.WAITING_ENGINEER
                                )"
                        />
                    </q-tab-panel>
                    <!-- moving ----------------------------------------------->
                    <q-tab-panel name="tab2">
                        <DialogEditRequestMoving />
                    </q-tab-panel>
                    <!-- documents -------------------------------------------->
                    <q-tab-panel name="tab3">
                        <DialogEditRequestDocuments :requestCompleted="requestCompleted" />
                    </q-tab-panel>
                    <!-- hotline ---------------------------------------------->
                    <q-tab-panel name="hotline">
                        <DialogEditRequestHotline />
                    </q-tab-panel>
                    <!-- comments --------------------------------------------->
                    <q-tab-panel name="comments">
                        <DialogEditRequestComments />
                    </q-tab-panel>
                    <!-- history ---------------------------------------------->
                    <q-tab-panel name="tab4">
                        <DialogEditRequestHistory :history="requestHistory" />
                    </q-tab-panel>
                </q-tab-panels>
            </div>

            <q-separator />

            <q-card-actions>
                <q-btn
                    v-if="request.id &&
                        request.request_kind_id !== REQUEST_KINDS_VALUES.REVOKE &&
                        canBeEdited &&
                        !requestCompleted"
                    flat
                    label="Отозвать заявку"
                    color="primary"
                    :icon="matRotateLeft"
                    @click="recalRequest"
                />
                <q-space />
                <template v-if="!request.id">
                    <q-btn
                        v-if="canBeEdited"
                        flat
                        label="Создать"
                        color="primary"
                        :icon="matSave"
                        @click="saveRequest(true)"
                    />
                </template>
                <template v-else>
                    <q-btn
                        v-if="canBeEdited"
                        flat
                        label="Сохранить"
                        color="primary"
                        :icon="matSave"
                        @click="saveRequest(false)"
                    />
                    <q-btn
                        flat
                        label="Закрыть"
                        color="primary"
                        :icon="matClose"
                        @click="closeRequest"
                    />
                </template>
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import {
    REQUEST_STATUS_VALUES,
    REQUEST_KINDS_VALUES,
    PERFORM_RESULTS_IDS,
    OPTIONS_VIEW_BEFORE_VALUES,
    RIGHTS_CONSTANTS,
    PERFORM_KIND,
    REPAIRS_RESULTS_IDS,
    TOOLS_STATES_VALUES,
} from "@/store/constants";

import {
    hotLineEmailClose,
} from "@/helpers/getDataForReports";

import Documents from "@/components/Documents/Documents.vue";
import DialogConfirm from "@/components/Dialogs/DialogConfirm.vue";

import DialogEditRequestCommon from "./DialogEditRequestCommon.vue";
import DialogEditRequestMoving from "./DialogEditRequestMoving.vue";
import DialogEditRequestHistory from "./DialogEditRequestHistory.vue";
import DialogEditRequestDocuments from "./DialogEditRequestDocuments.vue";
import DialogEditRequestComments from "./DialogEditRequestComments.vue";
import DialogEditRequestHotline from "./DialogEditRequestHotline.vue";

export default {
    name: "DialogEditRequest",
    components: {
        Documents,
        DialogEditRequestMoving,
        DialogEditRequestCommon,
        DialogEditRequestHistory,
        DialogEditRequestDocuments,
        DialogEditRequestComments,
        DialogEditRequestHotline,
    },
    props: {
        requestForEdit: {
            type: Object,
            required: true,
        },
        tabStart: {
            type: String,
            default: "info",
        },
    },
    setup() {
        return {
            RIGHTS_CONSTANTS,
            REQUEST_KINDS_VALUES,
            REQUEST_STATUS_VALUES,
            PERFORM_KIND,
        };
    },
    data() {
        return {
            tabCurrent: "info",
            requestSaving: false,
            maximizedToggle: false,
            requestHash: "",
        };
    },
    computed: {
        ...mapGetters({
            request: "request",
            requestValidations: "requestValidations",
            getUserFIOshort: "getUserFIOshort",
            getReqKindTitle: "getReqKindTitle",
            getReqStatusTitle: "getReqStatusTitle",
            requestDocs: "requestDocs",
            currentUser: "currentUser",
            filesList: "filesList",
            requestEngineers: "requestEngineers",
            requestHistory: "requestHistory",
            requestIsLoading: "requestIsLoading",
            requestLoadingProgress: "requestLoadingProgress",
            getOrgProperty: "getOrgProperty",
            getOrgIdOfUnit: "getOrgIdOfUnit",
        }),
        reqTypeString() {
            return this.request.id
                ? this.getReqKindTitle(this.request.request_kind_id)
                : "Ожидание регистрации";
        },
        reqStatusString() {
            if (!this.request.id) {
                return "Ожидание регистрации";
            }

            return this.getReqStatusTitle(this.request.request_status_id);
        },
        canBeEdited() {
            // for tab "info"
            return (
                !this.request.number ||
                (
                    this.request.request_performer_kind === PERFORM_KIND.FILIAL &&
                    !!(this.currentUser.rightsObject.RIGHT_REQUEST_EDIT & RIGHTS_CONSTANTS.FULL)
                ) ||
                (
                    this.request.request_performer_kind === PERFORM_KIND.SENIOR &&
                    !!(this.currentUser.rightsObject.RIGHT_REQUEST_HOTLINE_EDIT & RIGHTS_CONSTANTS.FULL)
                )
            );
        },
        isHotlineRequest() {
            return (
                this.request.request_kind_id === REQUEST_KINDS_VALUES.SOFT_SPECIAL &&
                [
                    REQUEST_STATUS_VALUES.HOT_LINE_WAITING,
                    REQUEST_STATUS_VALUES.HOT_LINE_PERFORMING,
                    REQUEST_STATUS_VALUES.HOT_LINE_PERFORMED,
                ].includes(this.request.request_status_id)
            );
        },
        requestCompleted() {
            return [
                REQUEST_STATUS_VALUES.HOT_LINE_PERFORMED,
                REQUEST_STATUS_VALUES.PERFORMED,
                REQUEST_STATUS_VALUES.CANCELED,
                REQUEST_STATUS_VALUES.CLOSED,
            ].includes(this.request.request_status_id);
        },
        engineersList() {
            return this.requestEngineers.map((e) => this.getUserFIOshort(e)).join(", ");
        },
    },
    methods: {
        async show() {
            // this.tabCurrent = "info";
            this.tabCurrent = this.tabStart;
            this.$refs.dialog.show();

            await this.$store.dispatch("setRequest", this.requestForEdit);

            this.requestHash = JSON.stringify({
                request: this.request,
                filesList: this.filesList,
                requestDocs: this.requestDocs,
            });
        },
        hide() {
            this.$refs.dialog.hide();
        },
        async saveRequest(doHide) {
            if (this.requestSaving) {
                return;
            }

            if (Object.keys(this.requestValidations.validations).length > 0) {
                this.tabCurrent = this.requestValidations.tabName;
                return;
            }

            //------------------------------------------------------------------
            this.requestSaving = true;

            // check request status

            if (
                this.request.request_status_id === REQUEST_STATUS_VALUES.HOT_LINE_PERFORMING &&
                (
                    this.request.hotline_result_id === PERFORM_RESULTS_IDS.FULFILED ||
                    this.request.result === PERFORM_RESULTS_IDS.FULFILED_HOTLINE
                )
            ) {
                await this.$store.dispatch(
                    "setRequestField",
                    {
                        field: "request_status_id",
                        value: REQUEST_STATUS_VALUES.HOT_LINE_PERFORMED,
                    },
                );
                await this.$store.dispatch(
                    "setRequestField",
                    {
                        field: "hotline_result_date",
                        value: new Date(),
                    },
                );
            }
            else if (
                [
                    REQUEST_STATUS_VALUES.PERFORMING,
                    // REQUEST_STATUS_VALUES.HOT_LINE_WAITING,
                ].includes(this.request.request_status_id) &&
                this.request.request_kind_id === REQUEST_KINDS_VALUES.SOFT_SPECIAL &&
                this.request.result === PERFORM_RESULTS_IDS.MOVED_HOTLINE
            ) {
                await this.$store.dispatch(
                    "setRequestField",
                    {
                        field: "request_status_id",
                        value: REQUEST_STATUS_VALUES.HOT_LINE_WAITING,
                    },
                );
                await this.$store.dispatch(
                    "setRequestField",
                    {
                        field: "request_performer_kind",
                        value: PERFORM_KIND.SENIOR,
                    },
                );
                await this.$store.dispatch(
                    "setRequestField",
                    {
                        field: "result_d",
                        value: null,
                    },
                );
                await this.$store.dispatch(
                    "setRequestField",
                    {
                        field: "note",
                        value: null,
                    },
                );
                // await this.$store.dispatch(
                //     "setRequestDocs",
                //     [],
                // );
                await this.$store.dispatch(
                    "setRequestEngineers",
                    [],
                );
            }
            else if (this.request.request_status_id === REQUEST_STATUS_VALUES.PERFORMING) {
                // check if request was performed
                let docKey = "__DOCUMENT_DOES_NOT_EXIST__";
                let docDateField = "__DOCUMENT_DOES_NOT_EXIST__";

                if (
                    this.request.request_kind_id === REQUEST_KINDS_VALUES.SOFT_SPECIAL ||
                    this.request.request_kind_id === REQUEST_KINDS_VALUES.SOFT_COMMON ||
                    this.request.request_kind_id === REQUEST_KINDS_VALUES.RESTORE
                ) {
                    docKey = "_actCO3_";
                    docDateField = "co_3_d";
                }
                else if (
                    this.request.request_kind_id === REQUEST_KINDS_VALUES.REPAIR_HARD &&
                    this.request.view_res_before === OPTIONS_VIEW_BEFORE_VALUES.TO_DISMISS
                ) {
                    docKey = "_actCO7_";
                    docDateField = "co_7_d";
                }
                else if (this.request.request_kind_id === REQUEST_KINDS_VALUES.REPAIR_HARD) {
                    docKey = "_actCO8_";
                    docDateField = "co_8_d";
                }
                else if (this.request.request_kind_id === REQUEST_KINDS_VALUES.COMMISSIONING) {
                    docKey = "_actCO1.3_";
                }
                else if (this.request.request_kind_id === REQUEST_KINDS_VALUES.LEARNING) {
                    docKey = "act_learning_";
                }

                if (
                    this.request.request_kind_id === REQUEST_KINDS_VALUES.CONSULTATION ||
                    this.requestDocs
                        .map((rd) => rd.file_name)
                        .findIndex((rd) => (
                            rd.includes(docKey) &&
                            rd.endsWith(".docx")
                        )) >= 0
                ) {
                    await this.$store.dispatch(
                        "setRequestField",
                        {
                            field: "request_status_id",
                            value: REQUEST_STATUS_VALUES.PERFORMED,
                        },
                    );

                    if (this.request.request_kind_id === REQUEST_KINDS_VALUES.REPAIR_HARD &&
                        this.request.view_res_before === OPTIONS_VIEW_BEFORE_VALUES.TO_DISMISS) {
                        // result_d equal to date of act
                        if (this.request[docDateField]) {
                            await this.$store.dispatch(
                                "setRequestField",
                                {
                                    field: "result_d",
                                    value: this.request[docDateField],
                                },
                            );
                        }
                    }
                    else if (this.request.request_kind_id === REQUEST_KINDS_VALUES.REPAIR_HARD) {
                        // for REPAIR_HARD requests set result_d equal to date_reapair_d
                        await this.$store.dispatch(
                            "setRequestField",
                            {
                                field: "result_d",
                                value: this.request.date_repair_d,
                            },
                        );
                    }
                    else if (!this.request.result_d) {
                        await this.$store.dispatch(
                            "setRequestField",
                            {
                                field: "result_d",
                                value: new Date(),
                            },
                        );
                    }
                }
            }

            if (
                [
                    REQUEST_KINDS_VALUES.MISTAKE,
                    REQUEST_KINDS_VALUES.REVOKE,
                ].includes(this.request.request_kind_id)
            ) {
                await this.$store.dispatch(
                    "setRequestField",
                    {
                        field: "request_status_id",
                        value: REQUEST_STATUS_VALUES.CANCELED,
                    },
                );

                await this.$store.dispatch(
                    "setRequestEngineers",
                    [],
                );
            }

            // HL deals
            const orgId = this.getOrgIdOfUnit(this.request.unit_id);
            const hot_line_email = this.getOrgProperty(orgId, "hot_line_email");
            const hot_line_password = this.getOrgProperty(orgId, "hot_line_password");

            if (
                this.request.request_status_id === REQUEST_STATUS_VALUES.CLOSED &&
                (!hot_line_email || !hot_line_password) &&
                !this.request.hotline_email_close_sent &&
                this.request.hotlinegas_number
            ) {
                await hotLineEmailClose({
                    hotlinegas_number: this.request.hotlinegas_number,
                });

                await this.$store.dispatch(
                    "setRequestField",
                    {
                        field: "hotline_email_close_sent",
                        value: true,
                    },
                );
            }

            this.$store.dispatch("saveRequest", !doHide)
                .then((message) => {
                    if (doHide) {
                        this.hide();
                    }

                    this.$q.notify({
                        type: "notify-success",
                        message,
                    });

                    this.requestHash = JSON.stringify({
                        request: this.request,
                        filesList: this.filesList,
                        requestDocs: this.requestDocs,
                    });

                    if (
                        this.request.request_kind_id === REQUEST_KINDS_VALUES.REPAIR_HARD &&
                        [
                            REQUEST_STATUS_VALUES.PERFORMING,
                            REQUEST_STATUS_VALUES.PERFORMED,
                            REQUEST_STATUS_VALUES.CLOSED,
                        ].includes(this.request.request_status_id)
                    ) {
                        // change tool status
                        if (this.request.repair_result_id === REPAIRS_RESULTS_IDS.FULL) {
                            this.$store.dispatch(
                                "updateSingleToolInDB",
                                {
                                    id: this.request.tool_id,
                                    state: TOOLS_STATES_VALUES.IN_WORK,
                                },
                            );
                        }
                        else if (!this.request.repair_result_id) {
                            if (this.request.view_res_before === OPTIONS_VIEW_BEFORE_VALUES.TO_DISMISS) {
                                this.$store.dispatch(
                                    "updateSingleToolInDB",
                                    {
                                        id: this.request.tool_id,
                                        state: TOOLS_STATES_VALUES.WAITING_RECLAIM,
                                    },
                                );
                            }
                            if (this.request.view_res_before === OPTIONS_VIEW_BEFORE_VALUES.REPAIR_WAITING) {
                                this.$store.dispatch(
                                    "updateSingleToolInDB",
                                    {
                                        id: this.request.tool_id,
                                        state: TOOLS_STATES_VALUES.BROKEN,
                                    },
                                );
                            }
                        }
                    }
                })
                .catch((err) => {
                    this.$q.notify({
                        type: "notify-failure",
                        message: err.message,
                    });
                })
                .finally(() => {
                    this.requestSaving = false;
                });
        },
        addDoc(file) {
            this.$store.dispatch("addRequestFile", file);
        },
        delDoc(index) {
            this.$store.dispatch("delRequestFile", index);
        },
        closeRequest() {
            if (!this.canBeEdited) {
                this.hide();
                return;
            }

            const requestHashL = JSON.stringify({
                request: this.request,
                filesList: this.filesList,
                requestDocs: this.requestDocs,
            });

            const requestWasChanged = requestHashL !== this.requestHash;

            if (!requestWasChanged || !this.request.id) {
                this.hide();
            }
            else {
                this.$q.dialog({
                    component: DialogConfirm,
                    componentProps: {
                        dialogMessage: "Заявка была изменена. Сохранить изменения?",
                        labelOk: "Да",
                        labelCancel: "Нет",
                    },
                })
                    .onOk(() => {
                        this.saveRequest(true);
                    })
                    .onCancel(() => {
                        this.hide();
                    });
            }
        },
        recalRequest() {
            this.$q.dialog({
                component: DialogConfirm,
                componentProps: {
                    dialogMessage: "Отозвать заявку?",
                    labelOk: "Отозвать",
                },
            })
                .onOk(async () => {
                    await this.$store.dispatch(
                        "setRequestField",
                        {
                            field: "request_kind_id",
                            value: REQUEST_KINDS_VALUES.REVOKE,
                        },
                    );

                    this.saveRequest(true);
                });
        },
    },
};
</script>
