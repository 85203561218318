<template>
    <q-dialog
        ref="dialog"
        persistent
    >
        <q-card style="width: 700px; max-width: 80vw;">
            <div
                v-if="showSpinner"
                class="req-dialog-cover"
            >
                <q-spinner-box
                    color="primary"
                    size="5.5em"
                />
            </div>
            <q-card-section>
                <div class="text-h6">
                    Формирование формуляра
                </div>
            </q-card-section>

            <q-separator />

            <q-card-section
                style="max-height: 70vh;"
                class="scroll"
            >
                <SelectFilter
                    ref="refUnit"
                    v-model="unitId"
                    :options="unitsFiltered"
                    label="Объект"
                    clearable
                    class="cell-span-col-2 q-mb-sm"
                    optionLabel="name"
                    scopeAttr1="name"
                    scopeAttr2="not_needed"
                    scopeAttr3="address"
                    extendedScope
                    hideBottomSpace
                    :rules="[(val) => val || 'Нужно выбрать объект']"
                />

                <q-select
                    ref="refSignerUnit"
                    v-model="signerUnitId"
                    outlined
                    dense
                    :options="signersUnit"
                    optionValue="id"
                    :optionLabel="emplFio"
                    mapOptions
                    emitValue
                    label="Подписант от объекта"
                    class="q-mb-sm"
                    hideBottomSpace
                    :rules="[(val) => val && val > 0 || 'Укажите подписанта']"
                >
                    <template #option="scope">
                        <q-item v-bind="scope.itemProps">
                            <q-item-section>
                                <q-item-label>
                                    {{ scope.opt.surname }}
                                    {{ scope.opt.name }}
                                    {{ scope.opt.patronymic || "" }}
                                </q-item-label>
                                <q-item-label caption>
                                    {{ postTitle(scope.opt.post_id) }}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                    </template>
                </q-select>
                <q-select
                    ref="refSignerIac"
                    v-model="signerIacId"
                    outlined
                    dense
                    :options="signersFilial"
                    optionValue="id"
                    :optionLabel="emplFio"
                    mapOptions
                    emitValue
                    label="Подписант от филиала"
                    class="q-mb-sm"
                    hideBottomSpace
                    :rules="[(val) => val && val > 0 || 'Укажите подписанта']"
                >
                    <template #option="scope">
                        <q-item v-bind="scope.itemProps">
                            <q-item-section>
                                <q-item-label>
                                    {{ scope.opt.surname }}
                                    {{ scope.opt.name }}
                                    {{ scope.opt.patronymic || "" }}
                                </q-item-label>
                                <q-item-label caption>
                                    {{ postTitle(scope.opt.post_id) }}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                    </template>
                </q-select>

                <q-select
                    v-model="selectedYear"
                    outlined
                    dense
                    :options="yearsList"
                    label="Год"
                    class="q-mb-sm"
                    hideBottomSpace
                    :rules="[(val) => val && val > 0 || 'Выберите год']"
                />

                <q-input
                    v-model="dateTakeIn"
                    label="Дата сдачи в эксплуатацию"
                    outlined
                    dense
                    class="q-mb-sm"
                />

                <q-input
                    v-model="actOfTest"
                    label="Акт приемочных испытаний ГАС 'Правосудие'"
                    outlined
                    dense
                    class="q-mb-sm"
                />
            </q-card-section>

            <q-separator />

            <q-card-actions align="right">
                <q-btn
                    flat
                    label="Сформировать"
                    color="primary"
                    :icon="matSave"
                    @click="onOkClick"
                />
                <q-btn
                    v-close-popup
                    flat
                    label="Отменить"
                    color="primary"
                    :icon="matCancel"
                    @click="hide"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { date } from "quasar";
import SelectFilter from "@/components/Common/SelectFilter.vue";
import {
    RIGHTS_CONSTANTS,
    REQUEST_KINDS_VALUES,
    DATE_LOCALE_2,
} from "@/store/constants";
import { createDoc, saveDoc } from "@/helpers/createDoc";
import { getRequestsFormular } from "@/helpers/getDataForReports";

export default {
    name: "DialogCreateFormularFull",
    components: {
        SelectFilter,
    },
    setup() {
        const y = (new Date()).getFullYear() - 9;
        return {
            yearsList: Array.from({ length: 10 }, (v, i) => i + y),
        };
    },
    // emits: ["ok"],
    data() {
        return {
            unitId: null,
            signerUnitId: null,
            signerIacId: null,
            selectedYear: (new Date()).getFullYear(),
            dateTakeIn: "",
            actOfTest: "",
            showSpinner: false,
        };
    },
    computed: {
        ...mapGetters({
            unitsFull: "unitsFull",
            filials: "filials",
            currentUser: "currentUser",
            getOrgIdOfUnit: "getOrgIdOfUnit",
            usersRoles: "usersRoles",
            getUserFIO: "getUserFIO",
            getUserPostTitle: "getUserPostTitle",
            rolesWithRightIds: "rolesWithRightIds",
            postTitle: "postTitle",
            users: "users",
            getUserFIOshort: "getUserFIOshort",
            getToolKindName: "getToolKindName",
            getManufacturerName: "getManufacturerName",
            getToolModelName: "getToolModelName",
        }),
        unitsFiltered() {
            return !!(this.currentUser.rightsObject.RIGHT_REQUEST_READ & RIGHTS_CONSTANTS.ALL)
                ? this.unitsFull
                : this.unitsFull.filter((u) => this.currentUser.units.includes(u.id));
        },
        signersUnit() {
            const orgId = this.getOrgIdOfUnit(this.unitId);

            const signersIds = this.usersRoles
                .filter((ur) => this.rolesWithRightIds("RIGHT_SIGNING_DOCS_UNIT").includes(ur.role_id))
                .map((ur) => ur.user_id);

            const signers = this.users.filter((e) => (
                (signersIds.includes(e.id) && e.organization_id === orgId)
            ));

            return signers;
        },
        signersFilial() {
            const unit = this.unitsFull.find((u) => u.id === this.unitId);

            if (!unit) {
                return [];
            }

            const filial = this.filials.find((f) => f.region_id === unit.region_id);

            if (!filial) {
                return [];
            }

            const signersIds = this.usersRoles
                .filter((ur) => this.rolesWithRightIds("RIGHT_SIGNING_DOCS_IAC")
                    .includes(ur.role_id))
                .map((ur) => ur.user_id);

            const signers = this.users.filter((e) => (
                signersIds.includes(e.id) && e.organization_id === filial.id
            ));

            return signers;
        },
    },
    watch: {
        unitId() {
            this.signerUnitId = null;
            this.signerIacId = null;
        },
    },
    methods: {
        show() {
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        async getRequestsItems(requests) {
            const items = [];
            for (let i = 0; i < requests.length; i++) {
                const tool = await this.$store.dispatch(
                    "getSingleToolFromDB",
                    requests[i].tool_id,
                );

                const notes = [
                    `Заявка № ${requests[i].number}`,
                    tool ? this.getToolKindName(tool.tool_kind_id) : "",
                    tool ? this.getToolModelName(tool.model_id) : "",
                    tool ? tool.inventar_number : "",
                    tool ? tool.serial_number : "",
                    requests[i].request_text,
                ].join(" ");

                let deals = "";
                if (requests[i].request_kind_id === REQUEST_KINDS_VALUES.REPAIR_HARD) {
                    deals = [
                        requests[i].comment_before,
                        requests[i].co_7_number,
                        requests[i].co_7_d
                            ? date.formatDate(
                                requests[i].co_7_d,
                                "YYYY-MM-DD",
                            )
                            : "",
                        requests[i].comment_after,
                        requests[i].co_8_number,
                        requests[i].co_8_d
                            ? date.formatDate(
                                requests[i].co_8_d,
                                "YYYY-MM-DD",
                            )
                            : "",
                    ].join(" ");
                }
                else {
                    deals = [
                        requests[i].note,
                        requests[i].co_3_number,
                        requests[i].co_3_d
                            ? date.formatDate(
                                requests[i].co_3_d,
                                "«DD» MMMM YYYY г.",
                                DATE_LOCALE_2,
                            )
                            : "",
                    ].join(" ");
                }

                const engineers = await this.$store.dispatch(
                    "getRequestEngineers",
                    requests[i].id,
                );
                const engineer0 = engineers.length > 0
                    ? [
                        this.getUserPostTitle(engineers[0]),
                        this.getUserFIO(engineers[0]),
                    ].join(" ")
                    : "";

                const item = {
                    date: this.$dayjs(requests[i].request_date_add).format("DD.MM.YYYY"),
                    note: notes,
                    deal: deals,
                    engineer: engineer0,
                };
                items.push(item);
            }
            return items;
        },
        async onOkClick() {
            this.$refs.refUnit.validate();
            this.$refs.refSignerUnit.validate();
            this.$refs.refSignerIac.validate();

            if (
                this.$refs.refUnit.hasError ||
                this.$refs.refSignerUnit.hasError ||
                this.$refs.refSignerIac.hasError
            ) {
                return;
            }

            this.showSpinner = true;

            const unit = this.unitsFull.find((u) => u.id === this.unitId);
            const filial = this.filials.find((f) => f.region_id === unit.region_id);
            const signerUnit = this.users.find((u) => u.id === this.signerUnitId);
            const signerIac = this.users.find((u) => u.id === this.signerIacId);
            const outFileName = `ФО_КСА_РС_${unit.vncode}.docx`;

            const { requests } = await getRequestsFormular({
                unitId: this.unitId,
                date_beg: `01.01.${this.selectedYear}`,
                date_end: `31.12.${this.selectedYear}`,
            });

            const items = await this.getRequestsItems(requests);

            const replacement = {
                unit_code: unit.vncode,
                formular_year: this.selectedYear,
                current_date: this.$dayjs().format("DD.MM.YYYY"),
                unit_title: unit.fullname,
                unit_signer_post: this.postTitle(signerUnit.post_id),
                unit_signer_fio: this.getUserFIOshort(this.signerUnitId),
                filial_title: filial.fullname,
                filial_signer_post: this.postTitle(signerIac.post_id),
                filial_signer_fio: this.getUserFIOshort(this.signerIacId),
                date_take_in: this.dateTakeIn,
                act_of_test: this.actOfTest,
                items,
            };

            createDoc(
                "formular-full.docx",
                outFileName,
                replacement,
            )
                .then((repairreport) => {
                    saveDoc(repairreport);
                    this.showSpinner = false;
                });

            this.hide();
        },
        emplFio(empl) {
            const postTitle = this.postTitle(empl.post_id);
            return empl && empl.surname
                ? `${empl.surname} ${empl.name} ${empl.patronymic || ""}
                    ${postTitle ? `(${postTitle})` : ""}`
                : null;
        },
    },
};
</script>
