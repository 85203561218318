<template>
    <q-btn
        class="cell-span-col-2"
        filled
        color="primary"
        label="Назначить исполнителя/исполнителей"
        :disable="request.request_status_id == REQUEST_STATUS_VALUES.CLOSED || !canBeEdited"
        @click="chooseEngineer"
    />
    <q-btn
        class="cell-span-col-2"
        filled
        color="primary"
        label="Заявка выполнена"
        :disable="request.request_status_id !== REQUEST_STATUS_VALUES.PERFORMED || !canBeEdited"
        @click="requestClose"
    />
    <q-btn
        filled
        color="primary"
        label="На повторное исполнение"
        :disable="!canBeEdited"
        @click="requestRevive"
    />
    <q-btn
        filled
        color="primary"
        label="Отменить исполнение"
        :disable="disableResume"
        @click="resumeRequest"
    />
</template>

<script>
import { date } from "quasar";
import { mapGetters } from "vuex";
import DialogSearchEngineer from "@/components/Dialogs/DialogSearchEngineer.vue";
import { REQUEST_STATUS_VALUES, RIGHTS_CONSTANTS } from "@/store/constants";

export default {
    name: "DialogEditRequestMovingApprove",
    props: {
        canBeEdited: {
            type: Boolean,
            required: true,
        },
    },
    setup() {
        return {
            REQUEST_STATUS_VALUES,
        };
    },
    computed: {
        ...mapGetters({
            request: "request",
            currentUser: "currentUser",
        }),
        disableResume() {
            const expiringDate = date.subtractFromDate(
                Date.now(),
                { days: 3 },
            );

            return (
                this.request.request_status_id !== REQUEST_STATUS_VALUES.CLOSED ||
                !this.canBeEdited ||
                !(this.currentUser.rightsObject.RIGHT_REQUEST_RESUME & RIGHTS_CONSTANTS.FULL) ||
                this.request.result_d < expiringDate
            );
        },
    },
    methods: {
        chooseEngineer() {
            this.$q.dialog({
                component: DialogSearchEngineer,
                componentProps: {
                    dialogTitle: "Выбор исполнителя/исполнителей",
                },
            })
                .onOk((ids) => {
                    this.$store.dispatch("setRequestEngineers", ids);
                });
        },
        requestRevive() {
            this.$store.dispatch(
                "setRequestField",
                {
                    field: "request_status_id",
                    value: REQUEST_STATUS_VALUES.WAITING_ENGINEER,
                },
            );
            this.$store.dispatch(
                "setRequestField",
                {
                    field: "result",
                    value: null,
                },
            );
            this.$store.dispatch(
                "setRequestField",
                {
                    field: "result_d",
                    value: null,
                },
            );
        },
        async requestClose() {
            if (!this.request.hotlinegas_number) {
                this.$q.notify({
                    type: "notify-failure",
                    message: "Закрытие заявки невозможно, заполние поле 'Номер на \"ГЛ ГАС ПРАВОСУДИЕ\"'",
                });
                return;
            }

            await this.$store.dispatch(
                "setRequestField",
                {
                    field: "request_status_id",
                    value: REQUEST_STATUS_VALUES.CLOSED,
                },
            );
        },
        resumeRequest() {
            this.$store.dispatch(
                "setRequestField",
                {
                    field: "request_status_id",
                    value: REQUEST_STATUS_VALUES.PERFORMED,
                },
            );
        },
    },
};
</script>
