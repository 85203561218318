<template>
    <div class="content content__flex-with-table">
        <div class="content-title">
            Комплекты ЗИП
            <q-space />
            <FilterSparesSetsItems />
        </div>

        <div class="spares-grid">
            <!-- header -->
            <div class="cell cell-header cell-center">
                №
            </div>
            <div class="cell cell-header cell-center">
                Регион
            </div>
            <div class="cell cell-header cell-center">
                ЗИП
            </div>
            <div class="cell cell-header cell-center">
                Наименование
            </div>
            <div class="cell cell-header cell-center">
                Стоимость
            </div>
            <div class="cell cell-header cell-center">
                Количество
            </div>
            <div class="cell cell-header cell-center">
                Остаток
            </div>
            <div class="cell cell-header cell-center">
                <q-btn
                    round
                    :icon="matAdd"
                    size="0.7rem"
                    color="primary"
                    @click="(e) => editItem(null)"
                />
            </div>
            <!-- data -->
            <SparesRow
                v-for="(sparesSetItem, index) in sparesSetsItems"
                :key="index"
                :spareIndex="index"
                :sparesSetItem="sparesSetItem"
                @editItem="editItem"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import FilterSparesSetsItems from "@/components/Filters/FilterSparesSetsItems.vue";

import DialogEditSparesSet from "@/components/Dialogs/DialogEditSparesSet.vue";
import SparesRow from "./SparesRow.vue";

export default {
    name: "Spares",
    components: {
        FilterSparesSetsItems,
        SparesRow,
    },
    computed: {
        ...mapGetters({
            sparesSetsItems: "sparesSetsItems",
            sparesSet: "sparesSet",
        }),
    },
    methods: {
        editItem(index) {
            this.$q.dialog({
                component: DialogEditSparesSet,
                componentProps: {
                    itemForEdit: index === null
                        ? {}
                        : ({

                            ...this.sparesSet(this.sparesSetsItems[index].spares_set_id),
                        }),
                },
            })
                .onOk((sparesSet) => {
                    this.$store.dispatch("saveSparesSet", {
                        id: index === null ? null : sparesSet.id,
                        sparesSet,
                    })
                        .then((message) => {
                            this.$q.notify({
                                type: "notify-success",
                                message,
                            });
                        })
                        .catch((err) => {
                            this.$q.notify({
                                type: "notify-failure",
                                message: err,
                            });
                        });
                });
        },
    },
};
</script>
