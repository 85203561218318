<template>
    <q-dialog
        ref="dialog"
        persistent
    >
        <q-card style="width: 80vw; max-width: 80vw;">
            <div
                v-if="showSpinner"
                class="req-dialog-cover"
            >
                <q-spinner-grid
                    color="primary"
                    size="5.5em"
                />
            </div>

            <q-card-section>
                <div class="text-h6">
                    Комплект оборудования
                </div>
            </q-card-section>

            <q-separator />

            <q-card-section
                style="max-height: 60vh;"
                class="scroll"
            >
                <SelectFilter
                    ref="refUnit"
                    v-model="item.unit_id"
                    label="Объект"
                    :options="unitsFull"
                    optionLabel="name"
                    extendedScope
                    scopeAttr1="name"
                    scopeAttr2="not_needed"
                    scopeAttr3="address"
                    clearable
                    class="q-mb-sm"
                    hideBottomSpace
                    :rules="[(val) => val && val > 0 || 'Выберите объект']"
                />
                <q-input
                    ref="refInventar"
                    v-model="item.inventar_number"
                    outlined
                    dense
                    class="q-mb-sm"
                    label="Инвентарный номер"
                    hideBottomSpace
                    :rules="[(val) => !!val || 'Нужно ввести']"
                />
                <SelectFilter
                    ref="refSetType"
                    v-model="item.type_id"
                    :options="toolsSetsNamesCodes"
                    label="Вид комплекта оборудования"
                    optionLabel="name"
                    clearable
                    outlined
                    dense
                    class="q-mb-sm"
                    hideBottomSpace
                    :rules="[(val) => val && val > 0 || 'Нужно выбрать']"
                />
                <PoeDatePicker
                    ref="refDate"
                    v-model="workInDt"
                    labelTitle="Ввод в эксплуатацию"
                    class="q-mb-sm"
                    :futureDateAllowed="false"
                />
            </q-card-section>

            <q-card-section class="scroll no-padding">
                <div class="tools-in-sets-grid">
                    <div class="cell cell-header cell-center">
                        №
                    </div>
                    <div class="cell cell-header cell-center">
                        Вид оборудования
                    </div>
                    <div class="cell cell-header cell-center">
                        Производитель
                    </div>
                    <div class="cell cell-header cell-center">
                        Наименование
                    </div>
                    <div class="cell cell-header cell-center">
                        Серийный номер
                    </div>
                    <div class="cell cell-header cell-center">
                        <q-btn
                            round
                            :icon="matAdd"
                            size="sm"
                            color="primary"
                            @click="addTool"
                        >
                            <q-tooltip
                                :delay="300"
                                anchor="top right"
                                self="bottom middle"
                                class="bg-amber text-body2 text-black shadow-4"
                            >
                                Добавить оборудование
                            </q-tooltip>
                        </q-btn>
                    </div>
                    <div
                        v-for="(tool, toolIndex) in tools"
                        :key="toolIndex"
                        class="row-wrapper"
                        @click="editTool(toolIndex)"
                    >
                        <div class="cell cell-center">
                            {{ toolIndex + 1 }}
                        </div>
                        <div class="cell cell-center">
                            {{ getToolKindName(tool.tool_kind_id) }}
                        </div>
                        <div class="cell cell-center">
                            {{ getManufacturerName(tool.manufacturer_id) }}
                        </div>
                        <div class="cell cell-center">
                            {{ getToolModelName(tool.model_id) }}
                        </div>
                        <div class="cell cell-center">
                            {{ tool.serial_number }}
                        </div>
                        <div class="cell cell-center">
                            <q-btn
                                round
                                :icon="matDelete"
                                size="sm"
                                class="poeso-table--control-button"
                                @click="(e) => delTool(e, toolIndex)"
                            />
                        </div>
                    </div>
                </div>
            </q-card-section>

            <q-separator />

            <q-card-actions align="right">
                <q-btn
                    flat
                    label="Сохранить"
                    color="primary"
                    :icon="matSave"
                    @click="onOkClick"
                />
                <q-btn
                    flat
                    label="Отменить"
                    color="primary"
                    :icon="matCancel"
                    @click="hide"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import SelectFilter from "@/components/Common/SelectFilter.vue";
import PoeDatePicker from "@/components/Common/PoeDatePicker.vue";
import DialogEditTool from "@/components/Dialogs/DialogEditTool/DialogEditTool.vue";
import { dateDMYtoYMD, dateYMDtoDMY } from "@/helpers/helpers";

export default {
    name: "DialogEditToolsSet",
    components: {
        SelectFilter,
        PoeDatePicker,
    },
    props: {
        itemForEdit: {
            type: Object,
            required: true,
        },
        bCreateNew: {
            type: Boolean,
            required: true,
        },
    },
    emits: ["ok"],
    data() {
        return {
            setType: null,
            setName: "",
            item: {},
            tools: [],
            showSpinner: false,
            oldToolsIds: [],
        };
    },
    computed: {
        ...mapGetters({
            unitsFull: "unitsFull",
            toolsSetsNamesCodes: "toolsSetsNamesCodes",
            getToolKindName: "getToolKindName",
            getManufacturerName: "getManufacturerName",
            getToolModelName: "getToolModelName",
        }),
        workInDt: {
            get() {
                return this.item.work_in_dt
                    ? dateYMDtoDMY(this.item.work_in_dt)
                    : null;
            },
            set(v) {
                this.item.work_in_dt = dateDMYtoYMD(v);
            },
        },
    },
    methods: {
        addTool() {
            if (!this.areFieldsValid()) {
                return;
            }
            this.$q.dialog({
                component: DialogEditTool,
                componentProps: {
                    itemForEdit: {
                        unit_id: this.item.unit_id,
                        inventar_number: this.item.inventar_number,
                        work_in_dt: this.workInDt,
                    },
                    toolsSetTypeId: this.item.type_id,
                    saveToDb: false,
                },
            })
                .onOk((tool) => {
                    this.tools.push(tool);
                });
        },
        editTool(toolIndex) {
            this.$q.dialog({
                component: DialogEditTool,
                componentProps: {
                    itemForEdit: {
                        ...this.tools[toolIndex],
                        unit_id: this.item.unit_id,
                        inventar_number: this.item.inventar_number,
                        work_in_dt: this.workInDt,
                    },
                    toolsSetTypeId: this.item.type_id,
                    saveToDb: false,
                },
            })
                .onOk((tool) => {
                    this.tools[toolIndex] = tool;
                });
        },
        delTool(event, toolIndex) {
            event.stopPropagation();
            this.tools.splice(toolIndex, 1);
        },
        areFieldsValid() {
            this.$refs.refUnit.validate();
            this.$refs.refInventar.validate();
            this.$refs.refSetType.validate();
            this.$refs.refDate.validate();

            if (
                this.$refs.refUnit.hasError ||
                this.$refs.refInventar.hasError ||
                this.$refs.refSetType.hasError ||
                this.$refs.refDate.hasError
            ) {
                return false;
            }

            return true;
        },
        //----------------------------------------------------------------------
        async show() {
            this.item = this.itemForEdit;
            this.$refs.dialog.show();

            if (this.itemForEdit.id) {
                this.showSpinner = true;

                const tools = await this.$store.dispatch("getToolsBySetId", this.itemForEdit.id);
                this.tools = tools;

                for (let i = 0; i < this.tools.length; i++) {
                    const tool = this.tools[i];

                    // get date (it might be not set)
                    tool.work_in_dt = (tool.work_in_dt
                        ? this.$dayjs(tool.work_in_dt).format("DD.MM.YYYY")
                        : null);
                    tool.guaranty_dt = (tool.guaranty_dt
                        ? this.$dayjs(tool.guaranty_dt).format("DD.MM.YYYY")
                        : null);

                    tool.traits = await this.$store.dispatch(
                        "getToolKindTraitsValues",
                        {
                            toolId: tool.id,
                            kindId: tool.tool_kind_id,
                        },
                    );

                    tool.filesListDocs = await this.$store.dispatch(
                        "getFilesListFromDB",
                        {
                            section: "tool_docs",
                            id: tool.id,
                        },
                    );

                    tool.filesListPictures = await this.$store.dispatch(
                        "getFilesListFromDB",
                        {
                            section: "tool_pictures",
                            id: tool.id,
                        },
                    );
                }

                if (this.bCreateNew) {
                    delete this.item.id;
                    for (let i = 0; i < this.tools.length; i++) {
                        delete this.tools[i].id;
                    }
                }
                else {
                    this.oldToolsIds = tools.map((t) => t.id);
                }
                this.$refs.dialog.show();

                this.showSpinner = false;
            }
        },
        hide() {
            this.$refs.dialog.hide();
        },
        onOkClick() {
            if (!this.areFieldsValid()) {
                return;
            }

            if (this.tools.length < 1) {
                this.$q.notify({
                    type: "notify-failure",
                    message: "Комплект должен содержать учетные единицы",
                });
                return;
            }

            this.showSpinner = true;

            this.$store.dispatch(
                "saveToolsSet",
                {
                    toolsSet: this.item,
                    toolsSetTools: this.tools,
                    oldToolsIds: this.oldToolsIds,
                },
            )
                .then((message) => {
                    this.$q.notify({
                        type: "notify-success",
                        message,
                    });
                    this.hide();
                })
                .catch((err) => {
                    console.log(err);
                    this.$q.notify({
                        type: "notify-failure",
                        message: err.message,
                    });
                })
                .finally(() => {
                    this.showSpinner = false;
                });
        },
    },
};
</script>
