<template>
    <q-dialog v-model="showDialogLocal">
        <q-card style="width: 700px; max-width: 80vw;">
            <q-card-section>
                <div class="text-h6">
                    Вид учетной единицы
                </div>
            </q-card-section>

            <q-card-section
                style="max-height: 70vh; height: 60vh;"
                class="scroll"
            >
                <q-input
                    v-model="toolKind.title"
                    outlined
                    dense
                    label="Наименование"
                    class="q-mb-sm"
                />
                <q-checkbox
                    v-model="toolKind.soft_updatable"
                    label="Содержит обновляемое ПО"
                />
                <!--
                TODO: investigate if mandatory should be here
                <div class="q-gutter-sm">
                    <q-checkbox v-model="toolKind.mandatory" />
                </div>
                -->
                <div class="simple-three-grid">
                    <div class="cell cell-header cell-center">
                        №
                    </div>
                    <div class="cell cell-header cell-center">
                        Параметр
                    </div>
                    <div class="cell cell-header cell-center">
                        <q-btn
                            round
                            :icon="matAdd"
                            size="sm"
                            color="primary"
                            @click="addTrait"
                        >
                            <q-tooltip
                                :delay="300"
                                anchor="top right"
                                self="bottom middle"
                                class="bg-amber text-body2 text-black shadow-4"
                            >
                                Добавить параметр
                            </q-tooltip>
                        </q-btn>
                    </div>
                    <div
                        v-for="(trait, traitIndex) in toolKind.traits"
                        :key="traitIndex"
                        class="row-wrapper"
                    >
                        <div class="cell cell-center">
                            {{ traitIndex + 1 }}
                        </div>
                        <div class="cell cell-center">
                            <q-select
                                v-model="toolKind.traits[traitIndex].trait_id"
                                borderless
                                dense
                                :options="traits"
                                optionValue="id"
                                optionLabel="title"
                                mapOptions
                                emitValue
                            />
                        </div>
                        <div class="cell cell-center">
                            <q-btn
                                round
                                :icon="matDelete"
                                size="sm"
                                class="poeso-table--control-button"
                                @click="() => removeTrait(traitIndex)"
                            />
                        </div>
                    </div>
                </div>
            </q-card-section>

            <q-separator />

            <q-card-actions align="right">
                <q-btn
                    v-close-popup
                    flat
                    label="Сохранить"
                    color="primary"
                    :icon="matSave"
                    @click="() => $emit('close-dialog', toolKind)"
                />
                <q-btn
                    v-close-popup
                    flat
                    label="Отменить"
                    color="primary"
                    :icon="matCancel"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "DialogEditToolKind",
    props: {
        showDialog: {
            type: Boolean,
            required: true,
        },
        toolKindOriginal: {
            type: Object,
            required: true,
        },
    },
    emits: ["close-dialog"],
    data() {
        return {
            toolKind: {},
        };
    },
    computed: {
        ...mapGetters({
            traits: "traits",
        }),
        showDialogLocal: {
            get() {
                return this.showDialog;
            },
            set() {
                this.$emit("close-dialog");
            },
        },
    },
    // TODO: looks like a hack, investigate another way for setting
    // local varible from prop and getting contacts for organization
    watch: {
        showDialog(value) {
            if (value) {
                this.toolKind = {
                    ...this.toolKindOriginal,
                };
            }
        },
    },
    methods: {
        addTrait() {
            this.toolKind.traits.push({
                trait_id: null,
                trait_title: "",
            });
        },
        removeTrait(traitIndex) {
            this.toolKind.traits.splice(traitIndex, 1);
        },
    },
};
</script>
